import { ChangeEvent, ReactElement, useMemo } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { Autocomplete } from '@breathelife/mui';

import { StyledAutocompleteTextFieldMultiple } from './Styles';
import { AutocompleteOption } from './options';

const EmptyStateTitle = styled.div`
  color: grey;
  font-weight: bold;
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type OptionsFromItems<TItemType, TOptionType extends AutocompleteOption> = {
  selectedItems: TItemType[];
  items: TItemType[];
  toOption: (item: TItemType) => TOptionType;
  onChange: (values: TItemType[]) => void;
};

type SpecificOptions<TOptionType extends AutocompleteOption> = {
  options: TOptionType[];
  selectedOptions: TOptionType[];
  onChange: (values: TOptionType[]) => void;
};

// `OptionInfo` enables this component to accept either a list of items or a list of AutocompleteOption's.
type OptionInfo<TItemType, TOptionType extends AutocompleteOption> =
  | OptionsFromItems<TItemType, TOptionType>
  | SpecificOptions<TOptionType>;

type Props<TItemType, TOptionType extends AutocompleteOption> = {
  id?: string;
  label?: string;
  validationError?: yup.ValidationError;
  groupBy?: (option: TOptionType) => string;
  noOptionMessage?: string;
  optionInfo: OptionInfo<TItemType, TOptionType>;
  disabled?: boolean;
};

export function AutoCompleteMultiple<TItemType, TOptionType extends AutocompleteOption>({
  id,
  groupBy,
  label,
  validationError,
  noOptionMessage,
  optionInfo,
  disabled,
}: Props<TItemType, TOptionType>): ReactElement {
  const { options, selectedOptions, onChange } = useMemo(() => {
    if (isOptionsFromItems(optionInfo)) {
      const { toOption, selectedItems, items, onChange } = optionInfo;
      return {
        options: items.map(toOption),
        selectedOptions: selectedItems.map(toOption),
        onChange: (_: ChangeEvent<any>, newSelectedOptions: TOptionType[] | null) => {
          if (newSelectedOptions === null) return;

          const newSelectedItems = newSelectedOptions
            .map((option) => items.find((item) => toOption(item).value === option.value))
            .filter(Boolean) as TItemType[];
          onChange(newSelectedItems);
        },
      };
    } else {
      const { options, selectedOptions, onChange } = optionInfo;
      return {
        options,
        selectedOptions,
        onChange: (_: ChangeEvent<any>, newSelectedOptions: TOptionType[] | null) => {
          if (newSelectedOptions === null) return;
          onChange(newSelectedOptions);
        },
      };
    }
  }, [optionInfo]);

  const noOptionsText =
    noOptionMessage && selectedOptions.length === 0 ? <EmptyStateTitle>{noOptionMessage}</EmptyStateTitle> : undefined;

  return (
    <Autocomplete
      multiple
      id={id ?? 'autocomplete-input'}
      options={options}
      value={selectedOptions}
      onChange={onChange}
      groupBy={groupBy}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, selected) => option.value === selected.value}
      disableClearable
      filterSelectedOptions
      noOptionsText={noOptionsText}
      disabled={disabled}
      renderInput={(inputProps) => (
        <StyledAutocompleteTextFieldMultiple
          {...inputProps}
          label={label}
          inputVariant='outlined'
          error={!!validationError}
          helperText={validationError?.message}
        />
      )}
    />
  );
}

function isOptionsFromItems<T, U extends AutocompleteOption>(
  optionData: OptionInfo<T, U>,
): optionData is OptionsFromItems<T, U> {
  return !!(optionData as OptionsFromItems<T, U>)?.selectedItems;
}
