import styled from 'styled-components';

export const DEFAULT_COLOR = '#ebebeb';

type SizeProps = {
  size?: string;
};

type SeparatorProps = SizeProps & {
  color?: string;
};

export const Space = styled.div<SizeProps>`
  height: ${(props) => props.size || '5em'};
`;

export const Separator = styled.div<SeparatorProps>`
  background-color: ${(props) => props.color || DEFAULT_COLOR};
  height: ${(props) => props.size || '1px'};
  margin: 50px 0 20px 0;
`;
