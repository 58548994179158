import styled from 'styled-components';

import { Button } from '@breathelife/mui';

export const HeaderActionButton = styled(Button)`
  &&& {
    margin: 0 0 0 auto;
    padding: 0 12px 0 2px;
    width: 80px;
    height: 36px;
    border-radius: 100px;
    text-transform: none;
    line-height: 1em;
    display: flex;

    border-style: solid;
    border-width: ${(props) => props.theme.metrics.borders.default};
    border-color: ${(props) => props.theme.colors.button.scheduleCall.border.default};
    border-radius: ${(props) => props.theme.metrics.button.borderRadius};
    background-color: ${(props) => props.theme.colors.button.scheduleCall.background.default};

    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.button.scheduleCall.border.hover};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.hover};
    }
    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.hyperlink};
      border-width: ${(props) => props.theme.metrics.borders.focus};
      border-color: ${(props) => props.theme.colors.hyperlink};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.hover};
    }
    &:active {
      box-shadow: none;
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.button.scheduleCall.border.active};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.active};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      padding-right: 16px;
      width: max-content;
    }
  }
`;
