import { css } from 'styled-components';

const inputFontSize = css`
  select,
  textarea,
  input:not([type='radio']),
  input:not([type='checkbox']),
  input:not([type='button']),
  input:not([type='color']),
  input:not([type='file']),
  input:not([type='hidden']),
  input:not([type='image']),
  input:not([type='range']),
  input:not([type='reset']),
  input:not([type='submit']) {
    font-size: 16px !important;
  }
`;

/*
  CSS specific to iOS devices
  This is to prevent zooming-in in input fields on iOS devices.
  https://blog.osmosys.asia/2017/01/05/prevent-ios-from-zooming-in-on-input-fields/
  https://www.warrenchandler.com/2019/04/02/stop-iphones-from-zooming-in-on-form-fields/

  However, newer iPhones don't have supportable aspect ratios, so by targetting webkit we make sure we have iOS devices.
  https://stackoverflow.com/questions/30102792/css-media-query-to-target-only-ios-devices#answer-47818418
*/
export const iOSSupport = css`
  @supports (-webkit-overflow-scrolling: touch) {
    ${inputFontSize};
  }
`;
