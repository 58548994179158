import { ReactElement } from 'react';

export function FileMenuIcon(): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3707 3.4541C13.4801 3.56347 13.5416 3.71182 13.5416 3.86652V13.9541C13.5416 14.1088 13.4802 14.2572 13.3708 14.3666C13.2614 14.476 13.113 14.5374 12.9583 14.5374L3.07823 14.5375C2.92352 14.5375 2.77514 14.476 2.66575 14.3666C2.55635 14.2572 2.49489 14.1088 2.49489 13.9541L2.49487 1.89948C2.49487 1.74477 2.55633 1.5964 2.66573 1.487C2.77512 1.3776 2.9235 1.31614 3.07821 1.31614L10.7958 1.3161C10.9505 1.31614 11.0989 1.37762 11.2083 1.48702L13.3707 3.4541Z'
        fill='white'
      />
      <path
        d='M13.329 3.62501L13.6825 3.27141L13.6822 3.27108L13.329 3.62501ZM13.4999 4.03743L13.9999 4.03743V4.03732L13.4999 4.03743ZM13.5 13.918H14V13.918L13.5 13.918ZM12.9166 14.5013V14.0013H12.9166L12.9166 14.5013ZM3.07656 14.5014V15.0014H3.07656L3.07656 14.5014ZM2.49323 13.918L1.99323 13.918V13.918H2.49323ZM2.49325 2.08333L2.99325 2.08333V2.08333H2.49325ZM3.07658 1.49999L3.07658 0.999995H3.07658V1.49999ZM10.9583 1.5L10.9584 1H10.9583V1.5ZM11.3708 1.67092L11.0172 2.02442L11.0176 2.02485L11.3708 1.67092ZM12.9755 3.97862C12.9912 3.99424 12.9999 4.01544 12.9999 4.03754L13.9999 4.03732C13.9999 3.75003 13.8857 3.47452 13.6825 3.27141L12.9755 3.97862ZM12.9999 4.03743L13 13.918L14 13.918L13.9999 4.03743L12.9999 4.03743ZM13 13.918C13 13.9401 12.9912 13.9613 12.9756 13.9769L13.6827 14.684C13.8858 14.4809 14 14.2053 14 13.918H13ZM12.9756 13.9769C12.9599 13.9926 12.9387 14.0013 12.9166 14.0013V15.0013C13.204 15.0013 13.4795 14.8872 13.6827 14.684L12.9756 13.9769ZM12.9166 14.0013L3.07656 14.0014L3.07656 15.0014L12.9166 15.0013L12.9166 14.0013ZM3.07656 14.0014C3.05446 14.0014 3.03326 13.9926 3.01763 13.977L2.31053 14.6841C2.51369 14.8872 2.78924 15.0014 3.07656 15.0014V14.0014ZM3.01763 13.977C3.00201 13.9613 2.99323 13.9401 2.99323 13.918H1.99323C1.99323 14.2053 2.10736 14.4809 2.31053 14.6841L3.01763 13.977ZM2.99323 13.918L2.99325 2.08333L1.99325 2.08333L1.99323 13.918L2.99323 13.918ZM2.99325 2.08333C2.99325 2.06123 3.00203 2.04003 3.01765 2.0244L2.31055 1.3173C2.10738 1.52046 1.99325 1.79601 1.99325 2.08333H2.99325ZM3.01765 2.0244C3.03328 2.00877 3.05448 1.99999 3.07658 1.99999V0.999995C2.78926 0.999995 2.51371 1.11413 2.31055 1.3173L3.01765 2.0244ZM3.07658 1.99999L10.9583 2V1L3.07658 0.999995L3.07658 1.99999ZM10.9582 2C10.9803 2 11.0015 2.00879 11.0172 2.02442L11.7244 1.31742C11.5212 1.11424 11.2457 1.00006 10.9584 1L10.9582 2ZM11.0176 2.02485L12.9759 3.97894L13.6822 3.27108L11.7239 1.31699L11.0176 2.02485Z'
        fill='#757575'
      />
      <path d='M5 5.5H11' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 8.5H11' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 11.5H11' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
