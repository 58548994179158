import { ReactElement } from 'react';

export function Briefcase(): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 4.50061H14.5C15.052 4.50061 15.5 4.94861 15.5 5.50061V13.5006C15.5 14.0526 15.052 14.5006 14.5 14.5006H1.5C0.948 14.5006 0.5 14.0526 0.5 13.5006V5.50061C0.5 4.94861 0.948 4.50061 1.5 4.50061Z'
        fill='#FBFBFB'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 4.50061C11 2.84394 9.65667 1.50061 8 1.50061C6.34333 1.50061 5 2.84394 5 4.50061'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.5 8.50061H7' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 8.50061H15.5' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 10C7.448 10 7 9.552 7 9V8C7 7.448 7.448 7 8 7C8.552 7 9 7.448 9 8V9C9 9.552 8.552 10 8 10Z'
        fill='#F6F6F6'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
