import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function WorkIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path d='M23.6148 7.73724L27.0841 8.60459V15.5434H20.579V8.60459L23.6148 7.73724Z' fill={tertiaryColor} />
      <path
        d='M14.9413 40.6964V28.9873L18.4107 27.2526C18.4107 29.4209 19.5382 33.7577 24.0484 33.7577C28.5586 33.7577 29.3971 29.71 29.2525 27.6862L32.7219 29.8546V40.6964H14.9413Z'
        fill={tertiaryColor}
      />
      <path
        d='M16.2423 15.1097C16.2423 15.1097 16.676 11.6403 19.7117 9.03827L20.579 8.6046V15.1097H16.2423Z'
        fill={secondaryColor}
      />
      <path
        d='M31.4209 15.1097C31.4209 15.1097 30.9872 11.6403 27.9515 9.03827L27.0842 8.6046V15.1097H31.4209Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9424 9.43878C19.504 7.87726 21.6219 7 23.8302 7C26.0385 7 28.1564 7.87726 29.7179 9.43878C31.2795 11.0003 32.1567 13.1182 32.1567 15.3265C32.1567 17.5349 31.2795 19.6527 29.7179 21.2143C28.1564 22.7758 26.0385 23.6531 23.8302 23.6531C21.6219 23.6531 19.504 22.7758 17.9424 21.2143C16.3809 19.6527 15.5037 17.5349 15.5037 15.3265C15.5037 13.1182 16.3809 11.0003 17.9424 9.43878ZM23.8302 8.47449C22.0129 8.47449 20.2701 9.1964 18.9851 10.4814C17.7001 11.7664 16.9782 13.5093 16.9782 15.3265C16.9782 17.1438 17.7001 18.8866 18.9851 20.1717C20.2701 21.4567 22.0129 22.1786 23.8302 22.1786C25.6475 22.1786 27.3903 21.4567 28.6753 20.1717C29.9603 18.8866 30.6822 17.1438 30.6822 15.3265C30.6822 13.5093 29.9603 11.7664 28.6753 10.4814C27.3903 9.1964 25.6475 8.47449 23.8302 8.47449Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.8316 26.9056C20.2891 26.9056 16.8917 28.3129 14.3867 30.8178C11.8818 33.3228 10.4745 36.7202 10.4745 40.2628C10.4745 40.6699 10.1444 41 9.73724 41C9.33008 41 9 40.6699 9 40.2628C9 36.3292 10.5626 32.5567 13.3441 29.7752C16.1256 26.9937 19.898 25.4311 23.8316 25.4311C27.7652 25.4311 31.5377 26.9937 34.3192 29.7752C37.1006 32.5567 38.6633 36.3292 38.6633 40.2628C38.6633 40.6699 38.3332 41 37.926 41C37.5188 41 37.1888 40.6699 37.1888 40.2628C37.1888 36.7202 35.7815 33.3228 33.2766 30.8178C30.7716 28.3129 27.3742 26.9056 23.8316 26.9056Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.4107 26.5139C18.8179 26.5139 19.148 26.8439 19.148 27.2511V28.3367C19.148 29.5789 19.6414 30.7702 20.5198 31.6486C21.3981 32.5269 22.5894 33.0204 23.8316 33.0204C25.0738 33.0204 26.2651 32.5269 27.1435 31.6486C28.0218 30.7702 28.5153 29.5789 28.5153 28.3367V27.2511C28.5153 26.8439 28.8454 26.5139 29.2525 26.5139C29.6597 26.5139 29.9898 26.8439 29.9898 27.2511V28.3367C29.9898 29.97 29.341 31.5363 28.1861 32.6912C27.0312 33.8461 25.4649 34.4949 23.8316 34.4949C22.1984 34.4949 20.632 33.8461 19.4771 32.6912C18.3223 31.5363 17.6735 29.97 17.6735 28.3367V27.2511C17.6735 26.8439 18.0035 26.5139 18.4107 26.5139Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1567 28.4148C15.5639 28.4148 15.894 28.7449 15.894 29.152V40.2628C15.894 40.6699 15.5639 41 15.1567 41C14.7496 41 14.4195 40.6699 14.4195 40.2628V29.152C14.4195 28.7449 14.7496 28.4148 15.1567 28.4148Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.5037 28.4148C32.9108 28.4148 33.2409 28.7449 33.2409 29.152V40.2628C33.2409 40.6699 32.9108 41 32.5037 41C32.0965 41 31.7664 40.6699 31.7664 40.2628V29.152C31.7664 28.7449 32.0965 28.4148 32.5037 28.4148Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5776 7.73001C20.9848 7.73001 21.3149 8.06009 21.3149 8.46726V15.3265C21.3149 15.7337 20.9848 16.0638 20.5776 16.0638C20.1705 16.0638 19.8404 15.7337 19.8404 15.3265V8.46726C19.8404 8.06009 20.1705 7.73001 20.5776 7.73001Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.0828 7.73001C27.4899 7.73001 27.82 8.06009 27.82 8.46726V15.3265C27.82 15.7337 27.4899 16.0638 27.0828 16.0638C26.6756 16.0638 26.3455 15.7337 26.3455 15.3265V8.46726C26.3455 8.06009 26.6756 7.73001 27.0828 7.73001Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3353 15.3265C13.3353 14.9194 13.6654 14.5893 14.0726 14.5893H33.5879C33.995 14.5893 34.3251 14.9194 34.3251 15.3265C34.3251 15.7337 33.995 16.0638 33.5879 16.0638H14.0726C13.6654 16.0638 13.3353 15.7337 13.3353 15.3265Z'
        fill={primaryColor}
      />
    </svg>
  );
}
