import { Component as ReactComponent, ComponentType } from 'react';

import { breakpoints } from '../Theme/breakpoints';

export type ResponsiveProps = {
  isMobile?: boolean;
  isTablet?: boolean;
  width?: number;
  height?: number;
};

type State = {
  width: number;
  height: number;
};

const minTablet = parseInt(breakpoints.tablet.min, 10);
const maxTablet = parseInt(breakpoints.tablet.max, 10);

export const withResponsive = <P extends ResponsiveProps>(Component: ComponentType<P>) => {
  return class WithResponsive extends ReactComponent<P & ResponsiveProps, State> {
    state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    render() {
      const isTablet = this.state.width > minTablet && this.state.width <= maxTablet;
      return (
        <Component
          {...(this.props as P)}
          width={this.state.width}
          height={this.state.height}
          isMobile={this.state.width < minTablet}
          isTablet={isTablet}
        />
      );
    }
  };
};
