import { ReactElement } from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  margin: 30px 0;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colors.base.primary};
  font-size: ${(props) => props.theme.fonts.size.title.mobile};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.title.desktop};
  }
`;

const Subtitle = styled.h2`
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fonts.size.title.mobile};
  font-family: ${(props) => props.theme.fonts.family.fallbacks};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    font-size: ${(props) => props.theme.fonts.size.title.tablet};
  }
`;

const TextContainer = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    max-width: 500px;
  }
`;

const LoaderContainer = styled.div`
  margin-top: 70px;
  text-align: center;
`;

const LoaderImage = styled.img`
  height: 140px;
  width: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em 2em;
  text-align: center;
`;

export function LoadingContent(props: { title: string; subtitle: string; image: string }): ReactElement {
  return (
    <Container>
      <TextContainer>
        <Title>{props.title}</Title>
        <Subtitle>{props.subtitle}</Subtitle>
      </TextContainer>
      <LoaderContainer>
        <LoaderImage src={props.image} alt='' />
      </LoaderContainer>
    </Container>
  );
}
