// This is imported in `Base.ts` and in `Components/withResponsive`, but it is intentionally not exported from the library to prevent confusion
export const breakpoints = {
  mobile: {
    max: '768px',
    min: '320px',
  },
  tablet: {
    max: '1023px',
    min: '769px',
  },
  desktop: {
    min: '1024px',
    max: '1600px',
  },
};
