import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ExcellentFinancialHealth(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg width='42' height='49' viewBox='0 0 42 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.2687 7.19335C32.7243 7.90425 34.2437 10.3647 33.663 12.6901C33.0824 15.0154 30.6219 16.3237 28.1663 15.6128C25.7107 14.9019 24.1914 12.4415 24.772 10.1161C25.3527 7.79072 27.8131 6.48244 30.2687 7.19335Z'
        stroke={primaryColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M35.3561 23.9854L30.8515 22.682C29.9446 22.4196 29.081 22.0052 28.3065 21.4589L23.7377 18.2379C19.012 14.8841 13.0874 13.7115 7.6644 15.0546C6.29902 15.4017 5.5567 16.7706 6.00606 18.1108C6.44928 19.4319 7.88926 20.2359 9.24497 19.9203C12.3719 19.1556 15.7543 19.5429 18.7538 21.0055L10.5521 29.6638L5.75872 28.277C4.36022 27.8723 2.95681 28.6181 2.6261 29.9416C2.2954 31.2651 3.16201 32.6677 4.56051 33.0723L10.8943 34.9049C11.8401 35.1785 12.8329 34.931 13.4676 34.261L16.1153 31.4637L20.7344 35.5631L20.0738 41.7515C19.9455 42.9896 20.796 44.1887 22.0644 44.5557C22.1776 44.5884 22.2923 44.6148 22.4074 44.6326C23.8372 44.8612 25.1159 43.9405 25.2614 42.5754L25.9155 36.3989C26.0908 34.7767 25.4211 33.1226 24.1233 31.9741L19.5068 27.8788L23.2595 23.9135L25.4157 25.432C26.7053 26.345 28.1404 27.0379 29.6533 27.4774L34.1579 28.7807C35.5564 29.1853 36.9598 28.4396 37.2905 27.1161C37.6213 25.7925 36.7546 24.39 35.3561 23.9854Z'
        stroke={primaryColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
