import { ReactElement } from 'react';

export function CopyIcon(): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.04545 3.5H9.95455C10.2556 3.5 10.5 3.75272 10.5 4.0641V13.9359C10.5 14.2473 10.2556 14.5 9.95455 14.5H2.04545C1.74436 14.5 1.5 14.2473 1.5 13.9359V4.0641C1.5 3.75272 1.74436 3.5 2.04545 3.5Z'
        fill='#F6F6F6'
        stroke='#757575'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.04545 0.5H12.9545C13.2556 0.5 13.5 0.752718 13.5 1.0641V10.9359C13.5 11.2473 13.2556 11.5 12.9545 11.5H5.04545C4.74436 11.5 4.5 11.2473 4.5 10.9359V1.0641C4.5 0.752718 4.74436 0.5 5.04545 0.5Z'
        fill='#F6F6F6'
        stroke='#757575'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
