import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactNode, ReactElement, isValidElement, Fragment } from 'react';

import { Title } from '../../Components/Typography';
import { Container, HeaderWrapper, Content, OffsetContent, Subtitle } from './Styles';

export type CenteredLayoutProps = {
  title: string;
  subtitle?: string | ReactNode;
  isFullWidthLayout?: boolean;
  header?: ReactElement | null;
  offsetContent?: ReactElement;
  children: ReactNode;
  footer?: ReactElement;
};

export function CenteredLayout(props: CenteredLayoutProps): ReactElement {
  const { isFullWidthLayout, header } = props;
  const isSubtitleReactComponent = isValidElement(props.subtitle);

  return (
    <Container>
      {header && <HeaderWrapper>{header}</HeaderWrapper>}

      <Fragment>
        <Box component='main' pt={6}>
          <Content px={{ xs: isFullWidthLayout ? 0 : 3, md: 0 }}>
            {props.offsetContent && (
              <OffsetContent px={{ xs: isFullWidthLayout ? 3 : 0, md: 0 }} mb={{ xs: 3, md: 0 }}>
                {props.offsetContent}
              </OffsetContent>
            )}
            <Box width='100%'>
              <Box px={{ xs: isFullWidthLayout ? 3 : 0, md: 0 }}>
                {props.title && (
                  <Box mt={0.5}>{props.title ? <Title level={1}>{ReactHtmlParser(props.title)}</Title> : null}</Box>
                )}
                {props.subtitle && (
                  <Box mt={2}>
                    {isSubtitleReactComponent && props.subtitle}
                    {!isSubtitleReactComponent && <Subtitle>{ReactHtmlParser(props.subtitle as string)}</Subtitle>}
                  </Box>
                )}
              </Box>

              <Box mt={2}>{props.children}</Box>
            </Box>
          </Content>
        </Box>

        {props.footer ?? null}
      </Fragment>
    </Container>
  );
}
