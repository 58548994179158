import { ReactElement } from 'react';

import { Table, TableBody, TableContainer } from '@breathelife/mui';

import { JsonTableRow, TableIndexCell, TableJsonCell } from './Styles';

type Props = {
  jsonObject: Record<string, unknown> | undefined;
};

export function JsonViewer(props: Props): ReactElement {
  const { jsonObject } = props;
  const jsonSplitIntoArray = jsonObject ? JSON.stringify(jsonObject, null, 8).split('\n') : [];

  return (
    <TableContainer>
      <Table size='small'>
        <TableBody>
          {jsonSplitIntoArray.map((row, index) => (
            <JsonTableRow key={'json-row-' + index}>
              <TableIndexCell component='th' scope='row'>
                {index}
              </TableIndexCell>
              <TableJsonCell align='left'>{row}</TableJsonCell>
            </JsonTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
