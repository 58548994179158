import styled from 'styled-components';

export const ButtonContainer = styled.button<{ isChecked: boolean }>`
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
  background-color: ${(props) => props.theme.colors.grey[0]};

  > svg {
    margin: auto;
  }

  svg {
    .inside {
      fill: ${(props) => (props.isChecked ? props.theme.colors.primary[20] : props.theme.colors.grey[30])};
    }
    .outline {
      fill: ${(props) => (props.isChecked ? props.theme.colors.primary[60] : props.theme.colors.grey[60])};
    }
  }

  &:hover {
    svg {
      .outline {
        fill: ${(props) => !props.isChecked && props.theme.colors.grey[90]};
      }
    }
  }

  &:focus:not(:active) {
    border: 2px solid ${(props) => props.theme.colors.base.primary} !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile.min}) {
    width: 32px;
    height: 32px;
  }
`;
