import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background.leftPanel};
  padding: 34px 10px 70px 50px;
  height: 100%;
`;

export const InfoViewContainer = styled.div`
  padding: 0 10px 15px 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    padding: 0;
    margin-top: 100px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const LogoContainer = styled.div`
  img {
    object-fit: contain;
    height: ${(props) => props.theme.metrics.logo.height};
    align-self: flex-start;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.text.title};
  font-family: ${(props) => props.theme.fonts.family.title};
  font-weight: ${(props) => props.theme.fonts.weight.title};
  font-size: 42px;
  line-height: 57px;
  text-align: left;
  white-space: pre-wrap;
  padding-top: 110px;
  margin-top: 0;

  &:after {
    content: '';
    display: block;
    background-color: ${(props) => props.theme.colors.base.primary};
    height: 3px;
    width: 50px;
    border-radius: 7px;
    margin-top: 25px;
  }
`;
