import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function HospitalIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.707 26.8736C17.2226 26.66 17.7753 26.55 18.3334 26.55C18.8915 26.55 19.4441 26.66 19.9598 26.8736C20.4754 27.0871 20.9439 27.4002 21.3386 27.7948C21.7332 28.1895 22.0463 28.658 22.2599 29.1736C22.4734 29.6893 22.5834 30.2419 22.5834 30.8C22.5834 31.3582 22.4734 31.9108 22.2599 32.4265C22.0463 32.9421 21.7332 33.4106 21.3386 33.8053C20.9439 34.1999 20.4754 34.513 19.9598 34.7265C19.4441 34.9401 18.8915 35.05 18.3334 35.05C17.7753 35.05 17.2226 34.9401 16.707 34.7265C16.1913 34.513 15.7228 34.1999 15.3282 33.8053C14.9335 33.4106 14.6205 32.9421 14.4069 32.4265C14.1933 31.9108 14.0834 31.3582 14.0834 30.8C14.0834 30.2419 14.1933 29.6893 14.4069 29.1736C14.6205 28.658 14.9335 28.1895 15.3282 27.7948C15.7228 27.4002 16.1913 27.0871 16.707 26.8736ZM18.3334 28.25C17.9985 28.25 17.6669 28.316 17.3575 28.4442C17.0482 28.5723 16.767 28.7601 16.5303 28.9969C16.2935 29.2337 16.1056 29.5148 15.9775 29.8242C15.8493 30.1336 15.7834 30.4652 15.7834 30.8C15.7834 31.1349 15.8493 31.4665 15.9775 31.7759C16.1056 32.0853 16.2935 32.3664 16.5303 32.6032C16.767 32.84 17.0482 33.0278 17.3575 33.1559C17.6669 33.2841 17.9985 33.35 18.3334 33.35C18.6682 33.35 18.9998 33.2841 19.3092 33.1559C19.6186 33.0278 19.8997 32.84 20.1365 32.6032C20.3733 32.3664 20.5611 32.0853 20.6893 31.7759C20.8174 31.4665 20.8834 31.1349 20.8834 30.8C20.8834 30.4652 20.8174 30.1336 20.6893 29.8242C20.5611 29.5148 20.3733 29.2337 20.1365 28.9969C19.8997 28.7601 19.6186 28.5723 19.3092 28.4442C18.9998 28.316 18.6682 28.25 18.3334 28.25Z'
        fill={primaryColor}
      />
      <path
        d='M25.1333 27.4H37.9777C38.7793 27.4 39.548 27.7184 40.1148 28.2852C40.6816 28.852 41 29.6207 41 30.4222V34.2H25.1333V27.4Z'
        fill={tertiaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.2833 27.4C24.2833 26.9306 24.6639 26.55 25.1333 26.55H37.9778C39.0047 26.55 39.9897 26.958 40.7158 27.6842C41.442 28.4104 41.85 29.3953 41.85 30.4223V34.2C41.85 34.6695 41.4694 35.05 41 35.05H25.1333C24.6639 35.05 24.2833 34.6695 24.2833 34.2V27.4ZM25.9833 28.25V33.35H40.15V30.4223C40.15 29.8462 39.9211 29.2936 39.5138 28.8863C39.1064 28.4789 38.5539 28.25 37.9778 28.25H25.9833Z'
        fill={primaryColor}
      />
      <path d='M9.26672 34.2H41.0001V38.7333H9.26672V34.2Z' fill='#F8F9FD' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.41675 34.2C8.41675 33.7306 8.79731 33.35 9.26675 33.35H41.0001C41.4695 33.35 41.8501 33.7306 41.8501 34.2V38.7334C41.8501 39.2028 41.4695 39.5834 41.0001 39.5834H9.26675C8.79731 39.5834 8.41675 39.2028 8.41675 38.7334V34.2ZM10.1167 35.05V37.8834H40.1501V35.05H10.1167Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.26675 26.55C9.73619 26.55 10.1167 26.9306 10.1167 27.4V41C10.1167 41.4695 9.73619 41.85 9.26675 41.85C8.79731 41.85 8.41675 41.4695 8.41675 41V27.4C8.41675 26.9306 8.79731 26.55 9.26675 26.55Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41 37.8834C41.4695 37.8834 41.85 38.2639 41.85 38.7334V41C41.85 41.4695 41.4695 41.85 41 41.85C40.5306 41.85 40.15 41.4695 40.15 41V38.7334C40.15 38.2639 40.5306 37.8834 41 37.8834Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.1736 6.47354C29.6893 6.25995 30.2419 6.15002 30.8 6.15002C31.3582 6.15002 31.9108 6.25995 32.4265 6.47354C32.9421 6.68712 33.4106 7.00017 33.8053 7.39482C34.1999 7.78947 34.513 8.25798 34.7265 8.77362C34.9401 9.28925 35.05 9.84191 35.05 10.4C35.05 10.9581 34.9401 11.5108 34.7265 12.0264C34.513 12.5421 34.1999 13.0106 33.8053 13.4052C33.4106 13.7999 32.9421 14.1129 32.4265 14.3265C31.9108 14.5401 31.3582 14.65 30.8 14.65C30.2419 14.65 29.6893 14.5401 29.1736 14.3265C28.658 14.1129 28.1895 13.7999 27.7948 13.4052C27.4002 13.0106 27.0871 12.5421 26.8736 12.0264C26.66 11.5108 26.55 10.9581 26.55 10.4C26.55 9.84191 26.66 9.28925 26.8736 8.77362C27.0871 8.25799 27.4002 7.78947 27.7948 7.39482C28.1895 7.00017 28.658 6.68712 29.1736 6.47354ZM30.8 7.85002C30.4652 7.85002 30.1336 7.91598 29.8242 8.04413C29.5148 8.17228 29.2337 8.36011 28.9969 8.5969C28.7601 8.83369 28.5723 9.1148 28.4442 9.42418C28.316 9.73356 28.25 10.0652 28.25 10.4C28.25 10.7349 28.316 11.0665 28.4442 11.3759C28.5723 11.6852 28.7601 11.9664 28.9969 12.2031C29.2337 12.4399 29.5148 12.6278 29.8242 12.7559C30.1336 12.8841 30.4652 12.95 30.8 12.95C31.1349 12.95 31.4665 12.8841 31.7759 12.7559C32.0853 12.6278 32.3664 12.4399 32.6032 12.2031C32.84 11.9664 33.0278 11.6852 33.1559 11.3759C33.2841 11.0665 33.35 10.7349 33.35 10.4C33.35 10.0652 33.2841 9.73356 33.1559 9.42418C33.0278 9.1148 32.84 8.83369 32.6032 8.5969C32.3664 8.36011 32.0853 8.17228 31.7759 8.04413C31.4665 7.91598 31.1349 7.85002 30.8 7.85002Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.6667 19.1833C28.6898 19.1833 27.7529 19.5714 27.0621 20.2622C26.3714 20.9529 25.9833 21.8898 25.9833 22.8667C25.9833 23.3361 25.6028 23.7167 25.1333 23.7167C24.6639 23.7167 24.2833 23.3361 24.2833 22.8667C24.2833 21.4389 24.8505 20.0696 25.8601 19.0601C26.8696 18.0505 28.2389 17.4833 29.6667 17.4833H31.9333C33.3611 17.4833 34.7303 18.0505 35.7399 19.0601C36.7495 20.0696 37.3167 21.4389 37.3167 22.8667C37.3167 23.3361 36.9361 23.7167 36.4667 23.7167C35.9972 23.7167 35.6167 23.3361 35.6167 22.8667C35.6167 21.8898 35.2286 20.9529 34.5378 20.2622C33.8471 19.5714 32.9102 19.1833 31.9333 19.1833H29.6667Z'
        fill={primaryColor}
      />
      <path
        d='M19.4667 11.5333H16.0667V8.13333C16.0667 7.83275 15.9473 7.54449 15.7347 7.33195C15.5222 7.1194 15.2339 7 14.9333 7H12.6667C12.3661 7 12.0778 7.1194 11.8653 7.33195C11.6527 7.54449 11.5333 7.83275 11.5333 8.13333V11.5333H8.13333C7.83275 11.5333 7.54449 11.6527 7.33195 11.8653C7.1194 12.0778 7 12.3661 7 12.6667V14.9333C7 15.2339 7.1194 15.5222 7.33195 15.7347C7.54449 15.9473 7.83275 16.0667 8.13333 16.0667H11.5333V19.4667C11.5333 19.7672 11.6527 20.0555 11.8653 20.2681C12.0778 20.4806 12.3661 20.6 12.6667 20.6H14.9333C15.2339 20.6 15.5222 20.4806 15.7347 20.2681C15.9473 20.0555 16.0667 19.7672 16.0667 19.4667V16.0667H19.4667C19.7672 16.0667 20.0555 15.9473 20.2681 15.7347C20.4806 15.5222 20.6 15.2339 20.6 14.9333V12.6667C20.6 12.3661 20.4806 12.0778 20.2681 11.8653C20.0555 11.6527 19.7672 11.5333 19.4667 11.5333Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2643 6.73093C11.6362 6.35898 12.1407 6.15002 12.6667 6.15002H14.9334C15.4594 6.15002 15.9638 6.35898 16.3358 6.73093C16.7077 7.10288 16.9167 7.60735 16.9167 8.13336V10.6834H19.4667C19.9927 10.6834 20.4972 10.8923 20.8691 11.2643C21.2411 11.6362 21.45 12.1407 21.45 12.6667V14.9334C21.45 15.4594 21.2411 15.9638 20.8691 16.3358C20.4972 16.7077 19.9927 16.9167 19.4667 16.9167H16.9167V19.4667C16.9167 19.9927 16.7077 20.4972 16.3358 20.8691C15.9638 21.2411 15.4594 21.45 14.9334 21.45H12.6667C12.1407 21.45 11.6362 21.2411 11.2643 20.8691C10.8923 20.4972 10.6834 19.9927 10.6834 19.4667V16.9167H8.13336C7.60735 16.9167 7.10288 16.7077 6.73093 16.3358C6.35898 15.9638 6.15002 15.4594 6.15002 14.9334V12.6667C6.15002 12.1407 6.35898 11.6362 6.73093 11.2643C7.10288 10.8923 7.60735 10.6834 8.13336 10.6834H10.6834V8.13336C10.6834 7.60735 10.8923 7.10288 11.2643 6.73093ZM12.6667 7.85002C12.5915 7.85002 12.5195 7.87988 12.4663 7.93301C12.4132 7.98615 12.3834 8.05821 12.3834 8.13336V11.5334C12.3834 12.0028 12.0028 12.3834 11.5334 12.3834H8.13336C8.05821 12.3834 7.98615 12.4132 7.93301 12.4663C7.87988 12.5195 7.85002 12.5915 7.85002 12.6667V14.9334C7.85002 15.0085 7.87988 15.0806 7.93301 15.1337C7.98615 15.1868 8.05821 15.2167 8.13336 15.2167H11.5334C12.0028 15.2167 12.3834 15.5972 12.3834 16.0667V19.4667C12.3834 19.5418 12.4132 19.6139 12.4663 19.667C12.5195 19.7202 12.5915 19.75 12.6667 19.75H14.9334C15.0085 19.75 15.0806 19.7202 15.1337 19.667C15.1868 19.6139 15.2167 19.5418 15.2167 19.4667V16.0667C15.2167 15.5972 15.5972 15.2167 16.0667 15.2167H19.4667C19.5418 15.2167 19.6139 15.1868 19.667 15.1337C19.7202 15.0806 19.75 15.0085 19.75 14.9334V12.6667C19.75 12.5915 19.7202 12.5195 19.667 12.4663C19.6139 12.4132 19.5418 12.3834 19.4667 12.3834H16.0667C15.5972 12.3834 15.2167 12.0028 15.2167 11.5334V8.13336C15.2167 8.05821 15.1868 7.98615 15.1337 7.93301C15.0806 7.87988 15.0085 7.85002 14.9334 7.85002H12.6667Z'
        fill={primaryColor}
      />
    </svg>
  );
}
