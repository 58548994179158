import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ErrorOutlined(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.75 12C0.75 14.9837 1.93526 17.8452 4.04505 19.955C6.15483 22.0647 9.01631 23.25 12 23.25C14.9837 23.25 17.8452 22.0647 19.955 19.955C22.0647 17.8452 23.25 14.9837 23.25 12C23.25 9.01631 22.0647 6.15483 19.955 4.04505C17.8452 1.93526 14.9837 0.75 12 0.75C9.01631 0.75 6.15483 1.93526 4.04505 4.04505C1.93526 6.15483 0.75 9.01631 0.75 12Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.50767 3.52177C3.50998 3.5194 3.51232 3.51704 3.51467 3.51468C3.51702 3.51233 3.51938 3.51 3.52176 3.50768C5.77152 1.26165 8.82072 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1789 22.7387 18.2277 20.4931 20.4774C20.4906 20.4801 20.488 20.4827 20.4853 20.4853C20.4827 20.488 20.4801 20.4906 20.4774 20.4931C18.2277 22.7387 15.1789 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.82073 1.26164 5.77153 3.50767 3.52177ZM4.06399 5.12467C2.41576 7.02702 1.5 9.4663 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5C14.5337 22.5 16.973 21.5842 18.8753 19.936L4.06399 5.12467ZM19.936 18.8754C21.5842 16.973 22.5 14.5337 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.46629 1.5 7.02701 2.41577 5.12465 4.064L19.936 18.8754Z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
