import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Close(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='14' viewBox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg'>
      <path
        clipRule='evenodd'
        d='m5.22207 7.00079-4.514998-4.51501c-.498843-.49884-.498844-1.30762 0-1.806468.498848-.498843 1.307628-.498843 1.806468.000001l4.51501 4.515007 4.51795-4.517945c.4988-.498844 1.3076-.498844 1.8065-.000001.4988.498846.4988 1.307626 0 1.806476l-4.51798 4.51794 4.51738 4.51741c.4988.4988.4988 1.3076 0 1.8064-.4989.4989-1.3077.4989-1.8065 0l-4.51735-4.51734-4.51443 4.51444c-.49884.4988-1.30763.4988-1.80647 0-.498843-.4989-.498843-1.3076.000001-1.8065z'
        fill={props.color?.primary || theme.colors.icons.primary}
        fillRule='evenodd'
      />
    </svg>
  );
}
