import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  width: 40px;
  height: 40px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #eeeeee;
    border-radius: 50%;
    transform: scale(0.5);
    opacity: 0;
    transition: all 0.1s ease-out;
  }

  &:hover:before {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 1px;
  background-color: #000;
  margin-bottom: 4px;
  z-index: 1;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 190px;
`;

export const Link = styled.a`
  margin: 10px;
  color: ${(props) => props.theme.colors.black};
`;
