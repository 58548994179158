import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function PoliceIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.3245 24C29.3245 23.0611 30.0856 22.3 31.0245 22.3H39.4313C39.6029 21.9403 39.7836 21.564 39.9625 21.2021L39.9645 21.198C40.0163 21.0938 40.0462 20.9761 40.0497 20.8539C40.0531 20.7317 40.0299 20.6118 39.9836 20.5036C39.9373 20.3955 39.8702 20.3043 39.7909 20.2341C39.7119 20.1641 39.6226 20.1166 39.531 20.0911L39.5088 20.0848C36.4403 19.1858 33.7902 17.2012 32.0161 14.4952C32.009 14.4844 32.0021 14.4736 31.9953 14.4626C31.1038 13.0332 29.8919 11.8557 28.4686 11.0277C27.0597 10.2081 25.4839 9.75417 23.875 9.7008C22.2661 9.75417 20.6903 10.2081 19.2814 11.0277C17.8581 11.8557 16.6462 13.0332 15.7547 14.4626C15.7479 14.4736 15.741 14.4844 15.7339 14.4952C13.9598 17.2012 11.3097 19.1858 8.24125 20.0848L8.21898 20.0911C8.12737 20.1166 8.0381 20.1641 7.95906 20.2341C7.87981 20.3043 7.81271 20.3956 7.76643 20.5036C7.72009 20.6118 7.69689 20.7317 7.70033 20.8539C7.70378 20.976 7.73367 21.0938 7.78554 21.198L7.78618 21.1993C7.9662 21.5619 8.14752 21.9396 8.3193 22.3H16.7255C17.6644 22.3 18.4255 23.0611 18.4255 24H7.24697C7.14078 23.7801 7.02997 23.547 6.91555 23.3064C6.70811 22.8702 6.48879 22.409 6.26352 21.9553C6.10105 21.6287 6.01136 21.2688 6.00101 20.9018C5.99066 20.5348 6.05992 20.1701 6.20372 19.8343C6.34751 19.4986 6.5622 19.2003 6.83206 18.9614C7.10191 18.7224 7.4201 18.5488 7.76331 18.4533C10.4356 17.6705 12.7547 15.9387 14.3122 13.5631C15.3479 11.9023 16.7602 10.5277 18.4266 9.55825C20.093 8.58883 21.9632 8.05395 23.875 8C25.7868 8.05395 27.657 8.58883 29.3234 9.55825C30.9898 10.5277 32.4021 11.9023 33.4378 13.5631C34.9953 15.9387 37.3144 17.6705 39.9867 18.4533C40.3299 18.5488 40.6481 18.7224 40.9179 18.9614C41.1878 19.2003 41.4025 19.4986 41.5463 19.8343C41.6901 20.1701 41.7593 20.5348 41.749 20.9018C41.7386 21.2688 41.649 21.6287 41.4865 21.9553C41.2639 22.4056 41.046 22.8632 40.8396 23.2965C40.7233 23.5406 40.6107 23.777 40.503 24H29.3245Z'
        fill={primaryColor}
      />
      <path
        d='M8.12628 28.125C9.57752 31.0894 11.8124 33.6 14.5888 35.3848C17.3651 37.1696 20.577 38.1604 23.8763 38.25C27.1756 38.1604 30.3875 37.1696 33.1638 35.3848C35.9401 33.6 38.175 31.0894 39.6263 28.125H8.12628Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.40513 27.6751C7.56039 27.4263 7.83298 27.275 8.12631 27.275H39.6263C39.9196 27.275 40.1922 27.4263 40.3475 27.6751C40.5027 27.924 40.5187 28.2353 40.3897 28.4988C38.8703 31.6025 36.5303 34.2311 33.6235 36.0998C30.7166 37.9685 27.3538 39.0059 23.8994 39.0997C23.884 39.1001 23.8686 39.1001 23.8532 39.0997C20.3988 39.0059 17.036 37.9685 14.1291 36.0998C11.2223 34.2311 8.88234 31.6025 7.36288 28.4988C7.23391 28.2353 7.24988 27.924 7.40513 27.6751ZM9.55134 28.975C10.9138 31.2686 12.7937 33.2204 15.0484 34.6698C17.6878 36.3665 20.7402 37.3104 23.8763 37.3997C27.0124 37.3104 30.0648 36.3665 32.7042 34.6698C34.9589 33.2204 36.8388 31.2686 38.2013 28.975H9.55134Z'
        fill={primaryColor}
      />
      <path d='M27.4988 21.1275L23.8763 24.75L20.2538 21.1275V16.2975H27.4988V21.1275Z' fill={tertiaryColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.4038 16.2975C19.4038 15.8281 19.7844 15.4475 20.2538 15.4475H27.4988C27.9683 15.4475 28.3488 15.8281 28.3488 16.2975V21.1275C28.3488 21.3529 28.2593 21.5691 28.0998 21.7286L24.4773 25.3511C24.3179 25.5105 24.1017 25.6 23.8763 25.6C23.6509 25.6 23.4347 25.5105 23.2753 25.3511L19.6528 21.7286C19.4934 21.5691 19.4038 21.3529 19.4038 21.1275V16.2975ZM21.1038 17.1475V20.7754L23.8763 23.5479L26.6488 20.7754V17.1475H21.1038Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.12631 22.775C8.59575 22.775 8.97631 23.1556 8.97631 23.625V28.125C8.97631 28.5945 8.59575 28.975 8.12631 28.975C7.65686 28.975 7.27631 28.5945 7.27631 28.125V23.625C7.27631 23.1556 7.65686 22.775 8.12631 22.775Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.6263 22.775C40.0957 22.775 40.4763 23.1556 40.4763 23.625V28.125C40.4763 28.5945 40.0957 28.975 39.6263 28.975C39.1569 28.975 38.7763 28.5945 38.7763 28.125V23.625C38.7763 23.1556 39.1569 22.775 39.6263 22.775Z'
        fill={primaryColor}
      />
    </svg>
  );
}
