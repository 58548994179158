import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f2f2f2;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`;

export const Content = styled.div<{ hasLeftContent: boolean; showFullSize?: boolean }>`
  max-height: ${(props) => (props.showFullSize ? '100vh' : undefined)};
  display: flex;
  flex-direction: ${(props) => (props.hasLeftContent ? 'row' : 'column')};
`;

export const CloseButton = styled.button`
  background: ${(props) => props.theme.colors.grey[10]};
  border: 1px solid ${(props) => props.theme.colors.grey[30]};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  outline: none;
  span {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: ${(props) => props.theme.colors.grey[70]};
    margin-left: 8px;
  }
  :focus {
    border-color: ${(props) => props.theme.colors.selection.border.focus};
  }
`;

export function createStyles(
  width: string,
  shadowColor: string,
  isMobile: boolean,
  isFullSizeMobile: boolean,
  isVideoModal: boolean,
): Record<string, any> {
  // @ts-ignore display doesn't exist in the theme in types.d.ts (we need to have different types for different carriers)
  const showFullSize = isFullSizeMobile && isMobile;
  return {
    root: {
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.75)',
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
      zIndex: 1302,
    },
    overlay: {
      overflow: showFullSize ? 'hidden' : undefined,
      padding: showFullSize ? 0 : undefined,
      backgroundColor: showFullSize ? '#fff' : 'rgba(0, 0, 0, 0.5)',
    },
    modal: {
      width,
      maxWidth: '100%',
      maxHeight: showFullSize ? '100%' : 'unset',
      height: showFullSize ? '100%' : undefined,
      position: 'relative',
      borderRadius: showFullSize || isVideoModal ? 0 : '8px',
      boxShadow: `0 12px 24px -6px ${shadowColor}, 0 0 1px 0 ${shadowColor}`,
      backgroundColor: '#ffffff',
      padding: 0,
      lineHeight: isVideoModal ? '0' : 'unset',
      overflow: isVideoModal ? undefined : 'hidden',
    },
  };
}
