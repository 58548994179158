import { ReactNode, ReactElement, Fragment } from 'react';

import { LegalLink, LegalMenu } from '../LegalMenu/LegalMenu';
import { HeaderProgressBar } from './HeaderProgressBar';
import {
  ChildrenContainer,
  Content,
  InfoViewContainer,
  LogoContainer,
  MinimalMobileHeaderContainer,
  MobileHeaderContainer,
  SectionTitle,
  TitleContainer,
} from './TransactionFlowStyles';

type Props = {
  infoView?: ReactNode;
  logo: ReactNode;
  collapseHeader?: boolean;
  children: ReactNode;
  isAgentSession?: boolean;
  progress?: number;
  legalLinks: LegalLink[];
  title?: string;
  showMinimalHeader?: boolean;
};

export function TransactionFlowMobileHeader({
  collapseHeader = false,
  showMinimalHeader = false,
  ...props
}: Props): ReactElement {
  const height = !props.title ? '80px' : '130px';
  const showInfoViewForAgent = props.title && props.isAgentSession;

  const baseHeaderContent = (
    <Content>
      <LogoContainer>{props.logo}</LogoContainer>
      <ChildrenContainer>{props.children}</ChildrenContainer>
      <LegalMenu links={props.legalLinks} />
    </Content>
  );

  if (showMinimalHeader) {
    <MinimalMobileHeaderContainer>{baseHeaderContent}</MinimalMobileHeaderContainer>;
  }
  return (
    <Fragment>
      <MobileHeaderContainer $height={height}>
        {baseHeaderContent}
        {props.title && (
          <TitleContainer>
            <SectionTitle>{props.title}</SectionTitle>
          </TitleContainer>
        )}
        {(!props.title || collapseHeader) && !showInfoViewForAgent && props.progress && (
          <HeaderProgressBar progress={props.progress} />
        )}
      </MobileHeaderContainer>
      {showInfoViewForAgent && (
        <InfoViewContainer>
          {props.infoView}
          {props.progress && <HeaderProgressBar progress={props.progress} />}
        </InfoViewContainer>
      )}
    </Fragment>
  );
}
