import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScheduleTable = styled.table`
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.grey[30]};
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  max-width: 340px;

  td {
    border: 1px solid ${(props) => props.theme.colors.grey[30]};
  }
`;

export const ScheduleTableBody = styled.tbody``;

export const ScheduleTableColumnHeader = styled.thead``;

export const ScheduleTableColumnHeaderItem = styled.th<{ active: boolean }>`
  font-size: 14px;
  text-align: center;
  padding: 18px 0;
  color: ${(props) => (props.active ? props.theme.colors.base.primary : props.theme.colors.grey.dark)};
  font-weight: ${(props) => (props.active ? 600 : 'regular')};
  margin: auto;
`;

export const ScheduleTableHelpTextContainer = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey[90]};
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  svg {
    width: 12px;
    height: 12px;
    margin-right: 10px;
    .inside {
      fill: ${(props) => props.theme.colors.primary[20]};
    }
    g {
      fill: ${(props) => props.theme.colors.primary[60]};
    }
  }
`;

export const CenteredText = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px 10px;
`;
