import { ReactNode, ReactElement } from 'react';

import { StyledFooter } from './Styles';

type Props = {
  children?: ReactNode;
};

export function PlanFinderFooter(props: Props): ReactElement {
  return <StyledFooter>{props.children}</StyledFooter>;
}
