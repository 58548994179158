import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function PersonalInformationIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M27.3229 17.6167V15.1881L30.1585 17.2119L32.184 15.9976L34.6145 17.2119L35.8298 16.4024L37.0451 14.3786V18.4262L35.0196 20.45L32.184 21.6643L28.9432 20.0452L27.3229 17.6167Z'
        fill={secondaryColor}
      />
      <path
        d='M29.7534 7.49762L32.184 6.6881L35.0196 7.49762L36.64 9.11667L37.0451 11.1405L35.4247 11.5452H32.5891L30.1585 10.7357L28.1331 9.11667L29.7534 7.49762Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.5795 8.60964C27.8534 8.34593 28.2893 8.35396 28.5533 8.62758C29.2499 9.34982 30.0852 9.92425 31.0091 10.3165C31.9331 10.7088 32.9267 10.9109 33.9306 10.9107C34.9471 10.911 35.9534 10.7038 36.8869 10.3018C37.2362 10.1514 37.6414 10.3124 37.7919 10.6614C37.9424 11.0104 37.7813 11.4153 37.432 11.5656C36.3263 12.0417 35.1349 12.2872 33.9309 12.2869M27.5795 8.60964C27.3057 8.87336 27.2976 9.30897 27.5616 9.58259ZM27.5616 9.58259C28.3867 10.4381 29.3761 11.1185 30.4705 11.5831ZM30.4705 11.5831C31.5648 12.0477 32.7419 12.2871 33.9309 12.2869Z'
        fill={primaryColor}
      />
      <path d='M33.9309 12.2869L27.5795 8.60964L27.5616 9.58259L30.4705 11.5831L33.9309 12.2869Z' fill={primaryColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.2917 7.37619C31.1709 7.37619 30.096 7.82107 29.3034 8.61296C28.5109 9.40484 28.0657 10.4789 28.0657 11.5988V16.5094C28.0657 17.6293 28.5109 18.7034 29.3034 19.4953C30.096 20.2871 31.1709 20.732 32.2917 20.732C33.4125 20.732 34.4874 20.2871 35.28 19.4953C36.0725 18.7034 36.5177 17.6293 36.5177 16.5094V11.5988C36.5177 10.4789 36.0725 9.40484 35.28 8.61296C34.4874 7.82107 33.4125 7.37619 32.2917 7.37619ZM28.3295 7.63984C29.3804 6.58987 30.8056 6 32.2917 6C33.7778 6 35.203 6.58987 36.2539 7.63984C37.3047 8.68981 37.8951 10.1139 37.8951 11.5988V16.5094C37.8951 17.9943 37.3047 19.4184 36.2539 20.4684C35.203 21.5183 33.7778 22.1082 32.2917 22.1082C30.8056 22.1082 29.3804 21.5183 28.3295 20.4684C27.2787 19.4184 26.6884 17.9943 26.6884 16.5094V11.5988C26.6884 10.1139 27.2787 8.68981 28.3295 7.63984Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.377 11.6122C27.7573 11.6122 28.0657 11.9203 28.0657 12.3003C28.0657 12.8813 28.3123 13.8795 28.7036 14.8285C28.8951 15.293 29.1095 15.7166 29.3231 16.0456C29.5268 16.3591 29.6893 16.5232 29.7837 16.5885C29.7845 16.5881 29.7853 16.5877 29.7861 16.5873C29.8913 16.5309 30.0245 16.4136 30.2585 16.1909C30.2693 16.1805 30.2804 16.17 30.2917 16.1592C30.4921 15.9681 30.7537 15.7187 31.0526 15.5209C31.3792 15.3047 31.7925 15.1198 32.2926 15.1198C32.7927 15.1198 33.2061 15.3047 33.5327 15.5209C33.8317 15.7188 34.0933 15.9683 34.2937 16.1595C34.3049 16.1701 34.316 16.1806 34.3268 16.1909C34.5607 16.4137 34.6938 16.531 34.799 16.5874C34.7996 16.5877 34.8002 16.588 34.8008 16.5883C34.8953 16.5229 35.0578 16.3589 35.2612 16.0456C35.4748 15.7166 35.6892 15.293 35.8808 14.8285C36.2721 13.8795 36.5187 12.8813 36.5187 12.3003C36.5187 11.9203 36.827 11.6122 37.2073 11.6122C37.5877 11.6122 37.896 11.9203 37.896 12.3003C37.896 13.1429 37.5739 14.3349 37.1543 15.3527C36.9403 15.8716 36.6887 16.3756 36.4166 16.7947C36.1584 17.1923 35.8291 17.597 35.4319 17.8144L35.4313 17.8148C34.9576 18.0734 34.5005 17.989 34.1482 17.8002C33.8552 17.6433 33.5902 17.3907 33.3946 17.2043C33.3885 17.1985 33.3825 17.1928 33.3765 17.1871C33.1497 16.9712 32.9667 16.797 32.772 16.6681C32.5881 16.5464 32.4375 16.496 32.2926 16.496C32.1478 16.496 31.9972 16.5464 31.8133 16.6681C31.6185 16.797 31.4355 16.9712 31.2086 17.1872C31.2027 17.1928 31.1967 17.1985 31.1907 17.2042C30.9949 17.3907 30.7298 17.6433 30.4366 17.8003C30.0841 17.989 29.6271 18.0733 29.1534 17.8149L29.1524 17.8144C28.7553 17.597 28.426 17.1923 28.1677 16.7947C27.8956 16.3756 27.644 15.8716 27.4301 15.3527C27.0104 14.3349 26.6884 13.1429 26.6884 12.3003C26.6884 11.9203 26.9967 11.6122 27.377 11.6122Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.5265 20.2647C34.6471 19.9043 35.0372 19.7098 35.3979 19.8303C37.1067 20.4012 38.6256 21.4305 39.7885 22.8056C40.9513 24.1808 41.7135 25.8489 41.9916 27.6276C42.0503 28.0031 41.7933 28.355 41.4175 28.4137C41.0418 28.4724 40.6895 28.2155 40.6308 27.8401C40.3917 26.3106 39.7363 24.8763 38.7364 23.6938C37.7365 22.5114 36.4305 21.6264 34.9612 21.1355C34.6005 21.015 34.4058 20.6251 34.5265 20.2647Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.0568 20.2666C30.1774 20.627 29.9828 21.0168 29.6221 21.1373C28.1527 21.6282 26.8467 22.5133 25.8468 23.6957C24.8469 24.8781 24.1916 26.3125 23.9524 27.8419C23.8937 28.2174 23.5415 28.4742 23.1657 28.4156C22.7899 28.3569 22.5329 28.005 22.5916 27.6295C22.8697 25.8508 23.6319 24.1827 24.7948 22.8075C25.9577 21.4324 27.4765 20.4031 29.1853 19.8322C29.546 19.7117 29.9362 19.9061 30.0568 20.2666Z'
        fill={primaryColor}
      />
      <path
        d='M14.7645 18.831L18.0052 18.0214L20.8409 18.4262L23.6765 20.0452L25.2969 22.8786L26.1071 28.5452L27.3224 32.1881L27.7275 33.4024L26.9173 34.2119L24.0816 35.4262L21.6511 34.2119L18.8154 33.4024H17.6002L14.3594 34.2119L12.3339 35.4262L9.4983 34.2119L8.68811 32.9976L9.0932 31.7833L10.3085 28.95V27.331L10.7136 23.6881L11.9289 20.8548L14.7645 18.831Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.2065 19.7953C13.7841 18.2189 15.9239 17.3333 18.155 17.3333C20.3861 17.3333 22.5258 18.2189 24.1035 19.7953C25.6811 21.3716 26.5674 23.5096 26.5674 25.7389V27.6809C26.5674 28.1579 26.6644 28.6299 26.8525 29.0683L28.1404 32.0703C28.3574 32.5744 28.3675 33.1436 28.1683 33.6551C27.9691 34.1666 27.5767 34.5793 27.0757 34.8043L27.0679 34.8078C26.0006 35.2712 24.8807 35.6031 23.7331 35.7962C23.358 35.8593 23.0028 35.6067 22.9396 35.232C22.8765 34.8572 23.1293 34.5023 23.5044 34.4392C24.5402 34.2649 25.5509 33.9655 26.5144 33.5475C26.6842 33.4703 26.8171 33.3298 26.8847 33.1561C26.9528 32.9812 26.9494 32.7866 26.8752 32.6142L25.5866 29.6105C25.325 29.0008 25.1901 28.3443 25.1901 27.6809V25.7389C25.1901 23.8746 24.4489 22.0866 23.1296 20.7684C21.8102 19.4501 20.0208 18.7095 18.155 18.7095C16.2892 18.7095 14.4997 19.4501 13.1804 20.7684C11.8611 22.0866 11.1199 23.8746 11.1199 25.7389V27.6809C11.1199 28.3442 10.985 29.0007 10.7235 29.6104C10.7234 29.6104 10.7235 29.6103 10.7235 29.6104L9.43566 32.6143C9.3614 32.7867 9.3579 32.9812 9.42591 33.1561C9.49346 33.3298 9.6262 33.4703 9.79578 33.5476C10.7592 33.9655 11.7699 34.2649 12.8056 34.4392C13.1806 34.5023 13.4335 34.8572 13.3703 35.232C13.3072 35.6067 12.9519 35.8593 12.5769 35.7962C11.4292 35.6031 10.3094 35.2712 9.24205 34.8077L9.23383 34.8041C8.73308 34.5789 8.341 34.166 8.14211 33.6546C7.94327 33.1433 7.95344 32.5744 8.17041 32.0706C8.17048 32.0704 8.17034 32.0707 8.17041 32.0706L9.4574 29.0685C9.64549 28.6301 9.74256 28.1579 9.74256 27.6809V25.7389C9.74256 23.5096 10.6289 21.3716 12.2065 19.7953Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8994 33.6682C15.2342 33.0772 16.6832 32.7684 18.1545 32.7684C19.6238 32.7684 21.0708 33.0763 22.4041 33.6658L22.4215 33.6725C22.4451 33.6819 22.4679 33.6925 22.4898 33.7041C22.9315 33.9041 23.3603 34.1351 23.7731 34.3962C25.4538 35.4596 26.7978 36.978 27.6486 38.7748C27.8112 39.1183 27.6644 39.5285 27.3206 39.691C26.9768 39.8536 26.5662 39.7068 26.4036 39.3633C25.6644 37.8022 24.4966 36.4829 23.0363 35.559C22.8697 35.4536 22.7001 35.3539 22.5278 35.2599L20.9009 39.3246C20.7597 39.6774 20.3589 39.8491 20.0058 39.7079C19.6527 39.5668 19.4809 39.1664 19.6221 38.8135L21.2714 34.6929C20.277 34.3319 19.2225 34.1446 18.1545 34.1446C17.0868 34.1446 16.0327 34.3317 15.0385 34.6926L16.6879 38.8135C16.8291 39.1664 16.6574 39.5668 16.3042 39.7079C15.9511 39.8491 15.5503 39.6774 15.4091 39.3246L13.782 35.2594C13.6094 35.3536 13.4396 35.4534 13.2727 35.559C11.8124 36.4829 10.6446 37.8022 9.90544 39.3633C9.74279 39.7068 9.33222 39.8536 8.98842 39.691C8.64462 39.5285 8.49778 39.1183 8.66043 38.7748C9.5112 36.978 10.8552 35.4596 12.5359 34.3962C12.9509 34.1337 13.3822 33.9016 13.8264 33.7008C13.8464 33.6905 13.8672 33.681 13.8885 33.6725C13.8921 33.671 13.8958 33.6696 13.8994 33.6682Z'
        fill={primaryColor}
      />
      <path
        d='M18.155 22.231C18.155 22.231 16.8134 25.5986 13.4449 25.7342C13.225 26.4675 13.1797 27.242 13.3125 27.9959C13.4454 28.7498 13.7528 29.4622 14.2102 30.0763C14.6676 30.6903 15.2623 31.1891 15.9469 31.5327C16.6315 31.8763 17.387 32.0552 18.1532 32.0552C18.9193 32.0552 19.6748 31.8763 20.3594 31.5327C21.044 31.1891 21.6387 30.6903 22.0961 30.0763C22.5535 29.4622 22.8609 28.7498 22.9938 27.9959C23.1267 27.242 23.0813 26.4675 22.8614 25.7342C19.4976 25.5958 18.155 22.231 18.155 22.231Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.8816 23.4773C18.3816 22.7987 18.155 22.231 18.155 22.231C18.155 22.231 17.9288 22.7988 17.4289 23.4775C16.7115 24.4517 15.4302 25.6543 13.4449 25.7342C13.225 26.4675 13.1797 27.242 13.3125 27.9959C13.4454 28.7498 13.7528 29.4622 14.2102 30.0763C14.6676 30.6903 15.2623 31.1891 15.9469 31.5327C16.6315 31.8763 17.387 32.0552 18.1532 32.0552C18.9193 32.0552 19.6748 31.8763 20.3594 31.5327C21.044 31.1891 21.6387 30.6903 22.0961 30.0763C22.5535 29.4622 22.8609 28.7498 22.9938 27.9959C23.1267 27.242 23.0813 26.4675 22.8614 25.7342C20.8793 25.6526 19.599 24.4508 18.8816 23.4773ZM18.1554 24.5221C18.0979 24.5923 18.0379 24.663 17.9755 24.7339C17.2247 25.5863 16.072 26.5069 14.4666 26.8314C14.4396 27.1488 14.4537 27.4694 14.5094 27.7853C14.6094 28.3528 14.8408 28.889 15.1851 29.3513C15.5294 29.8135 15.9771 30.189 16.4924 30.4476C17.0078 30.7063 17.5765 30.841 18.1532 30.841C18.7299 30.841 19.2986 30.7063 19.8139 30.4476C20.3292 30.189 20.7769 29.8135 21.1212 29.3513C21.4655 28.889 21.6969 28.3528 21.7969 27.7853C21.8527 27.4692 21.8667 27.1482 21.8397 26.8306C20.2362 26.505 19.0846 25.5846 18.3345 24.7329C18.2723 24.6623 18.2127 24.592 18.1554 24.5221Z'
        fill={primaryColor}
      />
    </svg>
  );
}
