import { ReactElement } from 'react';

export function FourOhFourIcon(): ReactElement {
  return (
    <svg fill='none' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path d='m44 24c0 11.0457-8.9543 20-20 20s-20-8.9543-20-20 8.9543-20 20-20 20 8.9543 20 20z' fill='#fff' />
      <path
        clipRule='evenodd'
        d='m24 43c10.4934 0 19-8.5066 19-19s-8.5066-19-19-19-19 8.5066-19 19 8.5066 19 19 19zm0 1c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20z'
        fill='#f6f6f6'
        fillRule='evenodd'
      />
      <path
        d='m36.5 41.75h-25l10.89-36.30336c.1043-.34582.3172-.64886.6072-.86426s.6416-.33171 1.0028-.33171.7128.11631 1.0028.33171.5029.51844.6072.86426z'
        fill='#ff7a00'
      />
      <path d='m19 28h11v5h-11z' fill='#de7f30' />
      <path d='m18 17h12v5h-12z' fill='#fff' />
      <path
        clipRule='evenodd'
        d='m17.75 16.75c0-.6904.5596-1.25 1.25-1.25h10c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-10c-.6904 0-1.25-.5596-1.25-1.25z'
        fill='#ffb979'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='m16.25 21.75c0-.6904.5596-1.25 1.25-1.25h13c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-13c-.6904 0-1.25-.5596-1.25-1.25z'
        fill='#ffb979'
        fillRule='evenodd'
      />
      <path d='m15 29h18v6h-18z' fill='#fff' />
      <g clipRule='evenodd' fillRule='evenodd'>
        <path
          d='m14 29.25c0-.6904.5596-1.25 1.25-1.25h17.5c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-17.5c-.6904 0-1.25-.5596-1.25-1.25z'
          fill='#ffb979'
        />
        <path
          d='m12.5 34.25c0-.6904.5596-1.25 1.25-1.25h20.5c.6904 0 1.25.5596 1.25 1.25s-.5596 1.25-1.25 1.25h-20.5c-.6904 0-1.25-.5596-1.25-1.25z'
          fill='#ffb979'
        />
        <path
          d='m23.7426 5.58582c.0744-.05529.1647-.08515.2574-.08515s.183.02986.2574.08515c.0743.05514.129.13336.1559.22184l10.4067 34.69234h-21.64l10.4069-34.69302c.0269-.08849.0815-.16602.1557-.22116zm.2574-2.58515c-.6297 0-1.2427.20276-1.7482.57827s-.8766.9038-1.0585 1.50668l-10.6234 35.41438h-2.8199c-.69036 0-1.25.5596-1.25 1.25s.55964 1.25 1.25 1.25h32.5c.6904 0 1.25-.5596 1.25-1.25s-.5596-1.25-1.25-1.25h-2.8199l-10.6228-35.41252-.0006-.00186c-.1818-.60288-.553-1.13117-1.0585-1.50668s-1.1185-.57827-1.7482-.57827z'
          fill='#333'
        />
      </g>
    </svg>
  );
}
