import styled from 'styled-components';

export const WidgetContainer = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.p`
  margin-left: 5px;
  font-size: 14px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${(props) => props.theme.colors.grey[70]};
`;
