import { ReactNode, ReactElement } from 'react';

import { HeaderProgressBar } from './HeaderProgressBar';
import { InfoViewContainer } from './TransactionFlowStyles';

type Props = {
  infoView?: ReactNode;
  collapseHeader?: boolean;
  contentUnderTitle?: ReactElement;
  progress?: number;
};

export function TransactionFlowInfoView({ collapseHeader = false, ...props }: Props): ReactElement {
  return props.contentUnderTitle ? (
    <InfoViewContainer>{props.contentUnderTitle}</InfoViewContainer>
  ) : (
    <InfoViewContainer>
      {props.infoView}
      {!collapseHeader && props.progress && <HeaderProgressBar progress={props.progress} />}
    </InfoViewContainer>
  );
}
