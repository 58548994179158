import { ReactElement, useCallback, useState } from 'react';
import { NumberFormatValues, PatternFormat } from 'react-number-format';

import { TextFieldProps } from '@breathelife/mui';

import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { TextFieldMUI } from './TextInput';

type Props = TextFieldProps &
  Omit<FieldProps, 'onAnswerChange'> & {
    value?: string;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (fieldId: string, answer: string | number, previousAnswer?: string | number | undefined) => void;
    inputVariant?: InputVariant;
    allowTextWrap?: boolean;
    saveOnBlur?: boolean;
  };

export function PhoneInput(props: Props): ReactElement {
  const {
    allowTextWrap,
    disabled,
    id,
    inputVariant,
    label,
    name,
    onAnswerChange,
    onAnswerComplete,
    onBlur,
    optionalText,
    placeholder,
    required,
    saveOnBlur,
    subtitle,
    title,
    validationError,
    value,
  } = props;

  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  const [realValue, setRealValue] = useState(value);
  const [formattedValue, setFormattedValue] = useState('');

  const onValueChangeCallback = useCallback(
    (newValue: NumberFormatValues) => {
      if (!saveOnBlur) {
        newValue.value !== value && onAnswerChange && onAnswerChange(newValue.formattedValue);
      }
      setRealValue(newValue.value);
      setFormattedValue(newValue.formattedValue);
    },
    [onAnswerChange, saveOnBlur, value],
  );

  const onBlurCallback = useCallback(
    (event: any) => {
      if (!saveOnBlur && onBlur) {
        onBlur(event);
        return;
      }
      realValue !== value && onAnswerChange && onAnswerChange(formattedValue);
    },
    [formattedValue, onAnswerChange, onBlur, realValue, saveOnBlur, value],
  );

  return (
    <PatternFormat
      id={id}
      data-testid={props['data-testid']}
      name={name}
      type='tel'
      format='1-###-###-####'
      mask='_'
      customInput={TextFieldMUI}
      getInputRef={null}
      displayType='input'
      placeholder={placeholder || '###-###-####'}
      value={realValue as string}
      onValueChange={onValueChangeCallback}
      onBlur={onBlurCallback}
      label={labelValue}
      disabled={disabled}
      validationError={validationError}
      onAnswerComplete={onAnswerComplete}
      inputVariant={inputVariant}
      subtitle={subtitle}
      allowTextWrap={allowTextWrap}
      required={required}
    />
  );
}
