import styled from 'styled-components';

import { HeaderActionButton } from '../Button/HeaderActionButton';
import { Image } from '../Images/Image';

export const AssigneeContactPrompt = styled.span`
  display: none;
  margin-left: 8px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grey[80]} !important;

  font-size: ${(props) => props.theme.fonts.size.regular};
  font-weight: bold;
  letter-spacing: 1.4px;
  margin-left: 8px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    display: block;
  }
`;

export const ApplicationAssigneeImage = styled(Image)`
  margin-right: 12px;
`;

export const IconContainer = styled.div`
  svg {
    fill: ${(props) => props.theme.colors.grey[80]};
  }
`;

export const AssigneeContactButton = styled(HeaderActionButton)`
  &:hover,
  &:focus,
  &:active {
    ${AssigneeContactPrompt}, ${IconContainer} {
      color: ${(props) => props.theme.colors.grey[90]};
      svg {
        fill: ${(props) => props.theme.colors.grey[90]};
      }
    }
  }
`;

export const ActionButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
