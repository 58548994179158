import { baseCreateMuiTheme, Theme } from '@breathelife/mui';

import { fallbackFontFamilies } from './Base';
import { SharedTheme } from './Types';

export function createBaseMuiTheme(theme: SharedTheme): Theme {
  return baseCreateMuiTheme({
    breakpoints: {
      // Approximate to our internal breakpoints
      values: {
        xs: 0,
        sm: 300,
        md: 768,
        lg: 1024,
        xl: 1600,
      },
    },
    palette: {
      primary: { main: theme.colors.primary.default ?? '#D0021B' },
      secondary: { main: theme.colors.base.secondary ?? '#002846' },
    },
    typography: {
      fontSize: 16,
      fontFamily: fallbackFontFamilies,
      button: {
        fontSize: 13,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // Disable ripple animation on click
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthSm: {
            width: '540px',
            maxWidth: '540px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            paddingBottom: '15px',
            paddingTop: '15px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid #ebebeb',
            borderTop: '1px solid #ebebeb',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            margin: '5px 0 10px 0',
            textAlign: 'center',
          },
        },
      },
    },
  });
}
