import { ReactNode, HTMLProps, ReactElement } from 'react';
import styled from 'styled-components';

type TabsButtonProps = {
  children?: ReactNode;
  isSelected?: boolean;
} & HTMLProps<HTMLButtonElement>;

const DefaultTabsButton = styled.button.attrs({
  type: 'button',
  role: 'button',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.33px;
  padding: 3px;

  font-size: 16px;
  color: ${(props) => props.theme.colors.grey[70]};
  border: 1px solid ${(props) => props.theme.colors.grey[10]};
  background-color: ${(props) => props.theme.colors.grey.lighter};
`;

const SelectedTabsButton = styled(DefaultTabsButton)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  color: ${(props) => props.theme.colors.grey[100]};
  border-color: ${(props) => props.theme.colors.primary.default};

  /* box-shadow is used here to not interfere with the text's vertical alignment*/
  box-shadow: inset 0px -4px 0px ${(props) => props.theme.colors.primary.default};
`;

export function TabsButton({ onClick, children, isSelected = false }: TabsButtonProps): ReactElement {
  const Component = isSelected ? SelectedTabsButton : DefaultTabsButton;

  return <Component onClick={onClick}>{children}</Component>;
}

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  min-height: 50px;
`;
