import { ReactElement } from 'react';

export function BulletEx(): ReactElement {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect y='2' width='12' height='12' rx='2' fill='#D31919' />
      <path
        transform='translate(-4 0)'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1315 7.99998L13.7659 5.36556C14.0787 5.05276 14.0787 4.54716 13.7659 4.23436C13.4531 3.92156 12.9475 3.92156 12.6347 4.23436L10.0002 6.86877L7.36582 4.23436C7.05301 3.92156 6.54741 3.92156 6.2346 4.23436C5.9218 4.54716 5.9218 5.05276 6.2346 5.36556L8.86904 7.99998L6.2346 10.6344C5.9218 10.9472 5.9218 11.4528 6.2346 11.7656C6.3906 11.9216 6.59541 12 6.80021 12C7.00501 12 7.20982 11.9216 7.36582 11.7656L10.0002 9.13119L12.6347 11.7656C12.7907 11.9216 12.9955 12 13.2003 12C13.4051 12 13.6099 11.9216 13.7659 11.7656C14.0787 11.4528 14.0787 10.9472 13.7659 10.6344L11.1315 7.99998Z'
        fill='white'
      />
    </svg>
  );
}
