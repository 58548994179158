import { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

const Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey[90]};
  letter-spacing: 0.2px;
  margin: 0 0 4px;
`;

const Value = styled.span`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.grey[100]};
`;

const Suffix = styled.span<{ align?: string }>`
  color: ${(props) => props.theme.colors.grey[80]};
  margin-left: 16px;
  display: inline-flex;
  align-self: ${(props) => props.align || 'inherit'};
  button {
    height: 16px !important;
    width: 16px !important;
  }
  svg {
    height: 10px !important;
  }
`;

const Prefix = styled.span`
  color: ${(props) => props.theme.colors.grey[80]};
  margin-right: 16px;
`;

const ContentAlign = styled.div`
  display: flex;
  align-items: baseline;
`;

type Props = {
  value: ReactNode;
  title?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  suffixAlign?: string;
};

export function Statistic({ value, title, prefix, suffix, suffixAlign }: Props): ReactElement {
  return (
    <div>
      {title && <Title>{title}</Title>}

      <ContentAlign>
        {prefix && <Prefix>{prefix}</Prefix>}
        <Value>{value}</Value>
        {suffix && <Suffix align={suffixAlign}>{suffix}</Suffix>}
      </ContentAlign>
    </div>
  );
}
