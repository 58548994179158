import { ReactNode, ReactElement } from 'react';

import { FormControlFrame } from './Styles';

export type FormControlBoxProps = {
  checked?: boolean;
  disabled?: boolean;
  focused?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  role?: string;
  tabIndex?: number;
};

export function FormControlBox(props: FormControlBoxProps): ReactElement {
  return (
    <FormControlFrame
      className={props.className}
      checked={props.checked}
      focused={props.focused}
      disabled={props.disabled}
      onClick={props.onClick}
      role={props.role}
      tabIndex={props.tabIndex}
    >
      {props.children}
    </FormControlFrame>
  );
}
