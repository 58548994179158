export const baseTranslations = {
  en: {
    continueButtonText: {
      educationFund: 'Continue with recommendation',
      iConfirm: 'I confirm',
      iAgree: 'I agree',
      iUnderstand: 'I understand',
      submitApplication: 'Submit my application',
    },
    errorBoundary: {
      title: 'Oops, something went wrong!',
      subtitle: `We have been notified and are looking into the issue. Please refresh your browser or wait a few minutes.`,
    },
    home: {
      advisorTitle: 'Insurance advisor',
    },
    confirm: 'Confirm',
  },
  fr: {
    continueButtonText: {
      educationFund: 'Continuer avec la recommandation',
      iConfirm: 'Je confirme',
      iAgree: 'Je consens',
      iUnderstand: 'Je comprends',
      submitApplication: 'Soumettre mon application',
    },
    errorBoundary: {
      title: 'Oups, un problème est survenu!',
      subtitle: `Nous avons été informés et étudions le problème. Veuillez actualiser votre navigateur ou patienter quelques minutes.`,
    },
    home: {
      advisorTitle: 'Conseiller en assurance',
    },
    confirm: 'Confirmer',
  },
};
