import styled from 'styled-components';

export const AdvisorName = styled.p`
  font-weight: ${(props) => props.theme.fonts.weight.semibold};
  font-family: ${(props) => props.theme.fonts.family.condensed} !important;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary[70]};
  margin: 0;
`;

export const AdvisorTitle = styled.p`
  font-weight: normal;
  font-family: ${(props) => props.theme.fonts.family.condensed} !important;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.grey[90]};
`;
