// FIXME: This file is temporary until all frontend are merge into frontend/core. This localizer should be moved inside frontend core closer to where it is used.
// It currently lives in ui-components just for the sake of avoiding creating a temporary package or to avoid dupplicating these function in every carrier's frontend.
import I18n, { TranslateOptions, ToCurrencyOptions } from 'i18n-js';
import queryString from 'query-string';
import _ from 'lodash';

import { Language } from '@breathelife/types';

import { baseTranslations } from './dictionnary';

export const DEFAULT_LOCALE = 'en-CA';

export const getCurrentLocale = (): string => {
  const queryStringLanguage = queryString.parse(window.location.search).lang;
  const locale =
    Language[queryStringLanguage as keyof typeof Language] ||
    window.localStorage.getItem('locale') ||
    // @ts-ignore was used in ie11. Could be removed in near future
    window.navigator.userLanguage ||
    window.navigator.language ||
    DEFAULT_LOCALE;

  return locale.split('-')[0];
};

let currentLocale = getCurrentLocale();

export function setLocale(locale: string = getCurrentLocale()): void {
  window.localStorage.setItem('locale', locale);
  currentLocale = locale as Language;
  I18n.locale = locale;
}

setLocale(currentLocale);

export const shortLocale = (): Language => (currentLocale.indexOf(Language.fr) !== -1 ? Language.fr : Language.en);

export function text(value: string, parameters?: TranslateOptions): string {
  return I18n.t(value, parameters);
}

// Returns `undefined` if a text isn't defined instead of using I18n's fallback system
export function textIfExists(value: string, parameters?: TranslateOptions): string | undefined {
  return I18n.t(value, { ...parameters, defaults: [{ message: '' }] }) || undefined;
}

export function setTranslations(carrierTranslations: Partial<Record<Language, unknown>>): void {
  I18n.translations = {
    en: _.merge({}, baseTranslations.en, carrierTranslations.en),
    fr: _.merge({}, baseTranslations.fr, carrierTranslations.fr),
  };
}

export function toCurrency(locale: Language, value: number | null, options: ToCurrencyOptions = {}): string {
  if (value == null) {
    return 'N/A';
  }

  let currencyFormat = '%u%n';
  let delimiter = ',';
  if (locale === Language.fr) {
    currencyFormat = '%n %u';
    delimiter = ' ';
  }

  return I18n.toCurrency(value, _.merge(options, { delimiter, format: currencyFormat }));
}
