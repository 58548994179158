import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function LoadingCalculatorIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg
      id='eijwgrzoqzum1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 48 48'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
    >
      <style>{`
  #eijwgrzoqzum7 {
    animation: eijwgrzoqzum7_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum7_f_o {
    0% {
      fill-opacity: 1;
    }
    71.428571% {
      fill-opacity: 1;
    }
    85.714286% {
      fill-opacity: 0;
    }
    100% {
      fill-opacity: 1;
    }
  }
  #eijwgrzoqzum8 {
    animation: eijwgrzoqzum8_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum8_f_o {
    0% {
      fill-opacity: 1;
    }
    57.142857% {
      fill-opacity: 1;
    }
    71.428571% {
      fill-opacity: 0;
    }
    85.714286% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: 1;
    }
  }
  #eijwgrzoqzum9 {
    animation: eijwgrzoqzum9_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum9_f_o {
    0% {
      fill-opacity: 1;
    }
    42.857143% {
      fill-opacity: 1;
    }
    57.142857% {
      fill-opacity: 0;
    }
    71.428571% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: 1;
    }
  }
  #eijwgrzoqzum10 {
    animation: eijwgrzoqzum10_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum10_f_o {
    0% {
      fill-opacity: 1;
    }
    28.571429% {
      fill-opacity: 1;
    }
    42.857143% {
      fill-opacity: 0;
    }
    57.142857% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: 1;
    }
  }
  #eijwgrzoqzum11 {
    animation: eijwgrzoqzum11_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum11_f_o {
    0% {
      fill-opacity: 1;
    }
    14.285714% {
      fill-opacity: 1;
    }
    28.571429% {
      fill-opacity: 0;
    }
    42.857143% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: 1;
    }
  }
  #eijwgrzoqzum12 {
    animation: eijwgrzoqzum12_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum12_f_o {
    0% {
      fill-opacity: 1;
    }
    14.285714% {
      fill-opacity: 0;
    }
    28.571429% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: 1;
    }
  }
  #eijwgrzoqzum13 {
    animation: eijwgrzoqzum13_f_o 7000ms linear infinite normal forwards;
  }
  @keyframes eijwgrzoqzum13_f_o {
    0% {
      fill-opacity: 0;
    }
    14.285714% {
      fill-opacity: 1;
    }
    85.714286% {
      fill-opacity: 1;
    }
    100% {
      fill-opacity: 0.090909;
    }
  }`}</style>
      <circle
        id='eijwgrzoqzum2'
        r='19.500000'
        transform='matrix(1 0 0 1 24 24)'
        fill='rgb(255,255,255)'
        stroke='rgb(246,246,246)'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum3'
        d='M28.923100,6L9.192310,6C7.981530,6,7,6.981530,7,8.192310L7,36.692300C7,37.903100,7.981530,38.884600,9.192310,38.884600L28.923100,38.884600C30.133900,38.884600,31.115400,37.903100,31.115400,36.692300L31.115400,8.192310C31.115400,6.981530,30.133900,6,28.923100,6Z'
        fill='rgb(255,255,255)'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum4'
        d='M9.192330,6.850000C8.451000,6.850000,7.850020,7.450970,7.850020,8.192310L7.850020,36.692300C7.850020,37.433600,8.451000,38.034600,9.192330,38.034600L28.923100,38.034600C29.664400,38.034600,30.265400,37.433600,30.265400,36.692300L30.265400,8.192310C30.265400,7.450970,29.664400,6.850000,28.923100,6.850000L9.192330,6.850000ZM6.150020,8.192310C6.150020,6.512090,7.512110,5.150000,9.192330,5.150000L28.923100,5.150000C30.603300,5.150000,31.965400,6.512090,31.965400,8.192310L31.965400,36.692300C31.965400,38.372500,30.603300,39.734600,28.923100,39.734600L9.192330,39.734600C7.512110,39.734600,6.150020,38.372500,6.150020,36.692300L6.150020,8.192310Z'
        clipRule='evenodd'
        fill={primaryColor}
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum5'
        d='M25.634600,10.384600L12.480800,10.384600C11.875400,10.384600,11.384600,10.875400,11.384600,11.480800L11.384600,15.865400C11.384600,16.470800,11.875400,16.961500,12.480800,16.961500L25.634600,16.961500C26.240000,16.961500,26.730800,16.470800,26.730800,15.865400L26.730800,11.480800C26.730800,10.875400,26.240000,10.384600,25.634600,10.384600Z'
        fill={secondaryColor}
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum6'
        d='M12.480700,11.184600C12.317200,11.184600,12.184600,11.317200,12.184600,11.480800L12.184600,15.865400C12.184600,16.028900,12.317200,16.161500,12.480700,16.161500L25.634600,16.161500C25.798200,16.161500,25.930700,16.028900,25.930700,15.865400L25.930700,11.480800C25.930700,11.317200,25.798200,11.184600,25.634600,11.184600L12.480700,11.184600ZM10.584600,11.480800C10.584600,10.433600,11.433500,9.584620,12.480700,9.584620L25.634600,9.584620C26.681800,9.584620,27.530700,10.433600,27.530700,11.480800L27.530700,15.865400C27.530700,16.912600,26.681800,17.761500,25.634600,17.761500L12.480700,17.761500C11.433500,17.761500,10.584600,16.912600,10.584600,15.865400L10.584600,11.480800Z'
        clipRule='evenodd'
        fill={primaryColor}
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum7'
        d='M19,31C18.602200,31,18.220600,31.158000,17.939300,31.439300C17.658000,31.720600,17.500000,32.102200,17.500000,32.500000C17.500000,32.796700,17.588000,33.086700,17.752800,33.333400C17.917600,33.580000,18.151900,33.772300,18.426000,33.885800C18.700100,33.999400,19.001700,34.029100,19.292600,33.971200C19.583600,33.913300,19.850900,33.770400,20.060700,33.560700C20.270400,33.350900,20.413300,33.083600,20.471200,32.792600C20.529100,32.501700,20.499400,32.200100,20.385800,31.926000C20.272300,31.651900,20.080000,31.417600,19.833400,31.252800C19.586700,31.088000,19.296700,31,19,31Z'
        clipRule='evenodd'
        fill={primaryColor}
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum8'
        d='M13,31C13.296700,31,13.586700,31.088000,13.833400,31.252800C14.080000,31.417600,14.272300,31.651900,14.385800,31.926000C14.499400,32.200100,14.529100,32.501700,14.471200,32.792600C14.413300,33.083600,14.270400,33.350900,14.060700,33.560700C13.850900,33.770400,13.583600,33.913300,13.292600,33.971200C13.001700,34.029100,12.700100,33.999400,12.426000,33.885800C12.151900,33.772300,11.917600,33.580000,11.752800,33.333400C11.588000,33.086700,11.500000,32.796700,11.500000,32.500000C11.500000,32.102200,11.658000,31.720600,11.939300,31.439300C12.220600,31.158000,12.602200,31,13,31Z'
        fill={primaryColor}
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum9'
        d='M19,26C18.602200,26,18.220600,26.158000,17.939300,26.439300C17.658000,26.720600,17.500000,27.102200,17.500000,27.500000C17.500000,27.796700,17.588000,28.086700,17.752800,28.333400C17.917600,28.580000,18.151900,28.772300,18.426000,28.885800C18.700100,28.999400,19.001700,29.029100,19.292600,28.971200C19.583600,28.913300,19.850900,28.770400,20.060700,28.560700C20.270400,28.350900,20.413300,28.083600,20.471200,27.792600C20.529100,27.501700,20.499400,27.200100,20.385800,26.926000C20.272300,26.651900,20.080000,26.417600,19.833400,26.252800C19.586700,26.088000,19.296700,26,19,26ZM19,27.500000L19,27.500000L19,27.500000L19,27.500000L19,27.500000Z'
        clipRule='evenodd'
        fill={primaryColor}
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum10'
        d='M13,26C13.296700,26,13.586700,26.088000,13.833400,26.252800C14.080000,26.417600,14.272300,26.651900,14.385800,26.926000C14.499400,27.200100,14.529100,27.501700,14.471200,27.792600C14.413300,28.083600,14.270400,28.350900,14.060700,28.560700C13.850900,28.770400,13.583600,28.913300,13.292600,28.971200C13.001700,29.029100,12.700100,28.999400,12.426000,28.885800C12.151900,28.772300,11.917600,28.580000,11.752800,28.333400C11.588000,28.086700,11.500000,27.796700,11.500000,27.500000C11.500000,27.102200,11.658000,26.720600,11.939300,26.439300C12.220600,26.158000,12.602200,26,13,26Z'
        fill={primaryColor}
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum11'
        d='M25,21C25.296700,21,25.586700,21.088000,25.833400,21.252800C26.080000,21.417600,26.272300,21.651900,26.385800,21.926000C26.499400,22.200100,26.529100,22.501700,26.471200,22.792600C26.413300,23.083600,26.270400,23.350900,26.060700,23.560700C25.850900,23.770400,25.583600,23.913300,25.292600,23.971200C25.001700,24.029100,24.700100,23.999400,24.426000,23.885800C24.151900,23.772300,23.917600,23.580000,23.752800,23.333400C23.588000,23.086700,23.500000,22.796700,23.500000,22.500000C23.500000,22.102200,23.658000,21.720600,23.939300,21.439300C24.220600,21.158000,24.602200,21,25,21Z'
        fill={primaryColor}
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum12'
        d='M19,21C18.602200,21,18.220600,21.158000,17.939300,21.439300C17.658000,21.720600,17.500000,22.102200,17.500000,22.500000C17.500000,22.796700,17.588000,23.086700,17.752800,23.333400C17.917600,23.580000,18.151900,23.772300,18.426000,23.885800C18.700100,23.999400,19.001700,24.029100,19.292600,23.971200C19.583600,23.913300,19.850900,23.770400,20.060700,23.560700C20.270400,23.350900,20.413300,23.083600,20.471200,22.792600C20.529100,22.501700,20.499400,22.200100,20.385800,21.926000C20.272300,21.651900,20.080000,21.417600,19.833400,21.252800C19.586700,21.088000,19.296700,21,19,21Z'
        clipRule='evenodd'
        fill={primaryColor}
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum13'
        d='M13,21C13.296700,21,13.586700,21.088000,13.833400,21.252800C14.080000,21.417600,14.272300,21.651900,14.385800,21.926000C14.499400,22.200100,14.529100,22.501700,14.471200,22.792600C14.413300,23.083600,14.270400,23.350900,14.060700,23.560700C13.850900,23.770400,13.583600,23.913300,13.292600,23.971200C13.001700,24.029100,12.700100,23.999400,12.426000,23.885800C12.151900,23.772300,11.917600,23.580000,11.752800,23.333400C11.588000,23.086700,11.500000,22.796700,11.500000,22.500000C11.500000,22.102200,11.658000,21.720600,11.939300,21.439300C12.220600,21.158000,12.602200,21,13,21Z'
        fill={primaryColor}
        fillOpacity='0'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum14'
        d='M31.480800,42.173100C36.323900,42.173100,40.250000,38.247000,40.250000,33.403900C40.250000,28.560700,36.323900,24.634600,31.480800,24.634600C26.637700,24.634600,22.711500,28.560700,22.711500,33.403900C22.711500,38.247000,26.637700,42.173100,31.480800,42.173100Z'
        fill={secondaryColor}
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum15'
        d='M31.480800,25.484600C27.107100,25.484600,23.561600,29.030200,23.561600,33.403900C23.561600,37.777500,27.107100,41.323100,31.480800,41.323100C35.854500,41.323100,39.400000,37.777500,39.400000,33.403900C39.400000,29.030200,35.854500,25.484600,31.480800,25.484600ZM21.861600,33.403900C21.861600,28.091300,26.168200,23.784600,31.480800,23.784600C36.793400,23.784600,41.100000,28.091300,41.100000,33.403900C41.100000,38.716400,36.793400,43.023100,31.480800,43.023100C26.168200,43.023100,21.861600,38.716400,21.861600,33.403900Z'
        clipRule='evenodd'
        fill={primaryColor}
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      />
      <path
        id='eijwgrzoqzum16'
        d='M31.480800,27.123100C31.922600,27.123100,32.280800,27.481300,32.280800,27.923100L32.280800,28.219200L33.673100,28.219200C34.114900,28.219200,34.473100,28.577400,34.473100,29.019200C34.473100,29.461100,34.114900,29.819200,33.673100,29.819200L31.484400,29.819200L31.480800,29.819200L31.477100,29.819200L30.702100,29.819200C30.432600,29.819500,30.171500,29.913500,29.963600,30.085100C29.755700,30.256800,29.614000,30.495500,29.562900,30.760200C29.511700,31.025000,29.554300,31.299300,29.683200,31.536100C29.812100,31.772700,30.019200,31.957200,30.269100,32.057800L33.286300,33.265300C33.881000,33.504600,34.374000,33.943400,34.680600,34.506400C34.987200,35.069500,35.088400,35.721600,34.966700,36.351100C34.845100,36.980500,34.508200,37.548100,34.013800,37.956300C33.525500,38.359600,32.913600,38.582600,32.280800,38.588400L32.280800,38.884600C32.280800,39.326400,31.922600,39.684600,31.480800,39.684600C31.039000,39.684600,30.680800,39.326400,30.680800,38.884600L30.680800,38.588500L29.288500,38.588500C28.846700,38.588500,28.488500,38.230300,28.488500,37.788500C28.488500,37.346600,28.846700,36.988500,29.288500,36.988500L31.478000,36.988500C31.478900,36.988500,31.479800,36.988500,31.480800,36.988500C31.481700,36.988500,31.482600,36.988500,31.483600,36.988500L32.256300,36.988500C32.525900,36.988300,32.787100,36.894300,32.995000,36.722600C33.202900,36.550900,33.344600,36.312200,33.395800,36.047500C33.446900,35.782700,33.404400,35.508400,33.275400,35.271600C33.146600,35.035100,32.939600,34.850600,32.689700,34.749900L29.673800,33.543000L29.672400,33.542400C29.077600,33.303100,28.584700,32.864300,28.278100,32.301300C27.971400,31.738200,27.870300,31.086100,27.992000,30.456600C28.113600,29.827200,28.450500,29.259600,28.944800,28.851400C29.433800,28.447700,30.047100,28.224600,30.680800,28.219300L30.680800,27.923100C30.680800,27.481300,31.039000,27.123100,31.480800,27.123100Z'
        clipRule='evenodd'
        fill={primaryColor}
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      />
    </svg>
  );
}
