import styled from 'styled-components';

export const Container = styled.div<{ $isAgentSession: boolean }>`
  display: flex;
  flex-direction: row;
  overflow: unset;
  height: unset;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    overflow: hidden;
    height: ${(props) => (props.$isAgentSession ? 'calc(100vh - 5em)' : '100vh')};
  }
`;

export const LeftPanelContainer = styled.aside`
  width: 80%;
  max-width: 600px;
  min-width: 300px;
  position: relative;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.maxLeftPanelWidth}) {
    display: none;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    display: initial;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-y: unset;
  position: unset;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

export const HeaderContainer = styled.header`
  flex: 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
`;

export const MainContainer = styled.main<{ $isAgentSession: boolean }>`
  flex: 1;
  padding: ${(props) => (props.$isAgentSession ? '94px 0 8em' : '94px 0 3em')};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
