import styled from 'styled-components';

export const TextButton = styled.button.attrs({
  type: 'button',
  role: 'button',
})`
  background: none;
  border: none;
  padding: 0;
  color: ${(props) => props.theme.colors.base.primary};
`;
