import { ReactElement } from 'react';

import { ButtonProps as MuiButtonProps } from '@breathelife/mui';

import { Loader } from '../Loader/Loader';
import { StyledButton } from './Styles';

export type ButtonProps = MuiButtonProps & {
  ['data-testid']?: string;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
  loading?: boolean;
};

export function Button({ loading, ...props }: ButtonProps): ReactElement {
  return (
    <StyledButton {...props} disabled={props.disabled || loading} data-testid={props['data-testid'] || props.id}>
      {loading ? <Loader color='white' size='30px' /> : props.children}
    </StyledButton>
  );
}

Button.defaultProps = { loading: false };
