import { ReactNode, MouseEvent, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
`;

export function FocusControl(props: { children: ReactNode }): ReactElement {
  function onMouseUp(e: MouseEvent): void {
    const target = e.target as HTMLElement;
    if (!target) return;

    // The click target might be an element within a button (e.g. an icon),
    // so we need to handle this case.
    const closestButton = target.closest('button');
    if (!closestButton) return;

    closestButton.blur();
  }
  //eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <Container onMouseUp={onMouseUp}>{props.children}</Container>;
}
