import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function AverageFinancialHealth(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='40' height='40' fill='none' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20 .75a4.812 4.812 0 1 0 0 9.625A4.812 4.812 0 1 0 20 .75zM3.5 14.5h33a2.751 2.751 0 0 1 0 5.5H22.75v5.5h2.75a8.26 8.26 0 0 1 8.25 8.25v2.75a2.751 2.751 0 0 1-5.5 0v-2.75A2.751 2.751 0 0 0 25.5 31h-3.8l-1.845 3.69a8.21 8.21 0 0 1-7.372 4.56H9a2.751 2.751 0 0 1 0-5.5h3.483a2.735 2.735 0 0 0 2.459-1.52l2.308-4.629V20H3.5a2.751 2.751 0 0 1 0-5.5zm19.25 11h-5.5'
        stroke={props.color?.primary || theme.colors.icons.primary}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
