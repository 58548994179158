import { ReactElement, useState, useCallback } from 'react';

import { Box, Popover } from '@breathelife/mui';

import { Button, Dot, Content, Link } from './Styles';

export type LegalLink = { text: string; href?: string };

export function LegalMenu(props: { links: LegalLink[] }): ReactElement {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
    setPopoverIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setPopoverIsOpen(false);
  }, []);

  return (
    <Box>
      <Button aria-label='menu' onClick={handleClick}>
        <Dot />
        <Dot />
        <Dot />
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        open={popoverIsOpen}
        onClose={handleClose}
      >
        <Content>
          {props.links.map((link, index) => (
            <Link key={`${link.text}-${index}`} href={link.href} rel='noopener noreferrer' target='_blank'>
              {link.text}
            </Link>
          ))}
        </Content>
      </Popover>
    </Box>
  );
}
