import styled from 'styled-components';

import { Button } from '../Button/Button';

export const CtaButton = styled(Button)`
  &&&&& {
    width: 100%;
    text-transform: none;
    font-size: ${(props) => props.theme.fonts.size.button};
    font-weight: ${(props) => props.theme.fonts.weight.medium};
  }
`;

export const StyledInputWrapper = styled.div<{ displaySubmissionError?: boolean }>`
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => props.displaySubmissionError && `1px solid ${props.theme.colors.text.error}`};
  }
`;
