import {
  useExternalScriptUrl,
  ExternalUrlScript,
  useExternalHtmlScriptTags,
  ExternalHtmlScriptTag,
} from '@breathelife/react-hooks';

export function ExternalUrlScriptLoader({ script }: { script: ExternalUrlScript }): null {
  useExternalScriptUrl(script);
  return null;
}

export function ExternalScriptTagsLoader({ scripts }: { scripts: ExternalHtmlScriptTag[] }): null {
  useExternalHtmlScriptTags(scripts);
  return null;
}
