import styled from 'styled-components';

import { ChildrenWrapper as ChildrenWrapperBase, FlexWrapper } from './SharedStyles';

export const ChildrenWrapper = styled(ChildrenWrapperBase)`
  padding-bottom: 10px;
`;

export const Header = styled.header`
  z-index: 9;
  width: 100%;
  min-height: 80px;
  background-color: ${(props) => props.theme.colors.grey[0]};
  box-shadow: inset 0px -1px 0px #fafafa;
`;

export const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalLayout = styled.div`
  align-items: center;
  display: flex;
  padding: 24px 24px 16px;

  ${FlexWrapper}:last-child {
    display: flex;
    flex-direction: row;
  }
`;
