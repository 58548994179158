const greys = {
  default: '#c2c2c2',
  border: '#cccccc',
  hover: '#eeeeee',
  inputPressed: '#e2e2e2',
  light: '#efefef',
  lighter: '#fbfbfb',
  dark: '#999999',
  darker: '#666666',
};

const black = '#333333';
const white = '#ffffff';

const shadows = {
  default: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
  active: '1px 2px 2px 0 rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31)',
};

export const colors = {
  button: {
    white,
    hover: greys.lighter,
  },
  anchor: {
    focus: white,
  },
  label: {
    default: black,
  },
  select: {
    default: black,
    label: greys.dark,
  },
  border: {
    default: greys.border,
    focus: greys.dark,
    bottom: greys.light,
    light: greys.light,
  },
  background: {
    default: greys.lighter,
    hover: greys.hover,
    disabled: greys.lighter,
    active: greys.inputPressed,
  },
  shadows,
  paragraph: {
    default: greys.darker,
  },
};

export const fontSize = {
  label: {
    medium: '15px',
  },
  option: {
    title: '14px',
    description: '12px',
  },
};

export const animation = {
  transition: 'cubic-bezier(0.55, 0, 0.1, 1)',
  speed: {
    slow: '0.5s',
    medium: '0.25s',
    fast: '0.1s',
  },
};

export const fontWeight = {
  regular: 300,
};
