import styled, { css } from 'styled-components';

import { colors } from '../../Theme/Theme';
import { FormControlBoxProps } from './FormControlBox';

const checkedSelectionInput = css`
  background-color: ${(props) => props.theme.colors.selection.background.selected} !important;
  border-width: ${(props) => props.theme.metrics.borders.default} !important;
  border-color: ${(props) => props.theme.colors.selection.border.selected} !important;
  box-shadow: ${colors.shadows.active} !important;
`;

const focusedSelectionInput = css`
  border-width: ${(props) => props.theme.metrics.borders.focus} !important;
  border-color: ${(props) => props.theme.colors.selection.border.focus} !important;
  background-color: ${(props) => props.theme.colors.selection.background.focus};
`;

const disabledFrameStyle = css`
  background-color: ${colors.background.disabled};
  background-color: ${colors.background.disabled};
`;

export const FormControlFrame = styled.div<FormControlBoxProps>`
  padding: 0;
  border-radius: ${(props) => props.theme.metrics.button.borderRadius};
  border-style: solid;
  border-width: ${(props) => props.theme.metrics.borders.default};
  border-color: ${(props) => props.theme.colors.selection.border.default};
  background-color: ${(props) => props.theme.colors.selection.background.default};
  box-shadow: ${colors.shadows.default};
  color: ${(props) => props.theme.colors.selection.text.default};
  transition: all ${(props) => props.theme.animation.speed.fast + ' ' + props.theme.animation.transition};

  ${(props) => props.checked && checkedSelectionInput};
  ${(props) => props.focused && focusedSelectionInput};

  ${(props: FormControlBoxProps) =>
    !props.disabled &&
    css`
      &:hover {
        border-width: ${(props) => props.theme.metrics.borders.default};
        border-color: ${(props) => props.theme.colors.selection.border.hover};
        background-color: ${(props) => props.theme.colors.selection.background.hover};
      }
      &:active {
        border-width: ${(props) => props.theme.metrics.borders.default};
        border-color: ${(props) => props.theme.colors.selection.border.hover};
        background-color: ${(props) => props.theme.colors.selection.background.active};
      }
    `}

  ${(props: FormControlBoxProps) => props.disabled && disabledFrameStyle}
  &:disabled {
    ${disabledFrameStyle}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    max-width: ${(props) => props.theme.metrics.input.maxWidth};
  }
`;
