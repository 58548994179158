import { CloudinaryContext, Image as CloudinaryImg, Transformation } from 'cloudinary-react';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { isCloudinarySrc, retrieveCloudinaryPublicIdFromUrl } from '../../helpers/images';

export type CloudinaryProps = {
  cloudName: string;
  folder: string;
};

export type ImageProps = {
  src: string;
  height?: number;
  width?: number;
  cloudinary?: CloudinaryProps;
  className?: string;
  alt?: string;
  radius?: number;
  isProfile?: boolean;
};

type StyledImageProps = {
  $isProfile: boolean;
  $width: number;
  $height: number;
  $radius: number;
};

const BaseImgStyles = css<Partial<StyledImageProps>>`
  object-fit: ${(props) => (props.$isProfile ? 'cover' : 'contain')};
  width: ${(props) => (props.$width ? `${props.$width}px` : 'auto')};
  height: ${(props) => (props.$height ? `${props.$height}px` : 'auto')};
  border-radius: ${(props) => props.$radius ?? 50}%;
`;

const CloudinaryContainer = styled(CloudinaryContext)<Partial<StyledImageProps>>`
  line-height: 0;
  img {
    ${BaseImgStyles}
  }
`;

const StyledImg = styled.img<Partial<StyledImageProps>>`
  ${BaseImgStyles}
`;

const StyledImgContainer = styled.div`
  line-height: 0;
`;

export function Image(props: ImageProps): ReactElement {
  if (props.cloudinary?.folder && props.cloudinary?.cloudName && isCloudinarySrc(props.src)) {
    return <CloudinaryImage {...props} />;
  }

  return (
    // Wrapping in a div because CloudinaryImage also return an img tag in a div. This is so they return the same structure.
    <StyledImgContainer className={props.className}>
      <StyledImg
        src={props.src}
        alt={props.alt || ''}
        $width={props.width}
        $height={props.height}
        $radius={props.radius}
        $isProfile={props.isProfile}
      />
    </StyledImgContainer>
  );
}

type CloudinaryImageProps = ImageProps;

function CloudinaryImage(props: CloudinaryImageProps): ReactElement {
  const { cloudinary, src, alt, isProfile = true } = props;
  return (
    <CloudinaryContainer
      cloudName={cloudinary?.cloudName}
      className={props.className}
      $isProfile={isProfile}
      $width={props.width}
      $height={props.height}
      $radius={props.radius}
    >
      <CloudinaryImg publicId={retrieveCloudinaryPublicIdFromUrl(src, props.cloudinary?.folder ?? '')} alt={alt || ''}>
        <Transformation
          // TODO if width or height < DEFAULT_SIZE, default to DEFAULT_SIZE
          // This width and height is used for the source dimension when retrieving the size of the image
          width={getSize(props.width)}
          height={getSize(props.height)}
          crop={isProfile ? 'thumb' : undefined}
          gravity={isProfile ? 'face' : undefined}
          fetchFormat='auto'
          quality='auto:good'
        />
      </CloudinaryImg>
    </CloudinaryContainer>
  );
}

function getSize(size: number | undefined): string {
  const defaultSize = 150;
  if (size && size > defaultSize) return size.toString();
  return '150';
}
