import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { Aside } from '../Aside';
import { CloudinaryProps, Image, ImageProps } from '../Images/Image';
import { AdvisorName, AdvisorTitle } from './Styles';

type ImgSettings = Partial<Pick<ImageProps, 'width' | 'height' | 'radius'>>;

type Props = {
  alignment?: 'vertical' | 'horizontal';
  cloudinaryProps?: CloudinaryProps;
  imgSettings?: ImgSettings;
  advisorInfo: {
    name: string;
    title: string;
    imgUrl?: string;
  };
  textAlign?: 'left' | 'center';
};

export function AdvisorInfoPanel(props: Props): ReactElement {
  const alignment = props.alignment ?? 'horizontal';
  const Container = alignment === 'vertical' ? Box : Aside;
  return (
    <Container align='center'>
      {props.advisorInfo.imgUrl && (
        <Box mb={props.alignment === 'vertical' ? 2 : 0}>
          <Image
            src={props.advisorInfo.imgUrl}
            height={props.imgSettings?.height ?? 40}
            width={props.imgSettings?.width ?? 40}
            radius={props.imgSettings?.radius ?? 50}
            cloudinary={props.cloudinaryProps}
          />
        </Box>
      )}
      <Box textAlign={props.textAlign ?? 'left'}>
        <AdvisorName>{props.advisorInfo.name}</AdvisorName>
        <AdvisorTitle>{props.advisorInfo.title}</AdvisorTitle>
      </Box>
    </Container>
  );
}
