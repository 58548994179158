import styled from 'styled-components';

import { Input } from '../Fields/TextInput';

export const StyledAutocompleteTextField = styled(Input)`
  &&& .MuiInputBase-input {
    box-sizing: border-box; // Centers the input text in the autocomplete control.
  }
`;

export const StyledAutocompleteTextFieldMultiple = styled(Input)`
  &&& .MuiInputBase-input {
    box-sizing: border-box; // Centers the input text in the autocomplete control.
  }

  &&& .MuiInputBase-root {
    height: auto;
  }
`;
