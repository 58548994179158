import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.main`
  flex: 1;
  padding: 10px 20px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    display: flex;
    padding: 40px 60px;
  }
`;

export const CenteredContent = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1120px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 15vh auto;
  }
`;

export const Section = styled.section``;

export const MainContent = styled(Section)`
  grid-column: 1 / 3;
  grid-row: 1;
  justify-self: end;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    text-align: left;
  }
`;

export const MainContentWrapper = styled.div``;

export const LeftSectionContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    width: auto;
    margin: 0;
    grid-column: 1 / 3;
    grid-row: 1;
    justify-self: start;
    display: flex;
    padding-top: 30px;
  }
`;

export const Footer = styled.footer`
  padding: 0 20px;
`;
