import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';

import { FormControl, InputLabel, SelectProps } from '@breathelife/mui';
import { RenderingFieldOption } from '@breathelife/questionnaire-engine';

import { Text } from '../Typography';
import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { WarningText } from './Styles/CommonStyles';
import { StyledFormControlLabel, StyledSelect, StyledMenuItem } from './Styles/FieldStyles';

type Props = FieldProps &
  Omit<SelectProps, 'onAnswerChange'> & {
    options: RenderingFieldOption[];
    required?: boolean;
    value?: string;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (field: string, answer: string, previousAnswer?: string) => void;
    inputVariant?: InputVariant;
  };

const MUI_NONE = '__none__';

export function SelectMUI(props: Props): ReactElement {
  const {
    disabled,
    id,
    inputVariant,
    label,
    name,
    onAnswerChange,
    onAnswerComplete,
    optionalText,
    options,
    placeholder,
    required,
    title,
    validationError,
    value,
  } = props;

  const showError = !!validationError;
  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);
  const selectFieldProps = {
    id,
    name,
    disabled,
    required,
    value: value === '' ? MUI_NONE : value,
    onChange: (event: any) => {
      const updatedValue = event.target.value === MUI_NONE ? '' : event.target.value;
      onAnswerChange && onAnswerChange(updatedValue);
      onAnswerComplete && onAnswerComplete(name, updatedValue, value);
    },
  };

  const menuOptions = options.map((option) => {
    // Force undefined disabled value for the option if the radio group
    // is disabled otherwise the UI won't show as disabled
    const itemDisabled = disabled ? undefined : option.disabled;
    return (
      <StyledMenuItem
        key={option.id}
        value={option.id === '' ? MUI_NONE : option.id}
        disabled={itemDisabled}
        data-testid={`${id}_${option.id}`}
      >
        {ReactHtmlParser(option.text)}
      </StyledMenuItem>
    );
  });

  menuOptions.unshift(
    <StyledMenuItem key={MUI_NONE} value={MUI_NONE}>
      <Text grey={70} component='span'>
        {`- ${placeholder} -`}
      </Text>
    </StyledMenuItem>,
  );

  if (inputVariant === 'outlined') {
    return (
      <FormControl variant='standard' fullWidth error={showError} required={required}>
        <StyledFormControlLabel
          id={id + '-label'}
          showError={showError}
          control={
            <StyledSelect
              labelId={id + '-label'}
              {...selectFieldProps}
              SelectDisplayProps={{
                'aria-required': required,
              }}
              variant={inputVariant}
            >
              {menuOptions}
            </StyledSelect>
          }
          label={ReactHtmlParser(labelValue)}
          labelPlacement='top'
        />
        {validationError && <WarningText>{validationError.message}</WarningText>}
      </FormControl>
    );
  }

  return (
    <FormControl variant='standard' fullWidth error={showError} required={required}>
      <InputLabel>{ReactHtmlParser(labelValue)}</InputLabel>

      <StyledSelect
        {...selectFieldProps}
        SelectDisplayProps={{
          'aria-required': required,
        }}
      >
        {menuOptions}
      </StyledSelect>
      {validationError && <WarningText>{validationError.message}</WarningText>}
    </FormControl>
  );
}
