import { ReactElement } from 'react';
import styled from 'styled-components';
import { colors, fontSize } from '../../Theme/Theme';
import { buttonStyle } from '../Button/Styles';

export const Link = styled.a`
  ${buttonStyle}
  display: inline-flex;

  font-size: ${fontSize.label.medium};
  font-family: inherit;

  &:hover,
  &:active,
  &:focus {
    color: ${colors.anchor.focus};
  }
`;

export function LinkButton(props: any): ReactElement {
  const handleClick = (e: any): void => {
    if (props.disabled) {
      return e.preventDefault();
    }
    props.onClick && props.onClick(e);
  };

  return (
    <Link {...props} href={props.href} onClick={handleClick}>
      {props.children}
    </Link>
  );
}
