import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Night(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='17' viewBox='0 0 14 17' width='14' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m6.07467 2.82659c.68594.96494 1.09423 2.09946 1.1805 3.28021.07737 1.24293-.19681 2.48252-.79114 3.57687-.59433 1.09433-1.4848 1.99923-2.56947 2.61103-.67536.3842-1.41659.6387-2.18548.7504-.20203.0333-.38904.1276-.53594.2702-.14691.1427-.246664.3268-.285894.5278s-.016055.4091.066417.5965c.082467.1874.220297.3451.394967.452.9224.5891 1.96225.9698 3.04699 1.1156 1.08473.1457 2.1882.0529 3.23338-.2718 1.04518-.3248 2.00689-.8738 2.818-1.6087.8111-.7348 1.452-1.6378 1.8781-2.646.426-1.0081.6269-2.09712.5886-3.19093s-.3149-2.16608-.8104-3.14197c-.4955-.97588-1.198-1.83186-2.0585-2.50819-.86054-.67632-1.85829-1.15669-2.92362-1.40758-.20328-.05066-.41728-.03687-.61238.03945s-.36165.2114-.4766.38654c-.11495.17515-.17261.38169-.165.59105s.08011.41118.20747.57752z'
        fill='#f6f6f6'
      />
      <path
        clipRule='evenodd'
        d='m7.24661.711517c1.14016.26892 2.20796.783253 3.12899 1.507143.9218.72447 1.6743 1.64138 2.2051 2.68673.5308 1.04536.827 2.19396.8681 3.36563.041 1.17167-.1742 2.33818-.6306 3.41808-.4563 1.0799-1.1429 2.0472-2.0117 2.8344-.86888.7872-1.89904 1.3752-3.01862 1.7231s-2.30161.4473-3.46356.2912c-1.16038-.1559-2.27283-.5629-3.25989-1.1925-.265558-.1637-.475115-.4044-.600817-.69-.126468-.2874-.162006-.6066-.101848-.9148.060157-.3082.213132-.5906.4384-.8093.225265-.2187.512045-.3633.821855-.4144l.01006-.0015c.70285-.1022 1.38041-.3348 1.99777-.686l.00166-.0009c.9971-.5624 1.81568-1.3943 2.36203-2.40026.54588-1.00514.79796-2.14357.72745-3.28517-.07938-1.07936-.45212-2.1165-1.0779-2.99943-.19083-.25285-.29942-.5583-.31093-.87503-.01165-.3203.07657-.6363.25243-.90426s.43067-.474625.72916-.591388c.2972-.116259.62306-.137668.93286-.061345zm-1.17195 2.115053.43638-.3102c.74265 1.04472 1.1847 2.27303 1.2781 3.55141l.00042.00575h-.00003c.08362 1.34327-.2127 2.68294-.85502 3.86565-.64216 1.18242-1.60422 2.16022-2.77608 2.82142-.73136.4158-1.53382.6917-2.36626.8134-.09274.0161-.1785.0598-.24607.1254-.06854.0666-.11508.1525-.13338.2463-.0183.0937-.00749.1908.03098.2783.03848.0874.10278.161.18427.2108l.00885.0056c.85673.5471 1.82257.9008 2.83009 1.0361 1.00751.1354 2.03243.0492 3.00321-.2524.97078-.3017 1.86403-.8116 2.61738-1.4941.7534-.6826 1.3487-1.5213 1.7444-2.4577s.5823-1.94786.5467-2.96381c-.0355-1.01595-.2924-2.01189-.7527-2.9183-.4602-.90642-1.1127-1.70147-1.91198-2.32965-.79925-.62818-1.72597-1.07435-2.71547-1.30738l-.00675-.00159.00001-.00004c-.09556-.02382-.19616-.01734-.28787.01854s-.17001.09938-.22404.18171-.08114.17942-.07756.27784c.00358.09841.03766.19328.09752.27147z'
        fill={props.color?.primary || theme.colors.icons.primary}
        fillRule='evenodd'
      />
    </svg>
  );
}
