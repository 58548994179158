import styled from 'styled-components';

type Props = {
  direction?: 'horizontal' | 'vertical';
  size?: string;
};

export const Stack = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => (props.direction === 'horizontal' ? 'row' : 'column')};

  > div + * {
    margin-left: ${(props) => (props.direction === 'horizontal' && props.size) ?? '16px'};
    margin-top: ${(props) => (props.direction !== 'horizontal' && props.size) ?? '16px'};
  }
`;
