import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Unfortunately(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <path d='m44 24c0 11.0457-8.9543 20-20 20s-20-8.9543-20-20 8.9543-20 20-20 20 8.9543 20 20z' fill='#fff' />
      <path
        clipRule='evenodd'
        d='m24 43c10.4934 0 19-8.5066 19-19s-8.5066-19-19-19-19 8.5066-19 19 8.5066 19 19 19zm0 1c11.0457 0 20-8.9543 20-20s-8.9543-20-20-20-20 8.9543-20 20 8.9543 20 20 20z'
        fill='#f6f6f6'
        fillRule='evenodd'
      />
      <g fill={props.color?.tertiary || theme.colors.icons.tertiary}>
        <path
          d='m24 23.1187 7.6989-13.99896c.0662-.11992.1644-.21907.2838-.28635.1193-.06729.255-.10006.3918-.09466.1369.00541.2696.04878.3832.12526.1137.07649.2038.18307.2603.30784.6765 1.50587.9652 3.15707.8399 4.80307s-.6606 3.2345-1.5572 4.6206c-.8965 1.3861-2.1258 2.5257-3.5757 3.315-1.4498.7892-3.0742 1.203-4.725 1.2036z'
          stroke={primaryColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.7'
        />
        <path
          d='m24 23.1186-7.7005-13.99893c-.0659-.11999-.164-.21923-.2832-.28659-.1192-.06737-.2549-.10018-.3917-.09477s-.2694.04883-.3829.12539c-.1136.07656-.2035.18324-.2598.30807-.6764 1.50583-.9651 3.15703-.8398 4.80303.1252 1.646.6606 3.2345 1.5571 4.6206.8966 1.3861 2.1258 2.5258 3.5757 3.315 1.4499.7893 3.0743 1.2031 4.7251 1.2036z'
          stroke={primaryColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.7'
        />
        <path d='m24.023 22.5873-4.8668-8.1112h9.7335z' />
        <path d='m19.4404 14.8299c1.1319-2.5717 2.4361-5.06397 3.9039-7.45988.0682-.11291.1644-.2063.2792-.27113.1149-.06483.2446-.09889.3765-.09889s.2616.03406.3765.09889.2111.15822.2793.27113c1.46 2.40052 2.7635 4.89288 3.9022 7.46138' />
      </g>
      <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.7'>
        <path
          d='m19.4404 14.8299c1.1319-2.5717 2.4361-5.06397 3.9039-7.45988.0682-.11291.1644-.2063.2792-.27113.1149-.06483.2446-.09889.3765-.09889s.2616.03406.3765.09889.2111.15822.2793.27113c1.46 2.40052 2.7635 4.89288 3.9022 7.46138l-4.558 8.2966z'
          stroke={primaryColor}
        />
        <path
          d='m34.1748 38.6666c-.0652.7641-.4145 1.4759-.979 1.995s-1.3031.8077-2.0699.8087h-14.2487c-.7666-.0014-1.5048-.2901-2.069-.8092-.5641-.519-.9132-1.2307-.9784-1.9945l-1.2977-15.5479h22.9373z'
          fill='#f6f6f6'
          stroke={primaryColor}
        />
        <path
          d='m28.7833 36.8832c-.4218-.9139-1.0965-1.6878-1.9443-2.2303s-1.8333-.8308-2.8398-.8308-1.9919.2883-2.8397.8308-1.5225 1.3164-1.9443 2.2303'
          stroke={primaryColor}
        />
      </g>
      <circle cx='19.6969' cy='28.5355' fill={primaryColor} r='1.62224' />
      <circle cx='28.3489' cy='28.5355' fill={primaryColor} r='1.62224' />
      <path d='m11 23.1187h26' stroke={primaryColor} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.7' />
    </svg>
  );
}
