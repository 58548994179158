import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
`;

export const LeftSectionContainer = styled.div`
  flex-basis: 0;
  display: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    flex-basis: 20%;
    display: initial;
  }
`;

export const MiddleSectionContainer = styled.div`
  flex: 1;
`;

export const RightSectionContainer = styled.div`
  flex-basis: 20%;
`;
