import { ReactElement } from 'react';

import { SVGIconProps } from '../../Components/Icon/IconMap';

export function BackArrowIcon(props: SVGIconProps): ReactElement {
  return (
    <svg
      width='800px'
      height='800px'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      stroke={props.color?.primary}
      fill='none'
    >
      <g data-name='Layer 2' id='Layer_2' />
      <g data-name='E421, Back, buttons, multimedia, play, stop' id='E421_Back_buttons_multimedia_play_stop' />
      <circle strokeLinecap='round' strokeLinejoin='round' strokeWidth='20px' cx='256' cy='256' r='246' />
      <line strokeLinecap='round' strokeLinejoin='round' strokeWidth='20px' x1='352.26' x2='170.43' y1='256' y2='256' />
      <polyline
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='20px'
        points='223.91 202.52 170.44 256 223.91 309.48'
      />
    </svg>
  );
}
