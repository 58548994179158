import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, Fragment, useEffect, useState } from 'react';

import { DeepPartial } from '@breathelife/types';

import { StringGrammarNumber } from '../../../Localization/types';
import { ContactForm, OnSubmitScheduleDataType } from '../../ContactForm';
import { LinkText } from '../../LinkText/LinkText';
import { PhoneButton } from '../../PhoneButton/PhoneButton';
import { Tabs, TabsButton } from '../../Tabs';
import { RunningText } from '../../Typography';
import { Confirmation } from './Confirmation';
import { localizationStrings, LocalizationStrings } from './Localization';
import { OpeningHours } from './OpeningHours';
import { SecurityDisclosure } from './Styles';
import { BaseContactAdvisorModalProps, ViewTabs } from './types';

type Props = Omit<BaseContactAdvisorModalProps, 'localizationStrings'> & {
  localizationStrings?: DeepPartial<LocalizationStrings>;
  onSubmitContactForm: (onSubmitContactArgs: OnSubmitScheduleDataType) => void;
  hasContactPersonInfo?: boolean;
};

export function ContactAdvisorTabs(props: Props): ReactElement {
  const [selectedTab, setSelectedTab] = useState<ViewTabs>(ViewTabs.callUs);

  const {
    form,
    language,
    localizationStrings: localizationStringsProps,
    customerServicePhoneNumber,
    customerServiceEmail,
    hasContactPersonInfo,
    securityDisclosure,
    openingHours,
    useMobileStylingOnDesktop,
    hideEmail,
    hidePhone,
    onSubmitContactForm,
    isPlural: isPluralProps = false,
    carrierContactPhoneNumber,
    carrierContactEmail,
  } = props;

  const mergedLocalizationStrings = _.merge({}, localizationStrings, localizationStringsProps);
  const textStrings = mergedLocalizationStrings[language];
  const isPlural = hasContactPersonInfo || isPluralProps;
  const noun = isPlural ? StringGrammarNumber.plural : StringGrammarNumber.single;
  const { schedulingTimeFrames, isSubmitting, ...scheduleData } = _.omit(form, 'onSubmitSchedule');

  useEffect(() => {
    if (useMobileStylingOnDesktop) setSelectedTab(ViewTabs.callYou);
  }, [useMobileStylingOnDesktop]);

  return (
    <Fragment>
      {!useMobileStylingOnDesktop && (
        <Box my={3}>
          <Tabs>
            <TabsButton isSelected={selectedTab === ViewTabs.callUs} onClick={() => setSelectedTab(ViewTabs.callUs)}>
              {textStrings.tabs[noun].callUs}
            </TabsButton>
            <TabsButton isSelected={selectedTab === ViewTabs.callYou} onClick={() => setSelectedTab(ViewTabs.callYou)}>
              {textStrings.tabs[noun].callYou}
            </TabsButton>
          </Tabs>
        </Box>
      )}

      {selectedTab === ViewTabs.callUs && (
        <Fragment>
          <Box mb={4}>
            <RunningText>{textStrings.subtitle[noun].callUs}</RunningText>
          </Box>

          {openingHours && (
            <span>
              <OpeningHours label={textStrings.openingHours[noun]} language={language} openingHours={openingHours} />
            </span>
          )}

          {(customerServicePhoneNumber || carrierContactPhoneNumber) && (
            <Box m={3}>
              <PhoneButton alt='call' fullSize phoneNumber={customerServicePhoneNumber || carrierContactPhoneNumber} />
            </Box>
          )}
          {(customerServiceEmail || carrierContactEmail) && (
            <Box textAlign='center'>
              <LinkText href={`mailto:${customerServiceEmail || carrierContactEmail}`}>
                {textStrings.emailUs[noun]}
              </LinkText>
            </Box>
          )}
          {securityDisclosure && (
            <Box mt={8}>
              <SecurityDisclosure>{securityDisclosure}</SecurityDisclosure>
            </Box>
          )}
        </Fragment>
      )}

      {selectedTab === ViewTabs.callYou && (
        <Fragment>
          {!form.isSubmitted ? (
            <ContactForm
              schedulingTimeFrames={schedulingTimeFrames}
              language={language}
              localizationStrings={mergedLocalizationStrings}
              isPlural={isPlural}
              hideEmail={hideEmail}
              hidePhone={hidePhone}
              scheduleData={scheduleData}
              isSubmitting={isSubmitting}
              onSubmitContactForm={onSubmitContactForm}
            />
          ) : (
            <Confirmation
              title={textStrings.confirmation[noun].title}
              subtitle={textStrings.confirmation[noun].subtitle}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
