import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function HealthPoor(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='24' cy='24' fill='#fff' r='19.5' stroke='#f6f6f6' />
      <path
        d='m40.3166 19.45c-.6718 0-1.3162.2669-1.7913.742s-.742 1.1195-.742 1.7913v2.5334c0 .3359-.1334.6581-.371.8956-.2375.2376-.5597.371-.8957.371h-25.3333c-.3359 0-.6581-.1334-.8957-.371-.2375-.2375-.37096-.5597-.37096-.8956v-2.5334c0-.6718-.2669-1.3162-.74199-1.7913-.4751-.4751-1.11946-.742-1.79134-.742s-1.31625.2669-1.79134.742-.74199 1.1195-.74199 1.7913v5.0667c0 1.3438.5338 2.6325 1.48399 3.5827.95018.9502 2.23891 1.484 3.58267 1.484h27.86666c1.3438 0 2.6325-.5338 3.5827-1.484s1.484-2.2389 1.484-3.5827v-5.0667c0-.6718-.2669-1.3162-.742-1.7913s-1.1195-.742-1.7914-.742z'
        fill={theme.colors.icons.secondary}
      />
      <g clipRule='evenodd' fill={props.color?.primary || theme.colors.icons.primary} fillRule='evenodd'>
        <path d='m4.99096 19.591c.63449-.6345 1.49506-.991 2.39237-.991.89732 0 1.75788.3565 2.39238.991.63449.6345.99099 1.495.99099 2.3923v2.5334c0 .1105.0439.2165.122.2946s.1841.122.2946.122h25.3334c.1105 0 .2165-.0439.2946-.122s.122-.1841.122-.2946v-2.5334c0-.8973.3565-1.7578.991-2.3923s1.495-.991 2.3924-.991c.8973 0 1.7578.3565 2.3923.991s.991 1.495.991 2.3923v5.0667c0 1.5692-.6234 3.0741-1.733 4.1837-1.1095 1.1096-2.6145 1.733-4.1837 1.733h-27.86663c-1.5692 0-3.07413-.6234-4.18372-1.733s-1.73295-2.6145-1.73295-4.1837v-5.0667c0-.8973.35646-1.7578.99096-2.3923zm2.39237.709c-.44644 0-.87461.1774-1.19029.493-.31569.3157-.49304.7439-.49304 1.1903v5.0667c0 1.1183.44425 2.1909 1.23503 2.9816.79078.7908 1.86331 1.2351 2.98164 1.2351h27.86663c1.1184 0 2.1909-.4443 2.9817-1.2351.7907-.7907 1.235-1.8633 1.235-2.9816v-5.0667c0-.4464-.1774-.8746-.493-1.1903-.3157-.3156-.7439-.493-1.1903-.493-.4465 0-.8746.1774-1.1903.493-.3157.3157-.4931.7439-.4931 1.1903v2.5334c0 .5613-.223 1.0997-.6199 1.4967-.397.3969-.9354.6199-1.4967.6199h-25.3334c-.5613 0-1.0997-.223-1.49668-.6199-.39695-.397-.61995-.9354-.61995-1.4967v-2.5334c0-.4464-.17735-.8746-.49304-1.1903-.31569-.3156-.74385-.493-1.1903-.493z' />
        <path d='m9.91665 31.2667c.46945 0 .85005.3806.85005.85v3.8c0 .4694-.3806.85-.85005.85-.46944 0-.85-.3806-.85-.85v-3.8c0-.4694.38056-.85.85-.85z' />
        <path d='m37.7833 31.2667c.4695 0 .85.3806.85.85v3.8c0 .4694-.3805.85-.85.85-.4694 0-.85-.3806-.85-.85v-3.8c0-.4694.3806-.85.85-.85z' />
        <path d='m14.9833 12.7c-1.7902 0-3.5071.7112-4.7729 1.977-1.26592 1.2659-1.97707 2.9828-1.97707 4.773 0 .4694-.38056.85-.85.85-.46945 0-.85-.3806-.85-.85 0-2.2411.89026-4.3904 2.47494-5.9751 1.58473-1.5846 3.73393-2.4749 5.97503-2.4749h17.7334c2.241 0 4.3903.8903 5.975 2.4749 1.5847 1.5847 2.475 3.734 2.475 5.9751 0 .4694-.3806.85-.85.85-.4695 0-.85-.3806-.85-.85 0-1.7902-.7112-3.5071-1.9771-4.773-1.2658-1.2658-2.9827-1.977-4.7729-1.977z' />
        <path d='m23.85 11c.4694 0 .85.3806.85.85v13.9333c0 .4695-.3806.85-.85.85s-.85-.3805-.85-.85v-13.9333c0-.4694.3806-.85.85-.85z' />
      </g>
    </svg>
  );
}
