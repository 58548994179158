import { Pluralizable } from '../../../Localization/types';

export type LocalizedStrings = {
  title: string;
  subtitle: Pluralizable<{
    callYou: string;
    callUs: string;
  }>;
  tabs: Pluralizable<{
    callYou: string;
    callUs: string;
  }>;
  referenceNumber: string;
  emailUs: Pluralizable<string>;
  close: string;
  selectTimeSlots: string;
  openingHours: Pluralizable<string>;
  confirmation: Pluralizable<{
    title: string;
    subtitle: string;
  }>;
};

export type LocalizationStrings = {
  en: LocalizedStrings;
  fr: LocalizedStrings;
};

export const localizationStrings: LocalizationStrings = {
  en: {
    title: 'Hi,\nHow would you like to communicate?',
    subtitle: {
      single: {
        callYou: 'Please provide me with your contact information and I’ll be in touch with you soon.',
        callUs: 'Reach me via the actions at the bottom of this page.',
      },
      plural: {
        callYou: 'Please provide us your contact information and we’ll be in touch with you soon.',
        callUs: 'Reach us via the actions at the bottom of this page.',
      },
    },
    tabs: {
      single: {
        callUs: 'Contact me',
        callYou: "I'll contact you",
      },
      plural: {
        callUs: 'Contact us',
        callYou: "We'll contact you",
      },
    },
    referenceNumber: 'Your reference number',
    emailUs: {
      single: 'Send me an email',
      plural: 'Send us an email',
    },
    close: 'Close',
    selectTimeSlots: 'Select one or more time slots below',
    openingHours: {
      single: 'I’m available during these business hours:',
      plural: 'We are available during these business hours:',
    },
    confirmation: {
      single: {
        title: "I'll be in touch soon!",
        subtitle: 'Your request has been successfully sent. I will contact you shortly.',
      },
      plural: {
        title: 'We’ll be in touch soon!',
        subtitle: 'Your request has been successfully sent. We will contact you shortly.',
      },
    },
  },
  fr: {
    title: 'Bonjour. Comment souhaitez-vous communiquer ?',
    subtitle: {
      single: {
        callYou: 'Veuillez me fournir vos coordonnées et je vous contacterai bientôt.',
        callUs: 'Rejoignez-moi par e-mail ou par téléphone via les actions en bas de cette page.',
      },
      plural: {
        callYou: 'Veuillez nous fournir vos coordonnées et nous vous contacterons bientôt.',
        callUs: 'Rejoignez-nous via les actions en bas de cette page.',
      },
    },
    tabs: {
      single: {
        callUs: 'Contactez-moi',
        callYou: 'Je vous contacte',
      },
      plural: {
        callUs: 'Contactez-nous',
        callYou: 'On vous contacte',
      },
    },
    referenceNumber: 'Votre numéro de référence',
    emailUs: {
      single: 'Envoyez-moi un courriel',
      plural: 'Envoyez-nous un courriel',
    },
    close: 'Fermer',
    selectTimeSlots: 'Sélectionnez une ou plusieurs plages horaires ci-dessous',
    openingHours: {
      single: "Je suis disponible pendant ces heures d'ouverture :",
      plural: "Nos heures d'ouverture :",
    },
    confirmation: {
      single: {
        title: 'Je vous contacterai bientôt !',
        subtitle: 'Votre rêquete a bien été reçue. Je vous contacterai sous peu.',
      },
      plural: {
        title: 'Nous vous contacterons bientôt !',
        subtitle: 'Votre rêquete a bien été reçue. Nous vous contacterons sous peu.',
      },
    },
  },
};
