import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function AfterDeathIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M39.3777 20.0412C39.3777 26.1817 34.3998 31.1597 28.2592 31.1597C22.1187 31.1597 17.1407 26.1817 17.1407 20.0412C17.1407 13.9006 22.1187 8.92273 28.2592 8.92273C34.3998 8.92273 39.3777 13.9006 39.3777 20.0412Z'
        fill={secondaryColor}
      />
      <path
        fill={primaryColor}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.92276 26.4218C9.34953 26.4218 9.6955 26.7678 9.6955 27.1946V39.3773C9.6955 39.804 9.34953 40.15 8.92276 40.15C8.49599 40.15 8.15002 39.804 8.15002 39.3773V27.1946C8.15002 26.7678 8.49599 26.4218 8.92276 26.4218Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.15002 29.2251C8.15002 28.7983 8.49599 28.4523 8.92276 28.4523H16.0293C17.3113 28.4523 18.5407 28.9616 19.4472 29.8681C20.1695 30.5903 20.6396 31.5177 20.8008 32.5132H25.1663C26.4483 32.5132 27.6777 33.0225 28.5842 33.929C29.4907 34.8354 30 36.0649 30 37.3469C30 37.7736 29.654 38.1196 29.2272 38.1196H8.92276C8.49599 38.1196 8.15002 37.7736 8.15002 37.3469C8.15002 36.9201 8.49599 36.5741 8.92276 36.5741H28.3624C28.2215 35.9912 27.9224 35.4528 27.4914 35.0218C26.8748 34.4051 26.0384 34.0587 25.1663 34.0587H20.0902C19.6634 34.0587 19.3175 33.7127 19.3175 33.286C19.3175 32.4139 18.971 31.5775 18.3544 30.9609C17.7378 30.3442 16.9014 29.9978 16.0293 29.9978H8.92276C8.49599 29.9978 8.15002 29.6518 8.15002 29.2251Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2566 33.2859C15.2566 32.8591 15.6026 32.5132 16.0293 32.5132H20.0902C20.517 32.5132 20.863 32.8591 20.863 33.2859C20.863 33.7127 20.517 34.0587 20.0902 34.0587H16.0293C15.6026 34.0587 15.2566 33.7127 15.2566 33.2859Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.4617 10.6043C30.4913 9.72138 28.2949 9.47393 26.1774 9.89633C24.06 10.3187 22.1266 11.39 20.6459 12.9614C19.1651 14.5328 18.2104 16.5263 17.9144 18.6651C17.6184 20.8039 17.9957 22.9817 18.994 24.8962C19.1913 25.2747 19.0445 25.7414 18.6661 25.9387C18.2877 26.136 17.821 25.9892 17.6237 25.6108C16.4769 23.4116 16.0435 20.91 16.3835 18.4532C16.7235 15.9964 17.8202 13.7065 19.5211 11.9015C21.222 10.0965 23.4428 8.86592 25.8751 8.38072C28.3073 7.89552 30.8303 8.17976 33.0936 9.19398C35.357 10.2082 37.2483 11.9021 38.505 14.0403C39.7616 16.1785 40.3213 18.655 40.1061 21.1259C39.891 23.5967 38.9118 25.9392 37.3045 27.8281C35.6972 29.717 33.5416 31.0585 31.1371 31.6663C30.7233 31.7709 30.3031 31.5202 30.1985 31.1065C30.0939 30.6927 30.3446 30.2725 30.7583 30.1679C32.8516 29.6388 34.7282 28.471 36.1275 26.8266C37.5267 25.1822 38.3792 23.1428 38.5665 20.9918C38.7538 18.8408 38.2666 16.6848 37.1726 14.8234C36.0786 12.9619 34.432 11.4873 32.4617 10.6043Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.1968 12.2087C27.6236 12.2087 27.9695 12.5547 27.9695 12.9814V20.3305H32.2729C32.6997 20.3305 33.0457 20.6765 33.0457 21.1032C33.0457 21.53 32.6997 21.876 32.2729 21.876H27.1968C26.77 21.876 26.4241 21.53 26.4241 21.1032V12.9814C26.4241 12.5547 26.77 12.2087 27.1968 12.2087Z'
        fill={primaryColor}
      />
    </svg>
  );
}
