import styled from 'styled-components';

export const StyledFooter = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.family.fallbacks};
  font-size: ${(props) => props.theme.fonts.size.small};
  padding: 30px 0;
  color: ${(props) => props.theme.colors.grey.dark};
  border-top: 1px solid ${(props) => props.theme.colors.grey.light};

  a {
    color: ${(props) => props.theme.colors.base.primary};
    display: block;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      display: inline;
    }
  }
`;
