import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Error(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.96 19.547C20.9849 19.5717 21.0146 19.5909 21.0473 19.6034C21.0801 19.616 21.115 19.6216 21.15 19.62C21.185 19.6181 21.2192 19.6088 21.2504 19.5927C21.2816 19.5767 21.3091 19.5543 21.331 19.527C23.1936 17.2249 24.1382 14.3148 23.9826 11.3576C23.827 8.4004 22.5824 5.60547 20.4885 3.51148C18.3946 1.41748 15.5998 0.172652 12.6426 0.0169071C9.68542 -0.138838 6.77526 0.805531 4.47302 2.668C4.4458 2.69017 4.42354 2.7178 4.40768 2.74912C4.39181 2.78043 4.38268 2.81472 4.38089 2.84978C4.3791 2.88483 4.38469 2.91988 4.39728 2.95264C4.40988 2.98541 4.4292 3.01516 4.45402 3.04L20.96 19.547ZM3.04002 4.454C3.01527 4.42913 2.98556 4.40978 2.95282 4.3972C2.92007 4.38461 2.88504 4.37909 2.85002 4.381C2.81496 4.38286 2.78067 4.39201 2.74934 4.40785C2.718 4.42369 2.69031 4.44587 2.66802 4.473C0.805414 6.77513 -0.139127 9.68523 0.0164424 12.6424C0.172012 15.5996 1.41668 18.3945 3.51055 20.4885C5.60442 22.5825 8.39928 23.8273 11.3564 23.9831C14.3136 24.1388 17.2238 23.1945 19.526 21.332C19.5535 21.3102 19.576 21.2827 19.592 21.2515C19.6081 21.2203 19.6173 21.186 19.619 21.151C19.6209 21.116 19.6154 21.0809 19.6028 21.0482C19.5902 21.0155 19.5709 20.9857 19.546 20.961L3.04002 4.454Z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
