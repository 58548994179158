import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function InformationOutlined(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const color = props.color?.primary || theme.colors.icons.primary;
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.75 12C0.75 14.9837 1.93526 17.8452 4.04505 19.955C6.15483 22.0647 9.01631 23.25 12 23.25C14.9837 23.25 17.8452 22.0647 19.955 19.955C22.0647 17.8452 23.25 14.9837 23.25 12C23.25 9.01631 22.0647 6.15483 19.955 4.04505C17.8452 1.93526 14.9837 0.75 12 0.75C9.01631 0.75 6.15483 1.93526 4.04505 4.04505C1.93526 6.15483 0.75 9.01631 0.75 12Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472ZM12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5Z'
        fill={color}
      />
      <path
        d='M14.25 16.5H13.5C13.1022 16.5 12.7206 16.342 12.4393 16.0607C12.158 15.7794 12 15.3978 12 15V11.25C12 11.0511 11.921 10.8603 11.7803 10.7197C11.6397 10.579 11.4489 10.5 11.25 10.5H10.5'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 10.5C9.75 10.0858 10.0858 9.75 10.5 9.75H11.25C11.6478 9.75 12.0294 9.90804 12.3107 10.1893C12.592 10.4706 12.75 10.8522 12.75 11.25V15C12.75 15.1989 12.829 15.3897 12.9697 15.5303C13.1103 15.671 13.3011 15.75 13.5 15.75H14.25C14.6642 15.75 15 16.0858 15 16.5C15 16.9142 14.6642 17.25 14.25 17.25H13.5C12.9033 17.25 12.331 17.0129 11.909 16.591C11.4871 16.169 11.25 15.5967 11.25 15V11.25H10.5C10.0858 11.25 9.75 10.9142 9.75 10.5Z'
        fill={color}
      />
      <path
        d='M11.625 6.75C11.5508 6.75 11.4783 6.77199 11.4167 6.8132C11.355 6.8544 11.3069 6.91297 11.2785 6.98149C11.2502 7.05002 11.2427 7.12542 11.2572 7.19816C11.2717 7.2709 11.3074 7.33772 11.3598 7.39016C11.4123 7.44261 11.4791 7.47833 11.5518 7.49279C11.6246 7.50726 11.7 7.49984 11.7685 7.47145C11.837 7.44307 11.8956 7.39501 11.9368 7.33334C11.978 7.27167 12 7.19917 12 7.125C12 7.02554 11.9605 6.93016 11.8902 6.85984C11.8198 6.78951 11.7245 6.75 11.625 6.75Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 6.1896C11.185 6.06598 11.4025 6 11.625 6C11.9234 6 12.2095 6.11853 12.4205 6.32951C12.6315 6.54048 12.75 6.82663 12.75 7.125C12.75 7.34751 12.684 7.56501 12.5604 7.75002C12.4368 7.93502 12.2611 8.07922 12.0555 8.16436C11.85 8.24951 11.6238 8.27179 11.4055 8.22838C11.1873 8.18497 10.9868 8.07783 10.8295 7.92049C10.6722 7.76316 10.565 7.5627 10.5216 7.34448C10.4782 7.12625 10.5005 6.90005 10.5856 6.69448C10.6708 6.48892 10.815 6.31321 11 6.1896ZM11.7685 7.47146C11.7911 7.4621 11.8128 7.45051 11.8333 7.4368C11.8745 7.40931 11.9096 7.37409 11.9368 7.33334C11.9504 7.31301 11.962 7.29131 11.9715 7.26851C11.9904 7.22279 12 7.17401 12 7.125C12 7.10055 11.9976 7.07605 11.9928 7.05184C11.9783 6.9791 11.9426 6.91228 11.8902 6.85983C11.8377 6.80739 11.7709 6.77168 11.6982 6.75721C11.6739 6.75239 11.6494 6.75 11.625 6.75C11.576 6.75 11.5272 6.75961 11.4815 6.77854C11.4587 6.78799 11.437 6.79962 11.4167 6.8132C11.3759 6.84043 11.3407 6.87552 11.3132 6.91666C11.2995 6.93718 11.2879 6.95891 11.2785 6.98149C11.2598 7.02677 11.25 7.07551 11.25 7.125C11.25 7.14975 11.2524 7.17424 11.2572 7.19816C11.2716 7.27037 11.307 7.33734 11.3598 7.39017C11.4127 7.44299 11.4796 7.47843 11.5518 7.4928C11.5758 7.49755 11.6003 7.5 11.625 7.5C11.6745 7.5 11.7232 7.49021 11.7685 7.47146Z'
        fill={color}
      />
    </svg>
  );
}
