import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { DragHandleIcon } from '@breathelife/mui';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function DragHandle(props: SVGIconProps & { size?: string }): ReactElement {
  const theme = useTheme() as SharedTheme;

  return (
    <DragHandleIcon
      style={{ color: props.color?.primary || theme.colors.icons.primary, fontSize: props.size || '16px' }}
    />
  );
}
