import styled from 'styled-components';

export type ProgressProps = {
  progress?: number;
};

export const Container = styled.div`
  width: 100%;
  height: 4px;
`;

export const StyledProgress = styled.div<ProgressProps>`
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey.light};

  &:after {
    background-color: ${(props) => props.theme.colors.base.primary};
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => props.progress ?? 0}%;
    transition: all ${(props) => props.theme.animation.speed.slow} ${(props) => props.theme.animation.transition};
  }
`;
