import { ReactElement, ReactNode, createContext, Component } from 'react';
import { connect } from 'react-redux';

import { ScreenName, TypewriterTracking } from '@breathelife/frontend-tracking';

export type ContextProps = {
  popup: ReactElement | null;
  setPopup: (popup: ReactElement | null, hashedId?: string, screenName?: ScreenName) => void;
};

export const PopupContext = createContext({
  popup: null,
  setPopup: () => {},
} as ContextProps);

type Props = {
  children: ReactNode;
};

class PopupProviderView extends Component<Props> {
  setPopup = (popup: ReactElement | null, hashedId?: string, screenName?: ScreenName) => {
    popup && screenName && hashedId && TypewriterTracking.viewedScreen({ screenName, hashedId });
    this.setState({ popup });
  };

  state = {
    popup: null,
    setPopup: this.setPopup,
  };

  render() {
    return <PopupContext.Provider value={this.state}>{this.props.children}</PopupContext.Provider>;
  }
}

const mapDispatchToProps = () => ({});

export const PopupProvider = connect(null, mapDispatchToProps)(PopupProviderView);
