import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactNode, Attributes, ReactElement } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { FullColorGradient } from '@breathelife/types';

import { fallbackFontFamilies } from '../Theme/Base';
import { breakpoints } from '../Theme/breakpoints';

const baseHeaderMixin = css`
  margin: 0;
  letter-spacing: 0.2px;
`;

const h1Mixin = css`
  font-weight: bold;
  white-space: pre-wrap;

  font-size: 20px;
  line-height: 26px;

  @media (min-width: ${breakpoints.tablet.min}) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const h2Mixin = css`
  font-weight: bold;
  white-space: pre-wrap;

  font-size: 18px;
  line-height: 24px;

  @media (min-width: ${breakpoints.tablet.min}) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const h3Mixin = css`
  font-weight: normal;
  font-size: 17px;
  line-height: 19px;

  @media (min-width: ${breakpoints.tablet.min}) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const h4Mixin = css`
  font-weight: bold;

  font-size: 16px;
  line-height: 22px;
`;

type HeadingLevels = 1 | 2 | 3 | 4;

const getVariantMixin = (variant: HeadingLevels): FlattenSimpleInterpolation => {
  switch (variant) {
    case 1:
      return h1Mixin;
    case 2:
      return h2Mixin;
    case 3:
      return h3Mixin;
    case 4:
      return h4Mixin;
  }
};

const H1 = styled.h1<{ center: boolean; variant?: HeadingLevels }>`
  color: ${(props) => props.color || props.theme.colors.grey[100]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  ${baseHeaderMixin}
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h1Mixin)};
`;

const H2 = styled.h2<{ center: boolean; variant?: HeadingLevels }>`
  color: ${(props) => props.color || props.theme.colors.grey[100]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  ${baseHeaderMixin}
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h2Mixin)};
`;

const H3 = styled.h3<{ center: boolean; variant?: HeadingLevels }>`
  color: ${(props) => props.color || props.theme.colors.grey[100]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  ${baseHeaderMixin}
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h3Mixin)};
`;

const H4 = styled.h4<{ center: boolean; variant?: HeadingLevels }>`
  color: ${(props) => props.color || props.theme.colors.grey[100]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  ${baseHeaderMixin}
  ${(props) => (props.variant ? getVariantMixin(props.variant) : h4Mixin)};
`;

type TitleProps = {
  id?: string;
  level?: HeadingLevels;
  center?: boolean;
  children?: ReactNode;
  color?: string;
  variant?: HeadingLevels;
} & Attributes;

export function Title(props: TitleProps): ReactElement {
  const { level, center = false, children, color, ...rest } = props;

  let Component = H1;

  switch (level) {
    case 1:
      Component = H1;
      break;
    case 2:
      Component = H2;
      break;
    case 3:
      Component = H3;
      break;
    case 4:
      Component = H4;
      break;
  }

  return (
    <Component center={center} color={color} {...rest}>
      {children}
    </Component>
  );
}

type TextVariant = 'copy' | 'small-copy' | 'small-body' | 'legal' | 'big-number' | 'button' | 'title' | 'smaller-body';
type TextProps = {
  noSpacing?: boolean;
  variant?: TextVariant;
  children: ReactNode;
  grey?: keyof FullColorGradient | 'inherit';
  component?: 'p' | 'span' | 'div';
  color?: string;
  className?: string;
  align?: string;
  weight?: number;
};

const smallBody = css`
  font-size: 13px;
  line-height: 18px;
`;

const smallerBody = css`
  font-size: 12px;
  line-height: 14px;
`;

const legal = css`
  font-size: 13px;
  line-height: 16px;
`;

const smallCopy = css`
  font-size: 14px;
  line-height: 18px;
`;

const copy = css`
  font-size: 16px;
  line-height: 22px;
`;

const bigNumber = css`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: 0.8;
`;

const button = css`
  font-size: 16px;
  line-height: 1.25em;
  font-weight: bold;
`;

const title = css`
  font-size: 16px;
  line-height: 1.25em;
  font-weight: 600;
`;

const textStylingVariantMap: { [variant in TextVariant]: FlattenSimpleInterpolation } = {
  ['copy']: copy,
  ['small-copy']: smallCopy,
  ['small-body']: smallBody,
  ['smaller-body']: smallerBody,
  ['legal']: legal,
  ['big-number']: bigNumber,
  ['button']: button,
  ['title']: title,
};

type StyledTextProps = Omit<TextProps, 'variant'> & { textStyling: FlattenSimpleInterpolation };

export const StyledText = styled.p<StyledTextProps>`
  ${({ noSpacing }) => noSpacing && 'margin: 0'};
  color: ${(props) => props.theme.colors?.grey?.[90]};
  letter-spacing: 0.2px;
  text-align: ${(props) => props.align};
  font-weight: ${(props) => props.weight};

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  ol {
    white-space: pre-line;
  }

  ${(props) => props.textStyling}
  ${(props) =>
    !_.isUndefined(props.grey) &&
    css`
      color: ${props.grey === 'inherit' ? 'inherit' : props.theme.colors?.grey?.[props.grey]};
    `}
`;

export function Text(props: TextProps): ReactElement | null {
  const { noSpacing, variant, component, color, className, align = 'left', weight = 400 } = props;
  const textStyling = textStylingVariantMap[variant ?? 'copy'];

  return (
    <StyledText
      className={className}
      textStyling={textStyling}
      noSpacing={noSpacing}
      grey={props.grey}
      as={component}
      color={color}
      align={align}
      weight={weight}
    >
      {props.children}
    </StyledText>
  );
}

const ColoredBulletLi = styled.li`
  color: ${(props) => props.theme.colors.primary.default};
`;

export function BulletedText(props: { children: ReactNode }): ReactElement {
  const { children } = props;
  return (
    <Box component='ul' my={1} pl={2}>
      <ColoredBulletLi>{children}</ColoredBulletLi>
    </Box>
  );
}

export const LegalText = styled.p<{ center?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors?.grey?.[60]};
  letter-spacing: 0.3;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RunningText = styled.p<{ center?: boolean }>`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #757575;
  margin: 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  white-space: pre-wrap;
`;

export const Label = styled.label<Pick<TextProps, 'grey'>>`
  font-size: ${(props) => props.theme.fonts.size.input};
  line-height: 18px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  display: inline-block;

  p {
    margin-bottom: 8px;
  }

  ul,
  ol {
    margin-left: 0;
    padding-left: 24px;

    li {
      margin-bottom: 8px;
    }
  }

  ul {
    list-style: disc;
  }
`;

/* Dyanmic PDF Typography */

export const PdfDocumentTitle = styled.p`
  font-family: ${fallbackFontFamilies};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 2px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: anywhere;
`;

export const PdfQuestionnaireSectionHeader = styled.p`
  font-family: ${fallbackFontFamilies};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: anywhere;
`;

export const PdfSubsectionHeader = styled.p`
  font-family: ${fallbackFontFamilies};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: anywhere;
`;

export const PdfFieldGroupHeader = styled.p`
  font-family: ${fallbackFontFamilies};
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: anywhere;
`;

export const PdfFieldText = styled.p`
  font-family: ${fallbackFontFamilies};
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  padding-bottom: 2px;
  margin-top: 0px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: anywhere;
`;

const bulletPdfValueText = css`
  display: list-item;
  margin-left: 1.5em;
`;

export const PdfValueText = styled.p<{ weight?: number; isSignature: boolean; isOptionGroup?: boolean }>`
  font-family: ${fallbackFontFamilies};
  font-size: ${(props) =>
    props.isSignature
      ? '5px'
      : '10px'}; // Smaller font size is required to prevent OneSpan text tags from being cut off
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  line-height: 9px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: ${(props) =>
    props.isSignature ? 'normal' : 'anywhere'}; // OneSpan text tags are required to be on a single line
  ${({ isOptionGroup }) => {
    return isOptionGroup ? bulletPdfValueText : '';
  }}
`;

export const PdfLabelText = styled.p<{ isSignature?: boolean; alignLabelWithValue?: boolean }>`
  font-family: ${fallbackFontFamilies};
  font-size: ${(props) => (props.isSignature ? '10px' : '8px')};
  font-weight: 400;
  line-height: 8px;
  padding-left: ${(props) => (props.alignLabelWithValue ? '8px' : '0px')};
  padding-bottom: 2px;
  color: ${(props) => props.theme.colors?.grey?.[90]};
  overflow-wrap: anywhere;
  white-space: ${(props) => (props.isSignature ? 'nowrap' : 'normal')};
`;
