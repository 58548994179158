import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Morning(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='16' viewBox='0 0 21 16' width='21' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m16.0324 10.4648c0 1.6875-.7679 3.1957-1.9733 4.1941-.942.7804-2.1513 0-3.4702 0-1.3188 0-2.5281.7804-3.47015 0-1.20538-.9984-1.97324-2.5066-1.97324-4.1941 0-3.00627 2.4371-5.44338 5.44339-5.44338 3.0064 0 5.4435 2.43711 5.4435 5.44338z'
        fill='#f6f6f6'
      />
      <g clipRule='evenodd' fill={props.color?.primary || theme.colors.icons.primary} fillRule='evenodd'>
        <path d='m.722656 14.8876c0-.3382.274175-.6124.612384-.6124h18.55706c.3382 0 .6124.2742.6124.6124s-.2742.6124-.6124.6124h-18.55706c-.338209 0-.612384-.2742-.612384-.6124z' />
        <path d='m10.6136.666672c.3382 0 .6124.274174.6124.612388v1.23714c0 .33821-.2742.61238-.6124.61238s-.6124-.27417-.6124-.61238v-1.23714c0-.338214.2742-.612388.6124-.612388z' />
        <path d='m17.6077 3.56388c.239.23926.2388.627-.0004.86604l-.8751.87424c-.2392.23904-.627.23886-.866-.00041-.2391-.23926-.2389-.627.0004-.86604l.875-.87424c.2393-.23904.6271-.23886.8661.00041z' />
        <path d='m18.0425 10.5576c0-.3382.2742-.61241.6124-.61241h1.2371c.3382 0 .6124.27421.6124.61241s-.2742.6124-.6124.6124h-1.2371c-.3382 0-.6124-.2742-.6124-.6124z' />
        <path d='m.722656 10.5576c0-.3382.274175-.61241.612384-.61241h1.23714c.33821 0 .61238.27421.61238.61241s-.27417.6124-.61238.6124h-1.23714c-.338209 0-.612384-.2742-.612384-.6124z' />
        <path d='m3.61935 3.56388c.23903-.23927.62677-.23945.86604-.00041l.87507.87424c.23926.23904.23944.62678.00041.86604-.23904.23927-.62678.23945-.86605.00041l-.87507-.87424c-.23926-.23904-.23944-.62678-.0004-.86604z' />
        <path d='m7.7567 5.01642c.88207-.45989 1.86213-.70004 2.8569-.70004.9947 0 1.9748.24015 2.8569.70004.882.45989 1.64 1.12593 2.2096 1.94152.5695.81558.9337 1.75661 1.0617 2.74311.1279.98645.0158 1.98925-.3268 2.92315-.1165.3176-.4683.4805-.7858.364-.3176-.1165-.4805-.4683-.364-.7858.2747-.7488.3645-1.5528.262-2.34382-.1026-.79097-.3947-1.54549-.8513-2.19943-.4567-.65394-1.0644-1.18797-1.7717-1.55671-.7072-.36874-1.493-.56129-2.2906-.56129-.79761 0-1.58342.19255-2.29066.56129-.70725.36874-1.31502.90277-1.77166 1.55671-.45665.65394-.74868 1.40846-.85127 2.19943-.10259.79102-.01271 1.59502.262 2.34382.11649.3175-.04648.6693-.364.7858-.31751.1165-.66935-.0464-.78583-.364-.34262-.9339-.45472-1.9367-.32677-2.92315.12795-.9865.49217-1.92753 1.0617-2.74311.56952-.81559 1.32753-1.48163 2.20959-1.94152z' />
      </g>
    </svg>
  );
}
