import { ReactElement, createElement } from 'react';
import styled from 'styled-components';

import { IconName } from '@breathelife/types';

import { iconMap, SVGIconProps } from './IconMap';

const Container = styled.div<{ size?: string }>`
  display: inline-block;
  font-size: 0;
  line-height: 0;
  svg {
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }
`;

export type IconProps = SVGIconProps & {
  name: IconName;
  size?: string;
};

export function Icon({ size = '40px', name, color }: IconProps): ReactElement | null {
  const svg = iconMap[name];
  if (!svg) {
    return null;
  }

  return <Container size={size}>{createElement(svg, { color })}</Container>;
}
