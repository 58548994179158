import { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

const AsideStyled = styled.div<{ align: string }>`
  display: flex;
  flex-direction: row;
  max-width: 100%;

  & > * {
    display: flex;
    flex-direction: column;

    justify-content: ${(props) => props.align};
  }

  & > *:first-child {
    flex: none;
    margin-right: 10px;
  }

  & > *:nth-child(3) {
    flex: none;
    margin-left: auto;
  }
`;

type AsideProps = {
  children: ReactNode;
  align?: 'flex-start' | 'flex-end' | 'center';
};

export function Aside({ children, align = 'flex-start' }: AsideProps): ReactElement {
  return <AsideStyled align={align}>{children}</AsideStyled>;
}
