import { ReactElement, MouseEvent, useState, Fragment } from 'react';

import { MoreVertIcon } from '@breathelife/mui';

import { Link, StyledHeaderButton, StyledMenu, StyledMenuItem } from './Styles';

export enum MenuItemTypes {
  link = 'link',
  button = 'button',
}

type MenuItemProps = {
  text: string;
  itemType: MenuItemTypes;
  href?: string;
  onClick?: () => void;
};

type Props = {
  items: MenuItemProps[];
};

function buildMenuItem(itemProps: MenuItemProps, key: number): ReactElement | null {
  switch (itemProps.itemType) {
    case MenuItemTypes.link:
      return (
        <Link key={key} href={itemProps.href} target='_blank' rel='noopener noreferrer'>
          <StyledMenuItem>{itemProps.text}</StyledMenuItem>
        </Link>
      );
    case MenuItemTypes.button:
      return (
        <StyledMenuItem key={key} onClick={itemProps.onClick}>
          {itemProps.text}
        </StyledMenuItem>
      );
    default:
      return null;
  }
}

export function HeaderMenu(props: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Fragment>
      <StyledHeaderButton
        aria-haspopup='true'
        aria-label='Header Menu'
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
          setMenuOpen(true);
        }}
      >
        <MoreVertIcon />
      </StyledHeaderButton>
      <StyledMenu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{
          style: {
            boxShadow: 'none',
          },
        }}
      >
        {props.items.map(buildMenuItem)}
      </StyledMenu>
    </Fragment>
  );
}
