import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function FamilyIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M13.5 17.5C15.9853 17.5 18 15.4853 18 13C18 10.5147 15.9853 8.5 13.5 8.5C11.0147 8.5 9 10.5147 9 13C9 15.4853 11.0147 17.5 13.5 17.5Z'
        fill={secondaryColor}
      />
      <path
        d='M34.5 18C36.9853 18 39 15.9853 39 13.5C39 11.0147 36.9853 9 34.5 9C32.0147 9 30 11.0147 30 13.5C30 15.9853 32.0147 18 34.5 18Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.5107 9.55376C32.4159 9.55376 30.7177 11.2519 30.7177 13.3468C30.7177 15.4416 32.4159 17.1398 34.5107 17.1398C36.6055 17.1398 38.3037 15.4416 38.3037 13.3468C38.3037 11.2519 36.6055 9.55376 34.5107 9.55376ZM29.1639 13.3468C29.1639 10.3938 31.5578 8 34.5107 8C37.4636 8 39.8575 10.3938 39.8575 13.3468C39.8575 16.2997 37.4636 18.6935 34.5107 18.6935C31.5578 18.6935 29.1639 16.2997 29.1639 13.3468Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0537 21.2527C12.9408 19.4247 11.1131 20.8097 9.74189 22.1666C8.37066 23.5236 7.91394 26.1123 7.91394 28.0313V29.4785H10.6559L11.5698 38.1612H16.1397L16.2385 36.3333H31.5785L31.6773 38.1612H36.2472L37.1612 29.4785H39.9031V28.0313C39.9031 26.1123 39.4464 23.5236 38.0752 22.1666C36.704 20.8097 34.8763 19.4247 30.7634 21.2527L30.7881 21.7096H17.029L17.0537 21.2527Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.928 19.4509C35.1282 19.3427 36.3349 19.5709 37.4128 20.1098C38.4907 20.6488 39.3972 21.4772 40.0308 22.5024C40.6644 23.5275 41 24.7088 41 25.914V29.3414C41 29.7704 40.6522 30.1182 40.2231 30.1182H38.624L37.5666 38.5775C37.518 38.9663 37.1875 39.258 36.7957 39.258H32.2258C31.8341 39.258 31.5037 38.9664 31.4549 38.5778L31.1686 36.2928C31.1152 35.8671 31.4171 35.4787 31.8428 35.4253C32.2685 35.372 32.6569 35.6739 32.7103 36.0996L32.9114 37.7043H36.1099L37.1673 29.245C37.2159 28.8562 37.5464 28.5645 37.9382 28.5645H39.4462V25.914C39.4462 24.9974 39.191 24.0989 38.7091 23.3192C38.2272 22.5395 37.5378 21.9094 36.7179 21.4995C35.8981 21.0896 34.9804 20.9161 34.0675 20.9984C33.1546 21.0807 32.2827 21.4156 31.5495 21.9656C31.2062 22.223 30.7193 22.1534 30.4618 21.8102C30.2044 21.4669 30.2739 20.98 30.6172 20.7226C31.5813 19.9995 32.7277 19.5592 33.928 19.4509Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4892 9.55376C11.3944 9.55376 9.69622 11.2519 9.69622 13.3468C9.69622 15.4416 11.3944 17.1398 13.4892 17.1398C15.584 17.1398 17.2822 15.4416 17.2822 13.3468C17.2822 11.2519 15.584 9.55376 13.4892 9.55376ZM8.14246 13.3468C8.14246 10.3938 10.5363 8 13.4892 8C16.4422 8 18.836 10.3938 18.836 13.3468C18.836 16.2997 16.4422 18.6935 13.4892 18.6935C10.5363 18.6935 8.14246 16.2997 8.14246 13.3468Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9325 20.9984C13.0196 20.9161 12.1018 21.0896 11.282 21.4995C10.4622 21.9094 9.77274 22.5395 9.29087 23.3192C8.809 24.0989 8.55376 24.9974 8.55376 25.914V28.5645H10.0618C10.4536 28.5645 10.7841 28.8562 10.8327 29.245L11.8901 37.7043H15.0886L15.2897 36.0996C15.3431 35.6739 15.7314 35.372 16.1572 35.4253C16.5829 35.4787 16.8848 35.8671 16.8314 36.2928L16.545 38.5778C16.4963 38.9664 16.1659 39.258 15.7742 39.258H11.2043C10.8125 39.258 10.482 38.9663 10.4334 38.5775L9.37601 30.1182H7.77688C7.34782 30.1182 7 29.7704 7 29.3414V25.914C7 24.7088 7.33559 23.5275 7.96916 22.5024C8.60274 21.4772 9.50926 20.6488 10.5872 20.1098C11.6651 19.5709 12.8717 19.3427 14.072 19.4509C15.2723 19.5592 16.4187 19.9995 17.3828 20.7226C17.726 20.98 17.7956 21.4669 17.5382 21.8102C17.2807 22.1534 16.7938 22.223 16.4505 21.9656C15.7173 21.4156 14.8453 21.0807 13.9325 20.9984Z'
        fill={primaryColor}
      />
      <path
        d='M23.9999 22.4865C25.8928 22.4865 27.4273 20.952 27.4273 19.0591C27.4273 17.1662 25.8928 15.6317 23.9999 15.6317C22.107 15.6317 20.5725 17.1662 20.5725 19.0591C20.5725 20.952 22.107 22.4865 23.9999 22.4865Z'
        fill='#F8F9FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.9999 16.4086C22.5361 16.4086 21.3494 17.5953 21.3494 19.0591C21.3494 20.523 22.5361 21.7097 23.9999 21.7097C25.4638 21.7097 26.6505 20.523 26.6505 19.0591C26.6505 17.5953 25.4638 16.4086 23.9999 16.4086ZM19.7957 19.0591C19.7957 16.7372 21.678 14.8548 23.9999 14.8548C26.3219 14.8548 28.2042 16.7372 28.2042 19.0591C28.2042 21.3811 26.3219 23.2634 23.9999 23.2634C21.678 23.2634 19.7957 21.3811 19.7957 19.0591Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.9607 26.4446C21.032 25.3733 22.4849 24.7715 24 24.7715V38.4811H21.715L20.5725 31.6263H18.2876V30.4838C18.2876 28.9688 18.8894 27.5159 19.9607 26.4446ZM28.0392 26.4446C26.9679 25.3733 25.515 24.7715 24 24.7715V38.4811H26.2849L27.4274 31.6263H29.7123V30.4838C29.7123 28.9688 29.1105 27.5159 28.0392 26.4446Z'
        fill='#F8F9FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 23.9946C22.2789 23.9946 20.6284 24.6783 19.4114 25.8953C18.1944 27.1122 17.5107 28.7628 17.5107 30.4838V31.6263C17.5107 32.0554 17.8586 32.4032 18.2876 32.4032H19.9144L20.9487 38.6089C21.0112 38.9835 21.3353 39.258 21.715 39.258H23.9975C23.9983 39.258 23.9992 39.258 24 39.258H26.2849C26.6647 39.258 26.9888 38.9835 27.0512 38.6089L28.0855 32.4032H29.7123C30.1414 32.4032 30.4892 32.0554 30.4892 31.6263V30.4838C30.4892 28.7628 29.8055 27.1122 28.5886 25.8953C27.3722 24.6789 25.7227 23.9953 24.0025 23.9946C24.0017 23.9946 24.0008 23.9946 24 23.9946ZM23.9978 25.5484C22.6896 25.5489 21.4351 26.0689 20.5101 26.9939C19.5845 27.9195 19.0645 29.1749 19.0645 30.4838V30.8494H20.5726C20.9523 30.8494 21.2764 31.124 21.3389 31.4986L22.3732 37.7043H24C24.0007 37.7043 24.0014 37.7043 24.0021 37.7043H25.6268L26.6611 31.4986C26.7235 31.124 27.0476 30.8494 27.4274 30.8494H28.9355V30.4838C28.9355 29.1749 28.4155 27.9195 27.4899 26.9939C26.5643 26.0683 25.3089 25.5484 24 25.5484C23.9992 25.5484 23.9985 25.5484 23.9978 25.5484Z'
        fill={primaryColor}
      />
    </svg>
  );
}
