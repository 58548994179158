import { ReactElement } from 'react';

export function CircledApplicationIcon(): ReactElement {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10' cy='10' r='9.5' stroke='#757575' />
      <path
        d='M13.9968 6.71876C14.0788 6.80079 14.125 6.91205 14.125 7.02807L14.125 14.4385C14.125 14.5545 14.0789 14.6658 13.9968 14.7479C13.9148 14.8299 13.8035 14.876 13.6875 14.876L6.30743 14.876C6.1914 14.876 6.08012 14.8299 5.99807 14.7479C5.91603 14.6658 5.86993 14.5546 5.86993 14.4385L5.86995 5.5625C5.86995 5.44646 5.91604 5.33518 5.99809 5.25314C6.08014 5.17109 6.19142 5.125 6.30745 5.125L12.2188 5.125C12.3348 5.12502 12.4461 5.17113 12.5281 5.25319L13.9968 6.71876Z'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.75 8.125H12.25' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.75 10.375H12.25' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.75 12.625H12.25' stroke='#757575' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
}
