import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Triangle(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.25 3.56699C7.58333 3.75944 7.58333 4.24056 7.25 4.43301L1.25 7.89711C0.916667 8.08956 0.5 7.849 0.5 7.4641L0.5 0.535898C0.5 0.150998 0.916666 -0.0895649 1.25 0.102885L7.25 3.56699Z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
