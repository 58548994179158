import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type BoxProps = {
  p?: number;
  pl?: number;
  pr?: number;
  pb?: number;
  pt?: number;

  m?: number;
  ml?: number;
  mr?: number;
  mb?: number;
  mt?: number;

  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;

  display?: string;
  flex?: boolean;
  flexDirection?: string;
  flexGrow?: number;
  justifyContent?: string;
  alignItems?: string;
  alignSelf?: string;

  position?: string;
  top?: number;
  left?: number;

  borderBottom?: string;
};

function convertUnitToPx(unit: number): number {
  // TODO: should use the 8px value from the default theme.
  return unit * 8;
}

const sharedBoxCss = (props: BoxProps): FlattenSimpleInterpolation => css`
  // TODO: support string to have em, rem
  // TODO: support px and py??
  ${props.p && `padding: ${convertUnitToPx(props.p)}px;`}
  ${props.pl && `padding-left: ${convertUnitToPx(props.pl)}px;`}
  ${props.pr && `padding-right: ${convertUnitToPx(props.pr)}px;`}
  ${props.pt && `padding-top: ${convertUnitToPx(props.pt)}px;`}
  ${props.pb && `padding-bottom: ${convertUnitToPx(props.pb)}px;`}

  // TODO: support string to have em, rem
  // TODO: support mx and my??
  ${props.m && `margin: ${convertUnitToPx(props.m)}px;`}
  ${props.ml && `margin-left: ${convertUnitToPx(props.ml)}px;`}
  ${props.mr && `margin-right: ${convertUnitToPx(props.mr)}px;`}
  ${props.mt && `margin-top: ${convertUnitToPx(props.mt)}px;`}
  ${props.mb && `margin-bottom: ${convertUnitToPx(props.mb)}px;`}

  ${props.width && `width: ${props.width};`}
  ${props.height && `height: ${props.height};`}
  ${props.maxWidth && `max-width: ${props.maxWidth};`}
  ${props.maxHeight && `max-height: ${props.maxHeight};`}

  ${props.display && `display: ${props.display};`}
  ${props.flex && `flex: ${props.flex};`}
  ${props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props.flexGrow && `flex-grow: ${props.flexGrow}`}
  ${props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props.alignItems && `align-items: ${props.alignItems};`}
  ${props.alignSelf && `align-self: ${props.alignSelf};`}

  ${props.position && `position: ${props.position};`}
  // TODO: support string to have em, rem, px
  ${props.top && `top: ${convertUnitToPx(props.top)}px;`}
  ${props.left && `left: ${convertUnitToPx(props.left)}px;`}

  ${props.borderBottom && `border-bottom: ${props.borderBottom};`}
`;

export const Box = styled.div<BoxProps>`
  ${sharedBoxCss}
`;

export const BoxSpan = styled.span<BoxProps>`
  ${sharedBoxCss}
`;

export const BoxH3 = styled.h3<BoxProps>`
  ${sharedBoxCss}
`;
