import { ReactNode, ReactElement, useState } from 'react';

import { SelectableCardContainerStyled, SelectableCardStyled, SelectableCardLabel, HiddenInput } from './Styles';

export type SelectableCardProps = {
  children: ReactNode;
  optionId: string;
  isChecked: boolean;
  groupName: string;
  ariaLabel: string;
  testId?: string;
  onSelect: (optionId: string) => void;
};

export function SelectableCard(props: SelectableCardProps): ReactElement {
  const [isFocused, setIsFocused] = useState(false);
  const classes = [];
  if (props.isChecked) classes.push('selectable-card--checked');
  if (isFocused) classes.push('selectable-card--focused');

  return (
    <SelectableCardLabel
      htmlFor={`selectable-${props.groupName}-${props.optionId}`}
      aria-checked={props.isChecked}
      data-testid={props.testId}
    >
      <SelectableCardStyled className={classes.join(' ')} checked={props.isChecked}>
        <div>{props.children}</div>
        <HiddenInput
          type='radio'
          id={`selectable-${props.groupName}-${props.optionId}`}
          value={props.optionId}
          aria-label={props.ariaLabel}
          name={props.groupName}
          checked={props.isChecked}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={() => props.onSelect(props.optionId)}
        />
      </SelectableCardStyled>
    </SelectableCardLabel>
  );
}

export const SelectableCardContainer = SelectableCardContainerStyled;
