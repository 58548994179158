import { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

const duration = '1.4s';
const offset = 250;

export type LoaderProps = {
  color?: string;
  size?: string;
};

type Props = {
  children?: ReactNode;
} & LoaderProps;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Svg = styled.svg`
  animation: rotator ${duration} linear infinite both;
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  stroke: ${(props) => (props.color ? props.color : props.theme.colors.primary.default)};
  transform-origin: center;
  animation: dash ${duration} ease-in-out infinite both;
  @keyframes dash {
    0% {
      stroke-dashoffset: ${offset};
      transform: rotate(0deg);
    }
    50% {
      stroke-dashoffset: ${offset / 4};
      transform: rotate(50deg);
    }
    100% {
      stroke-dashoffset: ${offset};
      transform: rotate(360deg);
    }
  }
`;

export function Loader({ size = '65px', ...props }: Props): ReactElement {
  return (
    <Container>
      <Svg width={size} height={size} viewBox='0 0 100 100'>
        <Circle color={props.color} fill='none' strokeWidth='4' strokeLinecap='round' cx='50' cy='50' r='40' />
      </Svg>
      {props.children}
    </Container>
  );
}
