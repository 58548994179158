import styled from 'styled-components';

export const DesktopHeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 2px solid ${(props) => props.theme.colors.background.secondary};
  z-index: 1001;
  background-color: ${(props) => props.theme.colors.background.primary};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: unset;
  padding: 25px 20px 15px 20px;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin-top: 2px;
    padding: 0 35px;
    height: 100%;
  }
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    bottom: initial;
    top: 0;
  }
`;

export const ChildrenContainer = styled.div`
  margin-left: auto;
`;

export const ProgressText = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.base.primary};
  font-size: 13px;
  font-weight: 700;
`;

export const LogoContainer = styled.div`
  flex: 1;

  img {
    height: ${(props) => props.theme.metrics.logo.mobile.height};
  }
`;

export const TitleContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.separator};
  padding-top: 12px;
`;

export const SectionTitle = styled.p`
  font-size: ${(props) => props.theme.fonts.size.medium};
  font-family: ${(props) => props.theme.fonts.family.title} !important;
  font-weight: ${(props) => props.theme.fonts.weight.title};
  color: ${(props) => props.theme.colors.text.title};
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 20px;
`;

export const MobileHeaderContainer = styled.div<{ $height: string }>`
  background: ${(props) => props.theme.colors.background.mobileHeader};
  z-index: 999;
  position: relative;
  width: 100%;
  height: ${(props) => props.$height};
`;

export const MinimalMobileHeaderContainer = styled.div`
  z-index: 1001;
  height: 90px;
  position: relative;
  width: 100%;
`;

export const InfoViewContainer = styled.div`
  background: ${(props) => props.theme.colors.background.mobileHeaderBottom};
  position: relative;
  width: 100%;
  height: auto;
  min-height: 3px;
`;
