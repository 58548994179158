import { ReactElement, Fragment, PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';

import { IconName } from '@breathelife/types';

import { SharedTheme } from '../../Theme';
import { Icon } from '../Icon/Icon';
import { Text } from '../Typography';

const DEFAULT_TICK_WIDTH = 8;
const DEFAULT_TRIANGLE_WIDTH = 9;
const DEFAULT_TRIANGLE_PADDING = 2;
const DEFAULT_LINE_THICKNESS = 1;
const DEFAULT_ICON_WIDTH = 30;

export type LevelStyleData = {
  isLast: boolean;
};

export type TreeViewItemStyle = {
  height: number;
  levelStyles: LevelStyleData[];
  iconWidth?: number;
  tickWidth?: number;
  triangleWidth?: number;
  trianglePadding?: number;
  bulletIconColor?: string;
};

export type FormattedTreeViewItemData = {
  label: string;
  identifier: string;
  level: number;
  style: TreeViewItemStyle;
  iconName?: IconName;
  iconWidth?: number;
  hasChildren?: boolean;
  isExpanded?: boolean;
  isForceExpanded?: boolean;
  previousInListIsExpanded?: boolean;
  path: string[];
  pathIdentifier: string;
  metadata: unknown;
};

type TreeViewItemProps = PropsWithChildren<FormattedTreeViewItemData> & {
  onToggleExpansion?: (pathIdentifier: string) => void;
  hideTicks?: boolean;
  isFullItemClickable?: boolean;
};

const Container = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ToggleExpandedButton = styled.button<{ expanded: boolean; clickable: boolean; trianglePadding: string }>`
  display: inline-block;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  padding: ${(props) => props.trianglePadding};
  border: none;
  background: none;
  transition: 0.25s;
  transform: rotate(${(props) => (props.expanded ? '90deg' : '0')});
  font-size: 0;
`;
const ItemContainer = styled.div<{ isFullItemClickable?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 0;
  flex-grow: 1;
  cursor: ${(isFullItemClickable) => (isFullItemClickable ? 'pointer' : 'default')};
`;

const InfoContainerWrapper = styled.div`
  display: flex;
  padding-left: 1px;
  align-items: center;
  min-width: 0;
  align-self: stretch;
  flex-grow: 1;
`;

const ContentLevelTickAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

const VerticalHalfTick = styled.div<{ show?: boolean }>`
  flex-grow: 1;
  width: ${DEFAULT_LINE_THICKNESS}px;
  background-color: ${({ theme }) => theme.colors.grey[40]};
  visibility: ${({ show }) => (!show ? 'hidden' : 'visible')};
`;

const PreviousLevelsTickWrapper = styled.div<{
  currentLevelIsHorizontalTickLevel: boolean;
  tickWidth: string;
  triangleWidth: string;
  trianglePadding: string;
}>`
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: ${({ tickWidth, triangleWidth, trianglePadding, currentLevelIsHorizontalTickLevel }) =>
    `calc(${
      currentLevelIsHorizontalTickLevel ? '0px' : tickWidth
    } + ${trianglePadding} + (${triangleWidth}/2) + ${DEFAULT_LINE_THICKNESS}px)`};
  padding-left: ${({ triangleWidth, trianglePadding }) =>
    `calc(${trianglePadding} + (${triangleWidth} / 2) - ${DEFAULT_LINE_THICKNESS / 2}px)`};
`;

const PreviousLevelsVerticalTicksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HorizontalTick = styled.div<{ tickWidth: string; show?: boolean }>`
  width: ${({ tickWidth }) => tickWidth};
  height: ${DEFAULT_LINE_THICKNESS}px;
  background-color: ${({ theme }) => theme.colors.grey[40]};
  visibility: ${({ show }) => (!show ? 'hidden' : 'visible')};
`;

const BulletWrapper = styled.div`
  padding: 0 2px;
`;

/*
  TreeView does not consist of nested list, but rather of one flat list with items.
  Each item displays a certain arrangement of ticks and shapes to make it look like it is nested.
  The arrangement of ticks is determined by the nesting level of each item, passed in props
*/

export function TreeViewItem({
  style,
  level,
  pathIdentifier,
  hideTicks,
  hasChildren,
  onToggleExpansion,
  isExpanded,
  isForceExpanded,
  iconName,
  label,
  children,
  isFullItemClickable,
}: TreeViewItemProps): ReactElement {
  const triangleWidth = `${style.triangleWidth || DEFAULT_TRIANGLE_WIDTH}px`;
  const tickWidth = `${style.tickWidth || DEFAULT_TICK_WIDTH}px`;
  const trianglePadding = `${style.trianglePadding || DEFAULT_TRIANGLE_PADDING}px`;
  const iconWidth = `${style.iconWidth || DEFAULT_ICON_WIDTH}px`;

  const theme = useTheme() as SharedTheme;
  // We iterate over each level to display either a certain s\hape of ticks, or the item content

  return (
    <Container height={style.height}>
      {Array.from({ length: level + 1 }, (_, i) => {
        const key = `${pathIdentifier}-${i}`;
        const currentLevelIsContentLevel = i === level;
        const currentLevelIsHorizontalTickLevel = i === level - 1;
        const currentLevelStyle = style.levelStyles[i + 1];
        if (currentLevelIsContentLevel) {
          return (
            <ItemContainer
              onClick={() => {
                if (!onToggleExpansion || isForceExpanded || !isFullItemClickable) {
                  return;
                }
                onToggleExpansion(pathIdentifier);
              }}
              key={key}
            >
              <ContentLevelTickAndIconWrapper>
                <VerticalHalfTick show={false} />

                {hasChildren ? (
                  <ToggleExpandedButton
                    trianglePadding={trianglePadding}
                    expanded={Boolean(isExpanded)}
                    clickable={Boolean(hasChildren) && !isForceExpanded}
                    onClick={() => {
                      if (!onToggleExpansion || isForceExpanded) {
                        return;
                      }
                      onToggleExpansion(pathIdentifier);
                    }}
                  >
                    <Icon
                      name={IconName.triangle}
                      size={triangleWidth}
                      color={{ primary: theme.colors.grey[isForceExpanded ? 40 : 60] }}
                    />
                  </ToggleExpandedButton>
                ) : (
                  <BulletWrapper>
                    <Icon
                      name={IconName.bullet}
                      size={triangleWidth}
                      color={{ primary: style.bulletIconColor || theme.colors.success }}
                    />
                  </BulletWrapper>
                )}

                <VerticalHalfTick show={hasChildren && isExpanded && !hideTicks} />
              </ContentLevelTickAndIconWrapper>

              <InfoContainerWrapper>
                {children || (
                  <Fragment>
                    {iconName && <Icon name={iconName} size={iconWidth} />}
                    {label && <Text noSpacing>{label}</Text>}
                  </Fragment>
                )}
              </InfoContainerWrapper>
            </ItemContainer>
          );
        }

        // if !currentLevelIsContentLevel, display arrangement of ticks
        return (
          <PreviousLevelsTickWrapper
            {...{ tickWidth, trianglePadding, triangleWidth, currentLevelIsHorizontalTickLevel }}
            key={key}
          >
            <PreviousLevelsVerticalTicksWrapper>
              <VerticalHalfTick
                show={(!currentLevelStyle?.isLast || currentLevelIsHorizontalTickLevel) && !hideTicks}
              />
              <VerticalHalfTick show={!currentLevelStyle?.isLast && !hideTicks} />
            </PreviousLevelsVerticalTicksWrapper>
            {currentLevelIsHorizontalTickLevel && <HorizontalTick tickWidth={tickWidth} show={!hideTicks} />}
          </PreviousLevelsTickWrapper>
        );
      })}
    </Container>
  );
}
