import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function HealthIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path d='M8.17493 22.5925H39.3443L31.1923 31.7035H16.8064L8.17493 22.5925Z' fill={secondaryColor} />
      <path
        d='M31.2397 31.5832L24.7776 38.3244C24.677 38.4294 24.5562 38.513 24.4224 38.5701C24.2887 38.6273 24.1447 38.6567 23.9993 38.6567C23.8538 38.6567 23.7099 38.6273 23.5762 38.5701C23.4424 38.513 23.3216 38.4294 23.221 38.3244L16.7574 31.5817'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1935 30.9933C16.5185 30.6818 17.0345 30.6927 17.3461 31.0177L23.8096 37.7603C23.8341 37.7859 23.8639 37.8066 23.8964 37.8205C23.929 37.8344 23.964 37.8416 23.9994 37.8416C24.0348 37.8416 24.0699 37.8344 24.1024 37.8205C24.135 37.8066 24.1644 37.7862 24.1889 37.7607L30.6513 31.0191C30.9629 30.6941 31.4789 30.6832 31.8039 30.9948C32.129 31.3063 32.1399 31.8223 31.8283 32.1474L25.3665 38.8882C25.1898 39.0728 24.9776 39.2196 24.7427 39.3199C24.5077 39.4203 24.2549 39.472 23.9994 39.472C23.7439 39.472 23.4911 39.4203 23.2562 39.3199C23.0212 39.2196 22.809 39.0728 22.6323 38.8882L16.1691 32.1459C15.8575 31.8209 15.8684 31.3049 16.1935 30.9933Z'
        fill={primaryColor}
      />
      <path
        d='M8.85233 22.9573C8.20466 21.7967 7.85013 20.4956 7.81955 19.1668C7.78896 17.8381 8.08324 16.522 8.67682 15.3328C9.25522 14.1764 10.1001 13.1739 11.1419 12.408C12.1837 11.6421 13.3926 11.1347 14.6689 10.9277C15.9452 10.7206 17.2525 10.8197 18.483 11.217C19.7135 11.6142 20.832 12.2981 21.7464 13.2123L23.9992 15.458L26.252 13.2052C27.1664 12.2909 28.2849 11.607 29.5154 11.2098C30.7459 10.8125 32.0532 10.7134 33.3295 10.9205C34.6058 11.1275 35.8147 11.6349 36.8565 12.4008C37.8983 13.1667 38.7432 14.1692 39.3216 15.3256C39.9152 16.5146 40.2099 17.8305 40.18 19.1591C40.1502 20.4877 39.7968 21.789 39.1504 22.9501'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.1992 11.7252C32.0503 11.5388 30.8737 11.6281 29.7661 11.9856C28.6585 12.3432 27.6517 12.9588 26.8287 13.7817L24.5759 16.0345C24.2579 16.3525 23.7424 16.3529 23.4239 16.0354L21.1711 13.7898L21.1702 13.7889C20.3472 12.9659 19.3404 12.3503 18.2328 11.9928C17.1252 11.6353 15.9486 11.546 14.7997 11.7324C13.6509 11.9188 12.5628 12.3755 11.625 13.0649C10.6874 13.7542 9.92692 14.6565 9.4063 15.6973C8.8721 16.7676 8.60726 17.9522 8.63478 19.1481C8.66232 20.3442 8.98144 21.5154 9.56444 22.5601C9.78384 22.9533 9.64299 23.4499 9.24984 23.6693C8.85669 23.8886 8.36013 23.7478 8.14073 23.3546C7.42839 22.0782 7.03846 20.6471 7.00482 19.1857C6.97118 17.7242 7.29484 16.2767 7.9477 14.9688L8.67707 15.3329L7.94799 14.9682C8.58415 13.6963 9.51345 12.5937 10.6593 11.7513C11.8052 10.9089 13.1348 10.3508 14.5386 10.1231C15.9424 9.89529 17.3803 10.0044 18.7337 10.4412C20.087 10.8781 21.3171 11.6302 22.3227 12.6357C22.3228 12.6358 22.3229 12.6359 22.323 12.636L23.9985 14.3061L25.6758 12.6288L25.6759 12.6288C26.6816 11.6232 27.9118 10.871 29.2652 10.4341C30.6186 9.99716 32.0565 9.8881 33.4603 10.1159C34.8641 10.3436 36.1937 10.9017 37.3396 11.7441C38.4855 12.5865 39.4147 13.6891 40.0509 14.961L39.3218 15.3257L40.0512 14.9616C40.704 16.2692 41.0281 17.7163 40.9953 19.1774C40.9625 20.6386 40.5738 22.0697 39.8629 23.3467C39.6439 23.7401 39.1475 23.8815 38.7541 23.6625C38.3607 23.4435 38.2194 22.9471 38.4384 22.5537C39.0203 21.5084 39.3385 20.3369 39.3653 19.1408C39.3922 17.945 39.127 16.7606 38.5927 15.6904M38.5925 15.6898C38.0719 14.6491 37.3115 13.747 36.3739 13.0577C35.4362 12.3683 34.348 11.9116 33.1992 11.7252'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5771 22.9875L18.4044 27.3341L18.4035 27.336C18.2905 27.5605 18.1174 27.7492 17.9034 27.8811C17.6895 28.0128 17.4431 28.0826 17.1919 28.0826C17.1918 28.0826 17.1917 28.0826 17.1916 28.0826H7.8152C7.36498 28.0826 7 27.7176 7 27.2674C7 26.8172 7.36498 26.4522 7.8152 26.4522H17.0225L19.4229 21.6501C19.5426 21.4054 19.7332 21.2025 19.97 21.0676C20.2087 20.9317 20.4831 20.8715 20.7568 20.895C21.0305 20.9184 21.2906 21.0245 21.5027 21.199C21.7132 21.3723 21.8666 21.6049 21.9429 21.8665L24.1394 29.1838L26.0955 24.6187L26.0964 24.6165C26.193 24.393 26.3478 24.1997 26.5448 24.0567C26.7418 23.9138 26.9736 23.8264 27.216 23.8039C27.4584 23.7814 27.7023 23.8246 27.9222 23.9288C28.1422 24.0331 28.33 24.1946 28.466 24.3965L28.4683 24.3998L29.8317 26.445H40.1848C40.635 26.445 41 26.81 41 27.2602C41 27.7104 40.635 28.0754 40.1848 28.0754H29.3954C29.1229 28.0754 28.8683 27.9392 28.7171 27.7124L27.394 25.7277L25.3355 30.5318L25.3282 30.5483C25.215 30.7972 25.0291 31.0061 24.795 31.1475C24.5609 31.2889 24.2895 31.3562 24.0165 31.3406C23.7435 31.325 23.4815 31.2272 23.2651 31.0601C23.0486 30.8929 22.8877 30.6643 22.8036 30.404L22.7984 30.3876L20.5771 22.9875Z'
        fill={primaryColor}
      />
    </svg>
  );
}
