/**
 * @decorative https://www.w3.org/WAI/tutorials/images/decorative/
 * @functional (Meaningful) https://www.w3.org/WAI/tutorials/images/functional/
 * @informative (Meaningful) https://www.w3.org/WAI/tutorials/images/informative/
 */
// export type ImageTypes = 'decorative' | 'meaningful';

export enum ImageTypes {
  decorative = 'decorative',
  meaningful = 'meaningful',
}

type DecorativeAttributes = {
  ['focusable']: boolean;
  ['aria-hidden']: boolean;
};

type MeaningfulAttributes = {
  ['aria-label']: string;
};

type ImageAttributes<T> = T extends ImageTypes.meaningful
  ? MeaningfulAttributes
  : T extends ImageTypes.decorative
  ? DecorativeAttributes
  : never;

const defaultImageAttributes = {
  decorative: {
    ['aria-hidden']: true,
    ['focusable']: false,
  },
  meaningful: {},
};

/**
 * @param {ImageTypes} imageType Type of image, can either be decorative or meaningful.
 * @param {Partial<ImageAttributes<S>>} additionalAttributes - Any additional attributes to merge into the default attributes.
 * @return HTML Attributes associated with a specific image type.
 * @description For accessibility purposes, certain image types should have default attributes.
 */
export function getHtmlAttributesForImageType<S extends ImageTypes>(options: {
  imageType: S;
  additionalAttributes?: Partial<ImageAttributes<S>>;
}): ImageAttributes<S> {
  const { imageType, additionalAttributes } = options;

  const imageAttributes = defaultImageAttributes[imageType] as ImageAttributes<S>;

  return Object.assign({}, imageAttributes, additionalAttributes);
}
