import styled from 'styled-components';

export const ContactInfoContainer = styled.div`
  margin-top: 10px;
  width: 300px;
  height: 60px;
  background-color: ${(props) => props.theme.colors.base.primary};
  color: white;
  display: flex;
  flex-direction: row;

  &:hover {
    background-color: ${(props) => props.theme.colors.base.activePrimary};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin-top: 15px;
    height: 75px;
  }
`;

export const ContactInfoIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.base.primary};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 15px rgba(9, 30, 66, 0.15);
  img {
    width: 12px;
    height: 12px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    height: 55px;
    width: 55px;

    img,
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

export const ContactInfoLeftSection = styled.div`
  display: block;
  padding: 10px;
  width: 60px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    width: 75px;
  }
`;

export const ContactInfoRightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
`;

export const ContactInfoHighlight = styled.span`
  font-size: 18px;
  line-height: 21px;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    max-width: 215px;
  }
`;
