import { ReactElement } from 'react';
import styled from 'styled-components';

export const TagContainer = styled.div<TagProps>`
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  color: ${(props) => props.textColor || 'black'};
  background-color: ${(props) => props.backgroundColor || 'white'};
  padding: 2px 12px;
  font-size: 11px;
  font-weight: 500;
  width: fit-content;
  line-height: 1.2em;
`;

export type TagProps = {
  label: string;
  textColor?: string;
  backgroundColor?: string;
};

export function Tag(props: TagProps): ReactElement {
  return (
    <TagContainer {...props}>
      <div>{props.label}</div>
    </TagContainer>
  );
}
