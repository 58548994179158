import _ from 'lodash';
import { ReactNode, ReactElement } from 'react';

import { LegalLink, LegalMenu } from '../LegalMenu/LegalMenu';
import { HeaderProgressBar } from './HeaderProgressBar';
import { ChildrenContainer, Content, DesktopHeaderContainer, ProgressText } from './TransactionFlowStyles';

type Props = {
  children: ReactNode;
  displayChildrenOnly?: boolean;
  progress?: number;
  progressText?: string;
  legalLinks: LegalLink[];
};

export function TransactionFlowDesktopHeader(props: Props): ReactElement {
  const displayChildrenOnly = props.displayChildrenOnly ?? false;

  if (displayChildrenOnly) {
    return <ChildrenContainer>{props.children}</ChildrenContainer>;
  }

  return (
    <DesktopHeaderContainer>
      {!_.isNil(props.progress) && <HeaderProgressBar progress={props.progress} />}
      <Content>
        {props.progressText && <ProgressText>{props.progressText}</ProgressText>}
        <ChildrenContainer>{props.children}</ChildrenContainer>
        <LegalMenu links={props.legalLinks} />
      </Content>
    </DesktopHeaderContainer>
  );
}
