import { ReactElement } from 'react';

import { FormControl, SelectProps } from '@breathelife/mui';
import { AutoComplete } from '../AutoComplete';
import { RenderingFieldOption } from '@breathelife/questionnaire-engine';

import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { WarningText } from './Styles/CommonStyles';

type Props = FieldProps &
  Omit<SelectProps, 'onAnswerChange'> & {
    options: RenderingFieldOption[];
    required?: boolean;
    value?: string;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (field: string, answer: string, previousAnswer?: string) => void;
    inputVariant?: InputVariant;
  };

const MUI_NONE = '__none__';

export function SearchableSelect(props: Props): ReactElement {
  const {
    disabled,
    id,
    label,
    name,
    onAnswerChange,
    onAnswerComplete,
    optionalText,
    options,
    placeholder,
    required,
    title,
    validationError,
    value,
  } = props;

  const showError = !!validationError;
  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  const noSelectedOption = { value: MUI_NONE, label: `- ${placeholder} -` };
  const renderedOptions: { value: string; label: string }[] = options.map((o) => ({ value: o.id, label: o.text }));
  const selectedRenderingOption = options.find((option) => option.id === value);
  const selectedOption: { value: string; label: string } = selectedRenderingOption
    ? { label: selectedRenderingOption.text, value: selectedRenderingOption.id }
    : noSelectedOption;

  return (
    <FormControl fullWidth error={showError} required={required}>
      <AutoComplete
        validationError={undefined}
        disabled={disabled}
        options={renderedOptions}
        option={selectedOption}
        label={labelValue}
        id={id}
        required={required}
        onChange={(change) => {
          const updatedValue = !change || !change.value || change?.value === MUI_NONE ? '' : change.value;
          onAnswerChange && onAnswerChange(updatedValue);
          onAnswerComplete && onAnswerComplete(name, updatedValue, value);
        }}
      />

      {validationError && <WarningText>{validationError.message}</WarningText>}
    </FormControl>
  );
}
