import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function FileDownloadIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M12.042 36H20.6162H25.0294H33.5L34 12.5L30 8.57623C29.5982 8.17464 29.0533 7.949 28.4852 7.94888L12.042 7.94839C11.4735 7.94839 10.9283 8.17423 10.5263 8.57623C10.1243 8.97822 9.89844 9.52344 9.89844 10.0919V33.8565C9.89844 34.425 10.1243 34.9702 10.5263 35.3722C10.9283 35.7742 11.4735 36 12.042 36Z'
        fill={secondaryColor}
        opacity='0.1'
      />
      <path
        d='M32.9792 39.2224C37.4979 39.2224 41.161 35.5593 41.161 31.0406C41.161 26.5219 37.4979 22.8588 32.9792 22.8588C28.4605 22.8588 24.7974 26.5219 24.7974 31.0406C24.7974 35.5593 28.4605 39.2224 32.9792 39.2224Z'
        fill={secondaryColor}
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.9783 23.8087C28.9114 23.8087 25.6146 27.1055 25.6146 31.1723C25.6146 35.2391 28.9114 38.5359 32.9783 38.5359C37.0451 38.5359 40.3419 35.2391 40.3419 31.1723C40.3419 27.1055 37.0451 23.8087 32.9783 23.8087ZM23.9783 31.1723C23.9783 26.2017 28.0077 22.1723 32.9783 22.1723C37.9488 22.1723 41.9783 26.2017 41.9783 31.1723C41.9783 36.1429 37.9488 40.1723 32.9783 40.1723C28.0077 40.1723 23.9783 36.1429 23.9783 31.1723Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.8853 27.4819C33.8873 27.0034 33.501 26.614 33.0225 26.6121C32.5441 26.6101 32.1547 26.9964 32.1527 27.4749L32.1316 32.7098L30.8119 31.3901C30.4961 31.0743 29.9842 31.0743 29.6684 31.3901C29.3527 31.7058 29.3527 32.2178 29.6684 32.5335L32.4192 35.2843C32.735 35.6001 33.2469 35.6001 33.5627 35.2843L36.3134 32.5335C36.6292 32.2178 36.6292 31.7058 36.3134 31.3901C35.9977 31.0743 35.4857 31.0743 35.17 31.3901L33.8643 32.6957L33.8853 27.4819Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1705 16.5522C13.1705 16.0747 13.5576 15.6876 14.0352 15.6876H29.1661C29.6436 15.6876 30.0307 16.0747 30.0307 16.5522C30.0307 17.0298 29.6436 17.4169 29.1661 17.4169H14.0352C13.5576 17.4169 13.1705 17.0298 13.1705 16.5522Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1705 23.0369C13.1705 22.5594 13.5576 22.1723 14.0352 22.1723H22.6814C23.1589 22.1723 23.546 22.5594 23.546 23.0369C23.546 23.5144 23.1589 23.9015 22.6814 23.9015H14.0352C13.5576 23.9015 13.1705 23.5144 13.1705 23.0369Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.1705 29.5215C13.1705 29.044 13.5576 28.6569 14.0352 28.6569H19.439C19.9166 28.6569 20.3037 29.044 20.3037 29.5215C20.3037 29.9991 19.9166 30.3862 19.439 30.3862H14.0352C13.5576 30.3862 13.1705 29.9991 13.1705 29.5215Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.4452 36.2075C21.4452 37.1191 20.3608 36.9942 20.3608 36.9942H12.8584C11.8351 36.9942 10.8537 36.5916 10.1301 35.8751C9.40651 35.1585 9 34.1866 9 33.1731V10.8211C9 9.80767 9.40651 8.83576 10.1301 8.11917C10.8537 7.40258 11.8351 7 12.8584 7H28.0476C29.0702 7.00022 30.0512 7.40244 30.7745 8.1183L33.8704 11.1842C34.5932 11.9005 34.9998 12.8721 35 13.8848V17.252C35 17.252 35 18.1261 34.0543 18.1261C33.2852 18.1261 33.2852 17.252 33.2852 17.252V13.8852C33.285 13.3226 33.0594 12.783 32.6578 12.3851L29.5624 9.31954C29.1605 8.92184 28.6157 8.69838 28.0476 8.69826H12.8584C12.2899 8.69826 11.7447 8.92191 11.3427 9.32002C10.9407 9.71812 10.7148 10.2581 10.7148 10.8211V33.1731C10.7148 33.7362 10.9407 34.2761 11.3427 34.6742C11.7447 35.0723 12.2899 35.296 12.8584 35.296H20.3608C20.3608 35.296 21.4452 35.296 21.4452 36.2075Z'
        fill={primaryColor}
      />
    </svg>
  );
}
