import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function FutureIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M21.9146 20.0713H25.9462V24.1029H21.9146V20.0713Z'
        stroke={primaryColor}
        strokeWidth='1.7'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5871 31.6445V13.9722C20.5883 13.1626 20.2956 12.38 19.7634 11.7698C19.2313 11.1596 18.4958 10.7633 17.6935 10.6543C16.8912 10.5454 16.0766 10.7313 15.401 11.1775C14.7254 11.6238 14.2347 12.3 14.02 13.0806L9.32904 30.1224'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.587 33.2684C19.6902 33.2684 18.9631 32.5414 18.9631 31.6445V31.4249L20.587 31.6445V13.9722C20.5882 13.1626 20.2955 12.38 19.7634 11.7698C19.2312 11.1596 18.4957 10.7633 17.6934 10.6543C16.8911 10.5454 16.0766 10.7313 15.401 11.1776C14.7254 11.6238 14.2347 12.3 14.02 13.0807L9.329 30.1225L10.9529 30.342L10.8947 30.5534C10.6567 31.4182 9.76274 31.9262 8.89802 31.6882C8.03331 31.4501 7.52527 30.5562 7.76329 29.6915L12.4542 12.65C12.4542 12.6499 12.4542 12.6498 12.4542 12.6497C12.7733 11.49 13.5023 10.4854 14.506 9.82251C15.5097 9.15955 16.7199 8.88334 17.9119 9.04517C19.1039 9.207 20.1966 9.79587 20.9873 10.7024C21.7776 11.6087 22.2124 12.7708 22.211 13.9732M22.211 13.9732V31.6445C22.211 32.5414 21.4839 33.2684 20.587 33.2684'
        fill={primaryColor}
      />
      <path
        d='M14.8555 37.3761C18.021 37.3761 20.5871 34.81 20.5871 31.6445C20.5871 28.4791 18.021 25.913 14.8555 25.913C11.6901 25.913 9.12396 28.4791 9.12396 31.6445C9.12396 34.81 11.6901 37.3761 14.8555 37.3761Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.211 31.6445C22.211 35.7068 18.9178 39 14.8555 39C10.7932 39 7.5 35.7068 7.5 31.6445C7.5 27.5822 10.7932 24.289 14.8555 24.289C18.9178 24.289 22.211 27.5822 22.211 31.6445ZM20.587 31.6445C20.587 34.8099 18.0209 37.376 14.8555 37.376C11.69 37.376 9.12394 34.8099 9.12394 31.6445C9.12394 28.479 11.69 25.9129 14.8555 25.9129C18.0209 25.9129 20.587 28.479 20.587 31.6445Z'
        fill={primaryColor}
      />
      <path
        d='M27.2874 31.6445V13.9722C27.2862 13.1626 27.5789 12.38 28.111 11.7698C28.6432 11.1596 29.3787 10.7633 30.181 10.6543C30.9833 10.5454 31.7978 10.7313 32.4734 11.1775C33.149 11.6238 33.6397 12.3 33.8545 13.0806L38.5454 30.1224'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.2874 33.2684C28.1843 33.2684 28.9114 32.5414 28.9114 31.6445V31.4249L27.2874 31.6445V13.9722C27.2862 13.1626 27.5789 12.38 28.1111 11.7698C28.6432 11.1596 29.3787 10.7633 30.181 10.6543C30.9833 10.5454 31.7979 10.7313 32.4735 11.1776C33.1491 11.6238 33.6398 12.3 33.8545 13.0807L38.5455 30.1225L36.9215 30.342L36.9797 30.5534C37.2178 31.4182 38.1117 31.9262 38.9764 31.6882C39.8411 31.4501 40.3492 30.5562 40.1112 29.6915L35.4203 12.65C35.4203 12.6499 35.4202 12.6498 35.4202 12.6497C35.1011 11.49 34.3721 10.4854 33.3685 9.82251C32.3647 9.15955 31.1545 8.88334 29.9626 9.04517C28.7706 9.207 27.6778 9.79587 26.8872 10.7024C26.0968 11.6087 25.6621 12.7708 25.6635 13.9732M25.6635 13.9732V31.6445C25.6635 32.5414 26.3905 33.2684 27.2874 33.2684'
        fill={primaryColor}
      />
      <path
        d='M33.0054 37.3761C36.1709 37.3761 38.737 34.81 38.737 31.6445C38.737 28.4791 36.1709 25.913 33.0054 25.913C29.84 25.913 27.2739 28.4791 27.2739 31.6445C27.2739 34.81 29.84 37.3761 33.0054 37.3761Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.3609 31.6445C40.3609 35.7068 37.0677 39 33.0054 39C28.9431 39 25.6499 35.7068 25.6499 31.6445C25.6499 27.5822 28.9431 24.289 33.0054 24.289C37.0677 24.289 40.3609 27.5822 40.3609 31.6445ZM38.7369 31.6445C38.7369 34.8099 36.1708 37.376 33.0054 37.376C29.8399 37.376 27.2738 34.8099 27.2738 31.6445C27.2738 28.479 29.8399 25.9129 33.0054 25.9129C36.1708 25.9129 38.7369 28.479 38.7369 31.6445Z'
        fill={primaryColor}
      />
    </svg>
  );
}
