import { Localizable } from '@breathelife/types';

export enum DayOfTheWeek {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

type Weekday = { short: Localizable; long: Localizable };

export const weekdays: { [key: string]: Weekday } = {
  [DayOfTheWeek.monday]: {
    short: {
      en: 'M',
      fr: 'L',
    },
    long: {
      en: 'Monday',
      fr: 'Lundi',
    },
  },
  [DayOfTheWeek.tuesday]: {
    short: {
      en: 'T',
      fr: 'M',
    },
    long: {
      en: 'Mardi',
      fr: 'Tuesday',
    },
  },
  [DayOfTheWeek.wednesday]: {
    short: {
      en: 'W',
      fr: 'M',
    },
    long: {
      en: 'Wednesday',
      fr: 'Mercredi',
    },
  },
  [DayOfTheWeek.thursday]: {
    short: {
      en: 'T',
      fr: 'J',
    },
    long: {
      en: 'Thursday',
      fr: 'Jeudi',
    },
  },
  [DayOfTheWeek.friday]: {
    short: {
      en: 'F',
      fr: 'V',
    },
    long: {
      en: 'Friday',
      fr: 'Vendredi',
    },
  },
  [DayOfTheWeek.saturday]: {
    short: {
      en: 'S',
      fr: 'S',
    },
    long: {
      en: 'Saturday',
      fr: 'Samedi',
    },
  },
  [DayOfTheWeek.sunday]: {
    short: {
      en: 'S',
      fr: 'D',
    },
    long: {
      en: 'Sunday',
      fr: 'Dimanche',
    },
  },
};
