import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';

import { RunningText, Title } from '../../Typography';
import { ConfirmationIcon } from './ConfirmationIcon';
import { ConfirmationIconContainer } from './Styles';

type Props = {
  title: string;
  subtitle: string;
};

export function Confirmation({ title, subtitle }: Props): ReactElement {
  return (
    <Box my={3} textAlign='center'>
      <ConfirmationIconContainer>
        <ConfirmationIcon />
      </ConfirmationIconContainer>
      <Title level={2} center>
        {title}
      </Title>
      <Box mt={2}>
        <RunningText center>{ReactHtmlParser(subtitle)}</RunningText>
      </Box>
    </Box>
  );
}
