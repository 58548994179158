import { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

import { Snackbar, SnackbarOrigin } from '@breathelife/mui';

import { MessageType, NotificationContent } from './NotificationContent';

type Props = {
  type: MessageType;
  position: SnackbarOrigin;
  autoHideDuration?: number;
  title?: ReactNode;
  message?: ReactNode;
  display: boolean;
  onClose: () => void;
  dataTestId?: string;
};

const StyledSnackbar = styled(Snackbar)`
  && {
    top: 0;
    right: auto;
    left: auto;
    width: 100%;
    padding: 16px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      top: 16px;
      right: 16px;
      width: auto;
      padding: 0;
    }
  }
`;

export function Notification(props: Props): ReactElement {
  return (
    <StyledSnackbar
      data-testid={props.dataTestId}
      anchorOrigin={props.position}
      open={props.display}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
    >
      <NotificationContent type={props.type} title={props.title} message={props.message} onClose={props.onClose} />
    </StyledSnackbar>
  );
}

Notification.defaultProps = {
  position: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
};
