import { ReactElement } from 'react';

export function ConfirmationIcon(): ReactElement {
  return (
    <svg fill='none' height='68' viewBox='0 0 68 68' width='68' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m34 65.25c17.2589 0 31.25-13.9911 31.25-31.25s-13.9911-31.25-31.25-31.25-31.25 13.9911-31.25 31.25 13.9911 31.25 31.25 31.25z'
        fill='#e8f4ff'
      />
      <g clipRule='evenodd' fill='#004383' fillRule='evenodd'>
        <path d='m34.0001 4.52079c-16.2809 0-29.47918 13.19831-29.47918 29.47921s13.19828 29.4791 29.47918 29.4791 29.4792-13.1982 29.4792-29.4791-13.1983-29.47921-29.4792-29.47921zm-33.020852 29.47921c0-18.2369 14.783952-33.020874 33.020852-33.020874s33.0208 14.783974 33.0208 33.020874-14.7839 33.0208-33.0208 33.0208-33.020852-14.7839-33.020852-33.0208z' />
        <path d='m51.0046 22.4043c1.0107.7619 1.2124 2.1988.4505 3.2094l-15.376 20.3982c-.5398.7134-1.2264 1.3025-2.0129 1.7284-.7864.4259-1.6548.6789-2.5468.7424-.8919.0636-1.7874-.0637-2.6263-.3736-.8389-.3098-1.6023-.7953-2.2382-1.4243l-.0047-.0046-7.9381-7.8968c-.8973-.8926-.9011-2.3436-.0085-3.2409s2.3436-.9011 3.2409-.0085l7.9334 7.8921c.0006.0007.0012.0013.0018.0019.1701.1677.375.2981.6014.3817.2272.0839.4703.1185.7126.1013.2423-.0173.4776-.086.69-.201.2117-.1147.3954-.2725.5394-.4622.0004-.0005-.0004.0006 0 0l15.3721-20.3929c.7618-1.0107 2.1987-1.2124 3.2094-.4506z' />
      </g>
    </svg>
  );
}
