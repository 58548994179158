export function isEmptyString(str: string): boolean {
  return str.trim().length < 1;
}

export function getNonEmptyLines(str: string): string[] {
  return str.split('\n').filter((value) => !isEmptyString(value));
}

export function isMultiLine(str: string): boolean {
  return getNonEmptyLines(str).length > 1;
}
