import { ReactElement, useEffect, useRef, useCallback, useState } from 'react';
import styled from 'styled-components';

import { TextFieldProps } from '@breathelife/mui';
import { IsoCountryCode } from '@breathelife/types';

import { FieldProps, InputVariant } from './Helpers/FieldHelper';
import { Input as TextInput } from './TextInput';
import { OnAnswerChange, RepeatedIndices } from '@breathelife/questionnaire-engine';

type Props = TextFieldProps &
  FieldProps & {
    countryCode: IsoCountryCode;
    useAutocompleteGeolocalization?: boolean;
    onAutocompleteAnswerChange: OnAnswerChange;
    nodeIdsToUpdate: {
      autocomplete?: string;
      streetAddress: string;
      city: string;
      provinceOrState: string;
      postalOrZipCode: string;
    };
    repeatedIndices: RepeatedIndices;
    inputVariant?: InputVariant;
    placeholder?: string;
  };

const AutoCompleteInputContainer = styled.div`
  position: relative;
  div.pac-container {
    top: 65px !important;
    left: 0px !important;
  }
`;

function formatStreetAddress(number?: string, street?: string): string {
  return `${number ?? ''} ${street ?? ''}`;
}

export function AddressAutocompleteInput(props: Props): ReactElement {
  const {
    countryCode,
    nodeIdsToUpdate,
    onAnswerChange,
    repeatedIndices,
    onAutocompleteAnswerChange,
    value,
    ...textInputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const addressInputRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>(value as string);
  const autocomplete = useRef<google.maps.places.Autocomplete>();
  const [isGoogleInWindow, setIsGoogleInWindow] = useState(false);
  const [isAutoCompleteRefLoaded, setIsAutoCompleteRefLoaded] = useState(false);

  const handleAutocompleteSelectionChange = useCallback(() => {
    if (!autocomplete.current) return;

    const place = autocomplete.current.getPlace();

    if (!place || !place.address_components) return;

    let city: string | undefined;
    let number: string | undefined;
    let postalCode: string | undefined;
    let stateOrProvince: string | undefined;
    let street: string | undefined;

    place.address_components.forEach((addressComponent) => {
      if (!addressComponent.types.length) return;

      const addressComponentType = addressComponent.types[0];

      switch (addressComponentType) {
        case 'street_number': {
          number = addressComponent.long_name;
          break;
        }
        case 'postal_code': {
          postalCode = addressComponent.long_name;
          break;
        }
        case 'locality': {
          city = addressComponent.long_name;
          break;
        }
        case 'route': {
          street = addressComponent.long_name;
          break;
        }
        case 'administrative_area_level_1': {
          stateOrProvince = addressComponent.short_name;
          break;
        }
      }
    });

    const addressPropertiesString = [number, street, city, stateOrProvince, postalCode].join(' ');

    const batchUpdate = [];

    if (nodeIdsToUpdate.city) {
      batchUpdate.push({ id: nodeIdsToUpdate.city, value: city || '', repeatedIndices });
    }

    if (nodeIdsToUpdate.provinceOrState) {
      batchUpdate.push({ id: nodeIdsToUpdate.provinceOrState, value: stateOrProvince || '', repeatedIndices });
    }

    if (nodeIdsToUpdate.postalOrZipCode) {
      batchUpdate.push({ id: nodeIdsToUpdate.postalOrZipCode, value: postalCode || '', repeatedIndices });
    }

    if (nodeIdsToUpdate.autocomplete) {
      setInputValue(addressPropertiesString);
      batchUpdate.push({ id: nodeIdsToUpdate.autocomplete, value: addressPropertiesString, repeatedIndices });
    }

    if (nodeIdsToUpdate.streetAddress) {
      const formattedAddress = formatStreetAddress(number, street);
      setInputValue(formattedAddress);
      batchUpdate.push({ id: nodeIdsToUpdate.streetAddress, value: formattedAddress, repeatedIndices });
    }

    onAutocompleteAnswerChange(batchUpdate);
  }, [
    nodeIdsToUpdate.autocomplete,
    nodeIdsToUpdate.city,
    nodeIdsToUpdate.postalOrZipCode,
    nodeIdsToUpdate.provinceOrState,
    nodeIdsToUpdate.streetAddress,
    onAutocompleteAnswerChange,
    repeatedIndices,
  ]);

  useEffect(() => {
    const checkGoogleIsLoadedInterval = setInterval(() => {
      if (typeof google === 'undefined') {
        return;
      }
      clearInterval(checkGoogleIsLoadedInterval);
      setIsGoogleInWindow(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (!isGoogleInWindow || !inputRef.current) return;

    let bounds: google.maps.LatLngBounds | null = null;

    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      const circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy,
      });

      bounds = circle.getBounds();
    });

    autocomplete.current = new google.maps.places.Autocomplete(inputRef.current, {
      types: ['address'],
      componentRestrictions: { country: countryCode },
      bounds: bounds === null ? undefined : bounds,
    });
    setIsAutoCompleteRefLoaded(true);
  }, [countryCode, isGoogleInWindow]);

  useEffect(() => {
    if (!isAutoCompleteRefLoaded || !autocomplete.current) return;
    autocomplete.current.addListener('place_changed', handleAutocompleteSelectionChange);

    return () => {
      if (autocomplete.current) {
        google.maps.event.clearListeners(autocomplete.current, 'place_changed');
      }
    };
  }, [handleAutocompleteSelectionChange, isAutoCompleteRefLoaded]);

  return (
    <AutoCompleteInputContainer ref={addressInputRef}>
      <TextInput
        {...textInputProps}
        inputRef={inputRef}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onBlur={() => {
          // When we select a value in the google auto complete, the onBlur execute before the place changed,
          // so once place_changed event trigger, it will override the value that was set on the onBlur
          onAnswerChange(inputValue);
        }}
      />
    </AutoCompleteInputContainer>
  );
}
