import styled from 'styled-components';

import { SnackbarContent, SnackbarContentProps } from '@breathelife/mui';

export const StyledSnackbarContent = styled(SnackbarContent)<SnackbarContentProps>`
  &&&&& {
    align-items: start;
    background-color: ${(props) => props.theme.colors.grey[0]} !important;
    border: 1px solid ${(props) => props.theme.colors.grey[30]};
    border-radius: 8px;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.05);
    flex-wrap: nowrap;
    padding: 8px 16px !important;
    min-width: 280px;
    max-width: 400px;
    flex-grow: 1;

    .MuiSnackbarContent-action {
      padding-left: 16px;
    }
  }
`;
