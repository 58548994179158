import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function SummaryIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='24.6572' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M15.5712 8.42485C15.2078 8.78825 15.0037 9.28112 15.0037 9.79504V15.1359L32.0416 35.9276H39.5031V16.0398L32.0416 8.42441C31.6783 8.06139 31.1858 7.85741 30.6722 7.8573H16.9414C16.4275 7.8573 15.9346 8.06145 15.5712 8.42485Z'
        fill='#F8F9FD'
      />
      <path
        d='M30.5056 7.8573V15.6083C30.5056 16.1222 30.7098 16.6151 31.0732 16.9785C31.4366 17.3418 31.9294 17.546 32.4434 17.546H40.1943'
        fill='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.8192 36.8316C30.9152 36.3796 31.3594 35.6488 31.3594 35.6488C31.6256 35.4277 31.9676 35.2948 32.3407 35.2948H38.3905C38.5245 35.2948 38.6489 35.2421 38.7373 35.1549C38.825 35.0684 38.8703 34.9558 38.8703 34.8428V18.2697H38.2746H31.789C31.0858 18.2697 30.4113 17.9904 29.914 17.4931C29.4167 16.9958 29.1373 16.3213 29.1373 15.618V8.53678H16.208C16.0739 8.53678 15.9495 8.58951 15.8611 8.67669C15.7735 8.76315 15.7282 8.87584 15.7282 8.98877V14.9551C15.7282 15.4308 15.6695 16.0214 15.1727 16.1379C14.745 16.2381 14.1914 16.0399 14.1914 15.5879V14.9551V8.98877C14.1914 8.46132 14.4039 7.95547 14.7821 7.5825C15.1602 7.20953 15.6732 7 16.208 7H30.4975C31.032 7.00011 31.5446 7.20946 31.9226 7.58205L39.8169 15.3674C40.1947 15.7403 40.4069 16.2457 40.4071 16.7728V34.8428C40.4071 35.3703 40.1946 35.8761 39.8164 36.2491C39.4382 36.6221 38.9253 36.8316 38.3905 36.8316H32.3407H31.8192ZM30.5837 8.54425V15.618C30.5837 15.9377 30.7107 16.2443 30.9367 16.4703C31.1628 16.6964 31.4694 16.8234 31.789 16.8234H38.8502H38.8703V16.7728C38.8702 16.6602 38.8247 16.5476 38.7374 16.4613L30.8438 8.67658C30.7749 8.60866 30.684 8.56166 30.5837 8.54425Z'
        fill={primaryColor}
      />
      <path
        d='M20.218 36.8316C25.5434 36.8316 29.8605 32.5145 29.8605 27.1891C29.8605 21.8637 25.5434 17.5466 20.218 17.5466C14.8925 17.5466 10.5754 21.8637 10.5754 27.1891C10.5754 32.5145 14.8925 36.8316 20.218 36.8316Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2181 18.3149C15.317 18.3149 11.3439 22.288 11.3439 27.1891C11.3439 32.0901 15.317 36.0632 20.2181 36.0632C25.1191 36.0632 29.0922 32.0901 29.0922 27.1891C29.0922 22.288 25.1191 18.3149 20.2181 18.3149ZM9.80713 27.1891C9.80713 21.4393 14.4683 16.7781 20.2181 16.7781C25.9679 16.7781 30.629 21.4393 30.629 27.1891C30.629 32.9389 25.9679 37.6 20.2181 37.6C14.4683 37.6 9.80713 32.9389 9.80713 27.1891Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.529 23.5261C25.8878 23.7965 25.9594 24.3067 25.6889 24.6655L20.9444 30.9597C20.7686 31.192 20.5451 31.3838 20.2891 31.5224C20.0331 31.6611 19.7504 31.7434 19.4601 31.7641C19.1698 31.7848 18.8783 31.7433 18.6053 31.6425C18.3322 31.5416 18.0837 31.3836 17.8766 31.1788L17.875 31.1772L15.4256 28.7405C15.107 28.4236 15.1057 27.9085 15.4226 27.5899C15.7395 27.2714 16.2546 27.27 16.5732 27.5869L19.0209 30.0219C19.0211 30.0221 19.0207 30.0217 19.0209 30.0219C19.0626 30.063 19.1134 30.0955 19.169 30.1161C19.2249 30.1367 19.2848 30.1453 19.3445 30.141C19.4041 30.1368 19.462 30.1198 19.5142 30.0916C19.5663 30.0634 19.6113 30.0247 19.6466 29.9782C19.6467 29.9781 19.6465 29.9783 19.6466 29.9782L24.3896 23.686C24.66 23.3272 25.1702 23.2556 25.529 23.5261Z'
        fill={primaryColor}
      />
    </svg>
  );
}
