import styled from 'styled-components';

import { Grid } from '@breathelife/mui';

export const PdfPaddedGridItem = styled(Grid)`
  &&&& {
    padding: 6px 12px 6px 12px;
  }
`;

export const PdfPaddedGrid = styled(Grid)`
  &&&& {
    padding: 12px;
  }
`;
