import { ReactElement, useEffect, useState } from 'react';

import { Container } from './Styles';

type Props = {
  message: string;
};

// This component is based on https://almerosteyn.com/2017/03/accessible-react-navigation
// It's goal is to make sure screen readers are aware of a page change, and notify the user.
export function A11yMessage(props: Props): ReactElement {
  const [message, setMessage] = useState('');

  useEffect(() => {
    // We delay the setting and clearing of the accessible route transition text to ensure that the screen readers
    // don't miss it.
    const setMessageTimeout = setTimeout(() => setMessage(props.message), 50);
    const clearMessageTimeout = setTimeout(() => setMessage(''), 500);

    return () => {
      clearTimeout(setMessageTimeout);
      clearTimeout(clearMessageTimeout);
    };
  }, [props.message]);

  return (
    <Container role='status' aria-live='polite' aria-atomic='true'>
      {message}
    </Container>
  );
}
