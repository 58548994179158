import styled from 'styled-components';

import { FormControl, FormControlLabel, Radio as MuiRadio } from '@breathelife/mui';

export const RadioLabel = styled.span<{ grey: number; fontSize: string; paddingTop?: string }>`
  &&& {
    color: ${(props) => props.theme.colors.grey[props.grey]};
    font-size: ${(props) => props.fontSize};
    font-weight: 400;
    padding-top: ${(props) => props.paddingTop ?? 0};
    display: block;
    line-height: 1.2;
  }
`;

export const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

export const StyledFormControlLabel = styled(FormControlLabel)<{ $hasError?: boolean }>`
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-radius: 4px;
  margin: unset !important;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  border: ${(props) => props.$hasError && `1px solid ${props.theme.colors.error}`};
`;

export const StyledRadio = styled(MuiRadio)`
  &&.Mui-checked {
    color: ${(props) => props.theme.colors.primary.default};
  }

  &.Mui-focusVisible {
    outline: ${(props) => `1px solid ${props.theme.colors.selection.border.focus}}`};
  }
`;
