import _ from 'lodash';
import { ReactNode, ReactElement, useState } from 'react';
import Modal from 'react-responsive-modal';

import { CloseButton, Content, createStyles, Header } from './Styles';

type Props = {
  width: string;
  shadowColor?: string;
  children: ReactNode;
  isFullSizeMobile?: boolean;
  isMobile: boolean;
  labelledBy?: string;
  describedBy?: string;
  closeButtonTitle?: string;
  closeButtonContent?: ReactNode;
  leftContent?: ReactNode;
  onClose: () => void;
  hideHeader?: boolean;
  isVideoModal?: boolean;
  styles?: Record<string, any>;
};

const renderDefaultCloseSvgData = (): ReactNode => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.22207 7.00079L0.707072 2.48578C0.208229 1.98694 0.208228 1.17816 0.707072 0.679312C1.20592 0.180469 2.0147 0.180469 2.51354 0.679313L7.02855 5.19432L11.5465 0.676375C12.0453 0.177531 12.8541 0.177531 13.353 0.676374C13.8518 1.17522 13.8518 1.984 13.353 2.48285L8.83502 7.00079L13.3524 11.5182C13.8512 12.017 13.8512 12.8258 13.3524 13.3246C12.8535 13.8235 12.0447 13.8235 11.5459 13.3246L7.02855 8.80726L2.51412 13.3217C2.01528 13.8205 1.20649 13.8205 0.70765 13.3217C0.208807 12.8228 0.208807 12.0141 0.707651 11.5152L5.22207 7.00079Z'
      fill='#757575'
    />
  </svg>
);

const animationDuration = 600;

export function BaseModal(props: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = (): void => {
    setIsOpen(false);
    setTimeout(() => {
      props.onClose?.();
    }, animationDuration);
  };

  const defaultShadow = 'rgba(0, 0, 0, 0.1)';
  const defaultStyles = createStyles(
    props.width,
    props.shadowColor ?? defaultShadow,
    props.isMobile,
    !!props.isFullSizeMobile,
    props.isVideoModal || false,
  );

  const styles = _.merge(defaultStyles, props.styles);

  return (
    <Modal
      open={isOpen}
      onClose={closePopup}
      center
      showCloseIcon={false}
      styles={styles}
      ariaLabelledby={props.labelledBy}
      ariaDescribedby={props.describedBy}
      blockScroll={!props.isMobile}
      animationDuration={animationDuration}
    >
      <Content hasLeftContent={props.leftContent != null} showFullSize={props.isMobile && props.isFullSizeMobile}>
        {props.leftContent}
        {!props.hideHeader && (
          <Header>
            {/* Cannot use close button from react-responsive-modal since it doesn't respect accessibility standards */}
            <CloseButton onClick={closePopup}>
              {renderDefaultCloseSvgData()}
              <span>{props.closeButtonTitle ?? 'Close'}</span>
            </CloseButton>
          </Header>
        )}
        {props.children}
      </Content>
    </Modal>
  );
}
