import { ReactNode } from 'react';
import styled from 'styled-components';

const ExpandStyled = styled.div`
  transition: ${(props) => `all ${props.theme.animation.speed.slow} ${props.theme.animation.transition}`};
  max-height: 0;
  overflow: hidden;
  opacity: 0;

  &.expand--visible {
    /* The trick to have height animations is to have the max-height
    be set way past the value it would ever reach. */
    max-height: 1000px;
    opacity: 1;
  }
`;

export type ExpandProps = {
  isVisible: boolean;
  children: ReactNode;
};

export function Expand(props: ExpandProps) {
  const classes = props.isVisible ? 'expand--visible' : '';

  return <ExpandStyled className={classes}>{props.children}</ExpandStyled>;
}
