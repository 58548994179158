import { ReactNode, ReactElement } from 'react';
import styled from 'styled-components';

import { Button, ButtonProps as MuiButtonProps } from '@breathelife/mui';

export enum IconPosition {
  right = 'right',
  left = 'left',
}

type IconProps = {
  position: IconPosition;
};

const IconWrapper = styled.div<IconProps>`
  margin-left: ${(props) => props.position === IconPosition.right && '10px'};
  margin-right: ${(props) => props.position === IconPosition.left && '10px'};
`;

const StyledIconTextButton = styled(Button)<IconProps>`
  display: flex;
  padding: 0 !important;
  flex-direction: ${(props) => (props.position === IconPosition.right ? 'row' : 'row-reverse')};
`;

type ButtonProps = MuiButtonProps & {
  iconPosition?: IconPosition;
  icon?: ReactNode;
};

export function IconTextButton(props: ButtonProps): ReactElement {
  const { icon, iconPosition, ...buttonProps } = props;
  return (
    <StyledIconTextButton variant='text' {...buttonProps} position={iconPosition || IconPosition.left}>
      <IconWrapper position={iconPosition || IconPosition.left}>{icon}</IconWrapper>
      {props.children}
    </StyledIconTextButton>
  );
}
