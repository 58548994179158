import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ScubaIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M19.4684 24.3038H17.3165C16.1751 24.3038 15.0804 23.8504 14.2732 23.0433C13.4661 22.2361 13.0127 21.1414 13.0127 20C13.0127 18.2879 13.6928 16.6458 14.9035 15.4351C16.1142 14.2245 17.7562 13.5443 19.4684 13.5443H23.7722V20C23.7722 21.1414 23.3188 22.2361 22.5116 23.0433C21.7045 23.8504 20.6098 24.3038 19.4684 24.3038Z'
        fill='#F8F9FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.4683 14.4051C17.9844 14.4051 16.5613 14.9945 15.5121 16.0438C14.4628 17.093 13.8734 18.5161 13.8734 20C13.8734 20.9132 14.2361 21.7889 14.8818 22.4346C15.5275 23.0803 16.4033 23.443 17.3164 23.443H19.4683C20.3815 23.443 21.2572 23.0803 21.9029 22.4346C22.5486 21.7889 22.9113 20.9132 22.9113 20V14.4051H19.4683ZM14.2948 14.8265C15.6669 13.4544 17.5279 12.6835 19.4683 12.6835H23.7721C24.2475 12.6835 24.6329 13.0689 24.6329 13.5443V20C24.6329 21.3697 24.0887 22.6834 23.1202 23.6519C22.1517 24.6204 20.838 25.1646 19.4683 25.1646H17.3164C15.9467 25.1646 14.6331 24.6204 13.6645 23.6519C12.696 22.6834 12.1519 21.3697 12.1519 20C12.1519 18.0596 12.9227 16.1986 14.2948 14.8265Z'
        fill={primaryColor}
      />
      <path
        d='M30.2278 24.3038H28.0759C26.9345 24.3038 25.8398 23.8504 25.0326 23.0433C24.2255 22.2361 23.7721 21.1414 23.7721 20V13.5443H28.0759C29.788 13.5443 31.4301 14.2245 32.6408 15.4351C33.8514 16.6458 34.5316 18.2879 34.5316 20C34.5316 21.1414 34.0782 22.2361 33.271 23.0433C32.4639 23.8504 31.3692 24.3038 30.2278 24.3038V24.3038Z'
        fill='#F8F9FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.9114 13.5443C22.9114 13.0689 23.2968 12.6835 23.7721 12.6835H28.0759C30.0164 12.6835 31.8773 13.4544 33.2494 14.8265C34.6215 16.1986 35.3924 18.0596 35.3924 20C35.3924 21.3697 34.8483 22.6834 33.8797 23.6519C32.9112 24.6204 31.5976 25.1646 30.2278 25.1646H28.0759C26.7062 25.1646 25.3926 24.6204 24.424 23.6519C23.4555 22.6834 22.9114 21.3697 22.9114 20V13.5443ZM30.2278 23.443C31.141 23.443 32.0167 23.0803 32.6624 22.4346C33.3081 21.7889 33.6709 20.9132 33.6709 20C33.6709 18.5161 33.0814 17.093 32.0322 16.0438C30.9829 14.9945 29.5598 14.4051 28.0759 14.4051H24.6329V20C24.6329 20.9132 24.9956 21.7889 25.6413 22.4346C26.287 23.0803 27.1628 23.443 28.0759 23.443H30.2278Z'
        fill={primaryColor}
      />
      <path
        d='M19.4684 31.8354C19.4684 32.9769 19.9218 34.0716 20.7289 34.8787C21.5361 35.6858 22.6307 36.1392 23.7722 36.1392C24.9136 36.1392 26.0083 35.6858 26.8154 34.8787C27.6225 34.0716 28.076 32.9769 28.076 31.8354C28.076 30.694 27.6225 29.5993 26.8154 28.7922C26.0083 27.9851 24.9136 27.5316 23.7722 27.5316C22.6307 27.5316 21.5361 27.9851 20.7289 28.7922C19.9218 29.5993 19.4684 30.694 19.4684 31.8354Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.1202 28.1836C21.0887 27.215 22.4024 26.6709 23.7721 26.6709C25.1418 26.6709 26.4555 27.215 27.424 28.1836C28.3925 29.1521 28.9367 30.4657 28.9367 31.8355C28.9367 33.2052 28.3925 34.5188 27.424 35.4873C26.4555 36.4559 25.1418 37 23.7721 37C22.4024 37 21.0887 36.4559 20.1202 35.4873C19.1517 34.5188 18.6075 33.2052 18.6075 31.8355C18.6075 30.4657 19.1517 29.1521 20.1202 28.1836ZM23.7721 28.3924C22.8589 28.3924 21.9832 28.7552 21.3375 29.4009C20.6918 30.0466 20.3291 30.9223 20.3291 31.8355C20.3291 32.7486 20.6918 33.6244 21.3375 34.2701C21.9832 34.9157 22.8589 35.2785 23.7721 35.2785C24.6853 35.2785 25.561 34.9157 26.2067 34.2701C26.8524 33.6244 27.2151 32.7486 27.2151 31.8355C27.2151 30.9223 26.8524 30.0466 26.2067 29.4009C25.561 28.7552 24.6853 28.3924 23.7721 28.3924Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.9874 9.45569C41.4627 9.45569 41.8481 9.84106 41.8481 10.3164V25.3797C41.8481 27.3202 41.0773 29.1812 39.7052 30.5533C38.3331 31.9254 36.4721 32.6962 34.5317 32.6962H28.076C27.6006 32.6962 27.2152 32.3108 27.2152 31.8354C27.2152 31.36 27.6006 30.9747 28.076 30.9747H34.5317C36.0155 30.9747 37.4386 30.3852 38.4879 29.336C39.5371 28.2867 40.1266 26.8636 40.1266 25.3797V10.3164C40.1266 9.84106 40.512 9.45569 40.9874 9.45569Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.9873 3C41.4627 3 41.8481 3.38538 41.8481 3.86076V6.01266C41.8481 6.48804 41.4627 6.87342 40.9873 6.87342C40.512 6.87342 40.1266 6.48804 40.1266 6.01266V3.86076C40.1266 3.38538 40.512 3 40.9873 3Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.6709 20C33.6709 19.5246 34.0563 19.1392 34.5317 19.1392H36.6836C37.1589 19.1392 37.5443 19.5246 37.5443 20C37.5443 20.4754 37.1589 20.8608 36.6836 20.8608H34.5317C34.0563 20.8608 33.6709 20.4754 33.6709 20Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 20C10 19.5246 10.3854 19.1392 10.8608 19.1392H13.0127C13.488 19.1392 13.8734 19.5246 13.8734 20C13.8734 20.4754 13.488 20.8608 13.0127 20.8608H10.8608C10.3854 20.8608 10 20.4754 10 20Z'
        fill={primaryColor}
      />
    </svg>
  );
}
