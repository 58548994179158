import ReactHtmlParser from 'html-react-parser';
import { ReactElement, cloneElement, useEffect } from 'react';

import { Grid, Link } from '@breathelife/mui';

import { TextContainer, WidgetContainer } from './Styles';

type Props = {
  promptText: string;
  linkText: string;
  onAnswerChange: (answer: any, triggerStepNavigation?: boolean) => void;
  icon?: JSX.Element;
  resetOnMount?: boolean;
};

export function ActionButton(props: Props): ReactElement {
  // TODO: This is a hack. The intent is to reset the answer to false if the component is mounted.
  // This is needed because this component has no option for the user to "de-select" it. When going through a step with
  // this component, the underlying answer should be set to false, and only set to true if the user explicitly clicks this button.
  // TODO: A better solution would be to support the notion of auto-reset fields in the engine.
  useEffect(() => {
    if (props.resetOnMount) {
      props.onAnswerChange(false, false);
    }
  }, []);

  return (
    <WidgetContainer>
      <TextContainer>
        {props.promptText}
        {/*When the use-case arises, this component should support a prop that will render a traditional button if needed.
        The required props should be adjusted accordingly*/}
        <Link
          component='button'
          type='button'
          onClick={() => {
            props.onAnswerChange(true);
          }}
        >
          <Grid container spacing={1}>
            {props.icon && <Grid item>{cloneElement(props.icon, { size: '20px' })}</Grid>}
            <Grid item>{ReactHtmlParser(props.linkText)}</Grid>
          </Grid>
        </Link>
      </TextContainer>
    </WidgetContainer>
  );
}
