import { ColorRanges } from '@breathelife/types';

import * as colors from './CoreColors';
import { SharedTheme } from './Types';
import { breakpoints } from './breakpoints';

export const fallbackFontFamilies = `
  '-apple-system',
  'BlinkMacSystemFont',
  'Helvetica Neue',
  'Segoe UI',
  'Roboto',
  'sans-serif'
`;

export function getColorizedBaseTheme(colorRanges: ColorRanges): SharedTheme {
  // Use the color ranges provided if possible, falling back to defaults otherwise
  const { grey = colors.coreGreys, primary, secondary, tertiary } = colorRanges;

  return {
    colors: {
      primary,
      secondary,
      tertiary,
      ...colors.accents,

      base: {
        primary: primary.default,
        softPrimary: primary[10],
        activePrimary: primary[70],
        secondary: secondary.default,
        softSecondary: secondary[10],
        tertiary: tertiary.default,
        error: colors.accents.error,
        success: colors.accents.success,
      },
      background: {
        secondary: grey[10],
        primary: grey[0],
        leftPanel: grey[10],
        mobileHeader: grey[10],
        mobileHeaderBottom: grey[10],
      },
      separator: grey[30],
      input: {
        background: grey[0],
        label: grey[60],
        placeholder: grey[60],
        text: grey[90],
        border: {
          default: grey[40],
          hover: grey[60],
          focus: grey[60],
        },
      },
      button: {
        base: {
          background: {
            default: primary[60],
            hover: primary[70],
            focus: primary[70],
            active: primary[80],
          },
          border: {
            default: primary[60],
            hover: primary[60],
            focus: colors.accents.hyperlink,
            active: primary[60],
          },
          text: {
            default: grey[0],
          },
        },
        secondary: {
          background: {
            default: secondary[60],
            hover: secondary[70],
            focus: secondary[70],
            active: secondary[80],
          },
          border: {
            default: secondary[60],
            hover: secondary[70],
            focus: secondary[70],
            active: secondary[80],
          },
        },
        option: {
          background: {
            default: 'transparent',
            hover: primary[10],
            focus: 'transparent',
            active: primary[10],
            selected: primary[10],
          },
          border: {
            default: 'transparent',
            hover: primary[10],
            focus: secondary[60],
            active: primary[10],
            selected: 'transparent',
          },
          text: {
            default: primary[60],
            title: grey[90],
            selected: primary[60],
          },
        },
        submit: {
          background: primary[60],
          hover: primary[70],
        },
        back: {
          background: {
            default: grey[0],
            hover: grey[10],
            focus: grey[0],
            active: grey[20],
          },
          border: {
            default: primary[60],
            hover: primary[60],
            focus: secondary[60],
            active: primary[60],
          },
        },
        scheduleCall: {
          background: {
            default: grey[0],
            hover: grey[10],
            focus: grey[0],
            active: grey[20],
          },
          border: {
            default: grey[40],
            hover: grey[40],
            focus: secondary[60],
            active: grey[40],
          },
        },
        dismiss: grey[60],
      },
      selection: {
        background: {
          default: grey[20],
          hover: grey[30],
          focus: grey[20],
          active: grey[40],
          selected: primary[80],
        },
        border: {
          default: grey[40],
          hover: grey[40],
          focus: colors.accents.hyperlink,
          active: grey[40],
          selected: primary[80],
        },
        text: {
          default: grey[90],
          selected: grey[0],
        },
      },
      text: {
        base: grey[90],
        lightest: grey[10],
        lighter: grey[30],
        light: grey[60],
        dark: grey[80],
        flashy: '#f44336',
        error: colors.accents.error,
        link: tertiary[60],
        title: grey[90],
      },
      form: {
        background: grey[0],
      },
      icons: {
        primary: primary.default,
        secondary: primary[20],
        tertiary: primary[30],
      },
      grey: {
        ...grey,
        regular: grey[20],
        light: grey[20],
        dark: grey[40],
        darker: grey[60],
        darkest: grey[70],
      },
      white: grey[0],
      black: grey[90],
    },
    fonts: {
      family: {
        title: fallbackFontFamilies,
        button: fallbackFontFamilies,
        body: fallbackFontFamilies,
        input: fallbackFontFamilies,
        label: fallbackFontFamilies,
        inputLabel: fallbackFontFamilies,
        fallbacks: fallbackFontFamilies,
      },
      size: {
        button: {
          regular: '14px',
          submit: '15px',
          big: '20px',
          answer: '15px',
        },
        mobile: {
          medium: '17px',
        },
        description: {
          desktop: '18px',
          mobile: '14px',
        },
        slider: {
          tooltips: {
            desktop: '24px',
            mobile: '16px',
          },
          labels: '11px',
        },
        input: '13px',
        small: '10px',
        medium: '18px',
        large: '21px',
        xlarge: '37px',
        body: '14px',
        title: '16px',
        label: '12px',
      },
      weight: {
        light: 200,
        regular: 400,
        medium: 500,
        heavy: 600,
        semibold: 700,
        bold: 900,
        button: 600,
        title: 700,
      },
      lineHeight: {
        body: '1.5em',
      },
      buttonTextTransform: 'none',
    },
    display: {
      shadows: {
        default: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      },
    },
    animation: {
      transition: 'cubic-bezier(0.55, 0, 0.1, 1)',
      speed: {
        slow: '0.5s',
        medium: '0.25s',
        fast: '0.1s',
      },
    },
    metrics: {
      input: {
        maxWidth: '530px',
        height: '50px',
        spacing: '24px',
        borderRadius: '4px',
      },
      borders: {
        default: '1px',
        button: '1px',
        focus: '2px',
      },
      button: {
        borderRadius: '8px',
      },
      logo: {
        height: '40px',
        mobile: {
          height: '27px',
        },
      },
    },
    breakpoints,
    components: {
      RadioCard: {
        backgroundColor: grey[0],
        borderColor: grey[50],
        color: grey[80],
        borderRadius: '3px',
        bulletIconColor: grey[0],
        bulletBackgroundColor: grey[0],

        focus: {
          color: grey[90],
        },

        hover: {
          color: grey[90],
          borderColor: grey[60],
        },

        selected: {
          color: grey[90],
          borderColor: grey[50],
          backgroundColor: grey[0],
          bulletBackgroundColor: primary.default,
        },
      },

      InfoCard: {
        backgroundColor: grey[20],
        borderRadius: '0px',
      },

      SelectableCard: {
        backgroundColor: '#fafafa',
        borderColor: grey[40],

        hover: {
          backgroundColor: grey[20],
        },

        selected: {
          borderColor: primary.default,
          backgroundColor: grey[0],
        },

        focused: {
          borderColor: secondary.default,
        },
      },
    },
  };
}

export const baseTheme: SharedTheme = getColorizedBaseTheme({
  primary: colors.corePrimary,
  secondary: colors.coreSecondary,
  tertiary: colors.coreTertiary,
});
