import { ReactNode, ReactElement, Fragment } from 'react';

import { LoadingContent } from './LoadingContent';
import { Container, Content, Footer, MainTitle } from './Styles';

export type ProductLayoutProps = {
  header: ReactNode;
  mainTitle?: ReactNode;
  popup?: ReactNode;
  footer?: ReactNode;
  contactDetails: ReactNode;
  productList: ReactNode;
  isLoading: boolean;
  loadingContent: {
    title: string;
    subtitle: string;
    image: string;
  };
};

export function ProductLayout(props: ProductLayoutProps): ReactElement {
  let content: ReactElement;

  if (props.isLoading) {
    content = <LoadingContent {...props.loadingContent} />;
  } else {
    content = (
      <Fragment>
        <MainTitle>{props.mainTitle}</MainTitle>

        <Content role='main'>
          <div>{props.contactDetails}</div>
          <div>{props.productList}</div>
        </Content>
        <Footer>{props.footer}</Footer>
      </Fragment>
    );
  }

  return (
    <Container>
      {props.popup}
      {props.header}
      {content}
    </Container>
  );
}
