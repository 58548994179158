import _ from 'lodash';
import { ReactElement, useCallback } from 'react';

import { Box } from '@breathelife/mui';
import { DeepPartial } from '@breathelife/types';

import { LocalizationStrings as ContactFormLocalizationStrings, OnSubmitScheduleDataType } from '../../ContactForm';
import { withResponsive } from '../../withResponsive';
import { BaseModal } from '../BaseModal';
import { ContactAdvisorModalDesktop } from './ContactAdvisorModalDesktop';
import { ContactAdvisorModalMobile } from './ContactAdvisorModalMobile';
import { localizationStrings, LocalizationStrings } from './Localization';
import { ModalContainer } from './Styles';
import { BaseContactAdvisorModalProps } from './types';

type ModalFormLocalizationStrings = LocalizationStrings & ContactFormLocalizationStrings;

type Props = Omit<BaseContactAdvisorModalProps, 'localizationStrings'> & {
  localizationStrings?: DeepPartial<ModalFormLocalizationStrings>;
  isMobile?: boolean;
  onClose: () => void;
};

function BaseContactAdvisorModal(props: Props): ReactElement {
  const { form, language, localizationStrings: localizationStringsProps, isMobile, onClose } = props;

  const mergedLocalizationStrings = _.merge({}, localizationStrings, localizationStringsProps);
  const textStrings = mergedLocalizationStrings[language];

  const onSubmitContactForm = useCallback(
    (onSubmitContactArgs: OnSubmitScheduleDataType): void => {
      form.onSubmitSchedule(onSubmitContactArgs);
    },
    [form],
  );

  const newProps = {
    ...props,
    onSubmitContactForm,
    localizationStrings: mergedLocalizationStrings,
  };

  const width = isMobile ? '100vw' : newProps.useMobileStylingOnDesktop ? '448px' : '860px';

  return (
    <BaseModal
      isMobile={!!isMobile}
      isFullSizeMobile
      width={width}
      labelledBy='modal-title'
      describedBy='modal-title'
      closeButtonTitle={textStrings.close}
      onClose={onClose}
    >
      <ModalContainer>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <ContactAdvisorModalMobile {...newProps} />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {newProps.useMobileStylingOnDesktop ? (
            <ContactAdvisorModalMobile {...newProps} />
          ) : (
            <ContactAdvisorModalDesktop {...newProps} />
          )}
        </Box>
      </ModalContainer>
    </BaseModal>
  );
}

export const ContactAdvisorModal = withResponsive(BaseContactAdvisorModal);
