import ReactHtmlParser from 'html-react-parser';
import { ChangeEvent, ReactElement } from 'react';

import { Checkbox, CheckboxContainer, Label } from './Styles';

export type Props = {
  id: string;
  className?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  required?: boolean;
};

export function SimpleCheckbox(props: Props): ReactElement {
  return (
    <CheckboxContainer className={props.className}>
      <Checkbox
        type='checkbox'
        checked={props.checked}
        onChange={props.onChange}
        disabled={Boolean(props.disabled)}
        id={props.id}
        required={props.required}
      />
      <Label htmlFor={props.id} data-testid={`checkbox-${props.id}`}>
        {props.label && ReactHtmlParser(props.label)}
      </Label>
    </CheckboxContainer>
  );
}
