import styled, { css } from 'styled-components';

export const Container = styled.div<{ hidePrevious?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.hidePrevious ? 'flex-end' : 'space-between')};
  margin: 32px 0;
  width: 100%;
`;

const BaseButton = styled.button`
  &&&& {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
    padding: 16px;
    color: ${(props) => props.theme.colors.grey[0]};
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.family.button};
    border-radius: ${(props) => props.theme.metrics.button.borderRadius};
    letter-spacing: 0.2px;
    mix-blend-mode: normal;
    cursor: pointer;
    box-sizing: border-box;

    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

const defaultNotDisabledStyles = css`
  &:hover {
    background-color: ${(props) => props.theme.colors.button.base.background.hover};
  }
  &:focus {
    &:not(:active) {
      background-color: ${(props) => props.theme.colors.button.base.background.focus};
      outline: 3px solid ${(props) => props.theme.colors.button.base.border.focus};
    }
  }
  &:active {
    background-color: ${(props) => props.theme.colors.button.base.background.active};
  }
`;

// TODO text-transform
export const NextButton = styled(BaseButton)`
  &&&& {
    width: 100%;
    text-transform: ${(props) => props.theme.fonts.buttonTextTransform};
    gap: 4px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      width: unset;
      padding: initial 40px;
    }

    background-color: ${(props) => props.theme.colors.button.base.background.default};
    color: ${(props) => props.theme.colors.white};
    path {
      fill: ${(props) => props.theme.colors.white};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      padding: 15px 40px;
    }

    &:not(:disabled) {
      ${defaultNotDisabledStyles}
    }
    &:disabled {
      cursor: not-allowed;
      background-color: ${(props) => props.theme.colors.grey[60]};
    }
  }
`;

const previousButtonInvertedStyle = css`
  path {
    fill: ${(props) => props.theme.colors.white};
  }

  &:not(:disabled) {
    ${defaultNotDisabledStyles}
  }
  &:disabled {
    cursor: not-allowed;
    path {
      fill: ${(props) => props.theme.colors.grey[60]};
    }
  }
`;

const previousButtonDefaultStyle = css`
  width: 54px;

  path {
    fill: ${(props) => props.theme.colors.button.base.background.default};
  }

  &:not(:disabled) {
    &:hover {
      background-color: ${(props) => props.theme.colors.grey[20]};
      path {
        fill: ${(props) => props.theme.colors.button.base.background.hover};
      }
    }
    &:active {
      background-color: ${(props) => props.theme.colors.grey[30]};
      path {
        fill: ${(props) => props.theme.colors.button.base.background.active};
      }
    }
    &:focus {
      &:not(:active) {
        background-color: ${(props) => props.theme.colors.grey[20]};
      }
      outline: 3px auto ${(props) => props.theme.colors.button.base.border.focus};
    }
  }
  &:disabled {
    cursor: not-allowed;
    path {
      fill: ${(props) => props.theme.colors.grey[60]};
    }
  }
`;

export const PreviousButton = styled(BaseButton)<{ invertedPreviousArrowColor?: boolean }>`
  &&&& {
    background: none;
    margin-right: 12px;
    ${(props) => (props.invertedPreviousArrowColor ? previousButtonInvertedStyle : previousButtonDefaultStyle)};
  }
`;
