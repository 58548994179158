import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Phone(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  return (
    <svg fill='none' height='12' viewBox='0 0 12 12' width='12' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m2.92536 5.17839c1.23257 2.10043 2.0543 2.94066 4.10861 4.20089l1.64345-1.68035c.83927.21923 1.89878.52015 2.74918.5166.2834-.00231.5658.28752.5734.56438l-.0191 2.62149c-.0012.2899-.2858.5809-.5746.5986-7.08123.0127-11.17481-4.17287-11.40493769-11.4271-.02350391-.280298.26112469-.57132264.54458069-.57252264h2.808067c.31005-.01224616.59237.27640764.65965.45271264-.07188.98314.33583 2.18237.55515 3.04549z'
        fill={props.color?.primary || theme.colors.icons.primary}
      />
    </svg>
  );
}
