import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function PreferenceIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M24.0499 9.06253C21.3991 9.05871 18.7955 9.76436 16.5093 11.1063C14.2232 12.4482 12.3378 14.3774 11.0489 16.6938C9.75994 19.0102 9.11438 21.6294 9.1792 24.2795C9.24402 26.9295 10.0169 29.514 11.4175 31.7646L7.04993 40.9375L16.2228 36.5685C18.1788 37.7851 20.3907 38.5309 22.684 38.7469C24.9774 38.963 27.2896 38.6435 29.4384 37.8136C31.5872 36.9837 33.5139 35.6661 35.0666 33.9646C36.6194 32.2631 37.7558 30.2242 38.3863 28.0087C39.0167 25.7931 39.124 23.4615 38.6997 21.1974C38.2753 18.9333 37.331 16.7987 35.941 14.9618C34.5511 13.1249 32.7535 11.6359 30.69 10.6122C28.6265 9.58849 26.3534 9.05799 24.0499 9.06253Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.0498 8.21252C26.4844 8.20795 28.8868 8.76876 31.0678 9.85077C33.2492 10.933 35.1495 12.5071 36.6189 14.4489C38.0883 16.3908 39.0866 18.6473 39.5352 21.0408C39.9837 23.4342 39.8703 25.8992 39.2038 28.2413C38.5374 30.5835 37.336 32.7388 35.6945 34.5375C34.0531 36.3363 32.0162 37.7292 29.7446 38.6065C27.4731 39.4838 25.0287 39.8216 22.6043 39.5932C20.3359 39.3795 18.1428 38.6755 16.1762 37.5322L7.41548 41.7049C7.09059 41.8597 6.70345 41.793 6.44897 41.5386C6.19449 41.2842 6.12782 40.897 6.28253 40.5721L10.4538 31.8115C9.12609 29.5286 8.3942 26.9454 8.3295 24.3002C8.26098 21.4985 8.94348 18.7295 10.3062 16.2805C11.6689 13.8316 13.6621 11.7919 16.0791 10.3732C18.4956 8.95477 21.2477 8.20874 24.0498 8.21252ZM24.05 9.06224L24.0487 9.91252C21.5496 9.90892 19.095 10.5742 16.9397 11.8393C14.7844 13.1044 13.0069 14.9233 11.7917 17.1071C10.5765 19.291 9.96789 21.7602 10.029 24.2587C10.0901 26.7571 10.8187 29.1937 12.1392 31.3155C12.2923 31.5615 12.3095 31.8684 12.185 32.13L8.84729 39.14L15.8574 35.8011C16.1189 35.6766 16.4259 35.6938 16.6718 35.8468C18.5161 36.9938 20.6015 37.697 22.7638 37.9007C24.9261 38.1044 27.1062 37.8031 29.1322 37.0206C31.1582 36.2382 32.9748 34.9958 34.4388 33.3916C35.9029 31.7873 36.9743 29.865 37.5688 27.7761C38.1632 25.6871 38.2643 23.4887 37.8643 21.3539C37.4642 19.2192 36.5738 17.2066 35.2633 15.4747C33.9527 13.7428 32.2579 12.3389 30.3123 11.3737C28.3667 10.4084 26.2235 9.90824 24.0516 9.91252L24.05 9.06224Z'
        fill={primaryColor}
      />
      <path
        d='M24.0499 31.1101L17.7104 24.4971C17.1529 23.9411 16.7845 23.2238 16.6574 22.4468C16.5303 21.6698 16.6508 20.8725 17.002 20.1678C17.2677 19.6361 17.6559 19.1752 18.1347 18.8231C18.6135 18.471 19.1691 18.2377 19.7558 18.1425C20.3425 18.0473 20.9434 18.0929 21.5089 18.2756C22.0745 18.4583 22.5885 18.7727 23.0087 19.1931L24.0443 20.2273L25.0799 19.1931C25.5 18.773 26.014 18.4588 26.5794 18.2763C27.1448 18.0938 27.7455 18.0483 28.3319 18.1435C28.9184 18.2387 29.4738 18.4719 29.9525 18.8238C30.4312 19.1757 30.8194 19.6364 31.0851 20.1678C31.4365 20.8724 31.5572 21.6698 31.4301 22.4468C31.3029 23.2239 30.9344 23.9412 30.3768 24.4971L24.0499 31.1101Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.2477 19.0845C20.8103 18.9432 20.3457 18.9079 19.892 18.9815C19.4383 19.0552 19.0086 19.2356 18.6383 19.5079C18.2682 19.7801 17.9681 20.1363 17.7626 20.5473C17.4911 21.0922 17.398 21.7087 17.4963 22.3095C17.5946 22.9105 17.8795 23.4653 18.3106 23.8953L18.324 23.9089L24.0493 29.8812L29.7626 23.9095L29.7767 23.8951C30.2079 23.4652 30.4929 22.9105 30.5912 22.3096C30.6895 21.7088 30.5963 21.0923 30.3246 20.5475C30.1191 20.1367 29.819 19.7807 29.449 19.5086C29.0788 19.2364 28.6493 19.0561 28.1958 18.9825C27.7423 18.9089 27.2777 18.9441 26.8405 19.0852C26.4032 19.2264 26.0058 19.4694 25.6809 19.7942L24.6449 20.8287C24.3131 21.1601 23.7755 21.1601 23.4437 20.8287L22.4081 19.7946C22.0832 19.4695 21.6851 19.2257 21.2477 19.0845ZM31.8458 19.7884C32.2768 20.6528 32.4249 21.6309 32.2689 22.5841C32.1136 23.5338 31.6642 24.4108 30.9844 25.0916L24.6641 31.6977C24.5039 31.8652 24.2822 31.96 24.0504 31.9601C23.8186 31.9602 23.5968 31.8657 23.4364 31.6983L17.103 25.0918C16.4232 24.4109 15.974 23.5338 15.8186 22.584C15.6626 21.6309 15.8105 20.653 16.2413 19.7886C16.5672 19.1365 17.0438 18.5703 17.6312 18.1384C18.2185 17.7064 18.9 17.4203 19.6197 17.3035C20.3393 17.1867 21.0764 17.2427 21.7702 17.4667C22.4637 17.6907 23.0941 18.0763 23.6093 18.5917L24.0443 19.026L24.4789 18.592C24.479 18.5919 24.4788 18.5921 24.4789 18.592C24.9942 18.0769 25.6249 17.6912 26.3183 17.4674C27.0119 17.2436 27.7487 17.1877 28.4681 17.3045C29.1875 17.4212 29.8689 17.7073 30.456 18.139C31.0432 18.5707 31.5198 19.1366 31.8458 19.7884Z'
        fill={primaryColor}
      />
    </svg>
  );
}
