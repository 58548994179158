import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.8em;
  height: 1.4em;
  color: ${(props) => props.theme.colors.text.base};
  cursor: pointer;
  transition: all 0.1s ease;
  gap: 4px;

  &:before,
  &:after {
    content: '';
    font-size: 14px;
    box-sizing: border-box;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border-radius: 4px;
  }

  &:before {
    border: 1px solid ${(props) => props.theme.colors.input.border.default};
  }

  &:after {
    border: 1px solid transparent;
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;

  &:checked + ${Label}:before {
    color: ${(props) => props.theme.colors.text.lightest};
    background-color: ${(props) => props.theme.colors.base.primary};
    box-shadow: 0 0 0 0.75px ${(props) => props.theme.colors.text.lightest};
    border-color: ${(props) => props.theme.colors.base.primary};
  }

  &:checked + ${Label}:after {
    content: '\\2713';
    color: ${(props) => props.theme.colors.text.lightest};
    font-weight: 700;
  }

  &:focus + ${Label}:before {
    border-color: ${(props) => props.theme.colors.base.primary};
    border-width: '2px';
  }

  input:hover + ${Label}:before {
    border-color: ${(props) => props.theme.colors.input.border.hover};
  }

  &:disabled + ${Label} {
    pointer-events: none;
  }
`;
