import { ReactElement } from 'react';
import _ from 'lodash';
import { Permission } from '@breathelife/types';

type Props = {
  requiredPermissions?: Permission[];
  applicablePermissions: Permission[];
  children?: ReactElement;
};

export function isAllowed(
  requiredPermissions: Permission | Permission[] | undefined,
  applicablePermissions: Permission[],
): boolean {
  if (!Array.isArray(applicablePermissions) || !applicablePermissions.length) return false;

  if (!requiredPermissions || (Array.isArray(requiredPermissions) && !requiredPermissions.length)) return true;

  if (Array.isArray(requiredPermissions)) {
    return _.intersection(applicablePermissions, requiredPermissions).length > 0;
  }

  return false;
}

export const RestrictedToPermissions = (props: Props): ReactElement | null => {
  const { requiredPermissions, applicablePermissions, children } = props;

  if (!isAllowed(requiredPermissions, applicablePermissions) || !children) return null;

  return children;
};
