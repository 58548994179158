import styled from 'styled-components';
import { Icon } from '../../Components/Icon/Icon';
import { IconName } from '@breathelife/types';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorPageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

type GlobalAppErrorPageProps = {
  line1?: string;
  line2?: string;
};

export function GlobalAppErrorPage(props: GlobalAppErrorPageProps): React.ReactElement {
  const { line1, line2 } = props;
  return (
    <ErrorPageContainer>
      <ErrorContainer>
        <Icon name={IconName.error404} />
        <p>{line1}</p>
        <p>{line2}</p>
      </ErrorContainer>
    </ErrorPageContainer>
  );
}
