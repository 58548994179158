import {
  ReactElement,
  createContext,
  useContext,
  useState,
  useMemo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
  DraggableProvided,
  DraggableProvidedDragHandleProps,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd';
import { Virtuoso, ItemProps as VirtuosoItemProps } from 'react-virtuoso';
import styled from 'styled-components';

import { IconName } from '@breathelife/types';

import { Icon } from '../Icon/Icon';
import { TreeViewItem, FormattedTreeViewItemData, TreeViewItemStyle, LevelStyleData } from './TreeViewItem';

export const TREE_VIEW_SPLIT_PATH_CHARACTERS = '-//_-';

export type UnformattedTreeViewItemData = {
  label: string;
  identifier: string;
  iconName?: IconName;
  iconWidth?: number;
  forceExpand?: boolean;
  forceHide?: boolean;
  metadata: unknown;
  bulletIconColor?: string;
};

export type TreeViewRepositionOptions = {
  newParentPath?: string[];
  newSiblingPath?: string[];
};

export type TreeViewItemRenderChildProps = {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  data: FormattedTreeViewItemData;
  snapshot?: DraggableStateSnapshot;
};

type ExpandingContextValue = {
  toggleItemExpansion: (itemPathIdentifier: string) => void;
  expandedItemIdentifiers: string[];
  itemHeight: number;
  canDrag: boolean;
  hideDefaultDragHandle?: boolean;
};

type ItemProps = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  data: FormattedTreeViewItemData;
  renderChild?: (props: TreeViewItemRenderChildProps) => ReactElement | null;
  isDraggedCopy?: boolean;
  isFullItemClickable?: boolean;
};

const DEFAULT_ITEM_HEIGHT = 40;

const ExpandingContext = createContext<ExpandingContextValue>({
  toggleItemExpansion: () => {},
  expandedItemIdentifiers: [],
  itemHeight: DEFAULT_ITEM_HEIGHT,
  canDrag: false,
});

const DraggableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

function TreeViewDraggableItem({
  provided,
  snapshot,
  data,
  renderChild,
  isDraggedCopy,
  isFullItemClickable,
}: ItemProps): ReactElement {
  const { toggleItemExpansion, hideDefaultDragHandle, canDrag } = useContext(ExpandingContext);

  return (
    <DraggableWrapper
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
      }}
    >
      <TreeViewItem
        {...data}
        onToggleExpansion={toggleItemExpansion}
        hideTicks={isDraggedCopy}
        isFullItemClickable={isFullItemClickable}
      >
        {renderChild && renderChild({ dragHandleProps: provided.dragHandleProps || undefined, snapshot, data })}
      </TreeViewItem>
      {!hideDefaultDragHandle && canDrag && (
        <div {...provided.dragHandleProps} style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name={IconName.dragHandle} size='20px' />
        </div>
      )}
    </DraggableWrapper>
  );
}

function HeightPreservingItem({ children, ...props }: PropsWithChildren<VirtuosoItemProps<any>>): ReactElement {
  return (
    // this is needed so that virtuoso properly calculates the placement of items
    <div {...props} style={{ height: props['data-known-size'] || undefined }}>
      {children}
    </div>
  );
}

type TreeViewProps<TUnknownTreeViewItemData = unknown> = {
  data: TUnknownTreeViewItemData[];
  getDataItemFromUnknownItem: (item: TUnknownTreeViewItemData, currentPath: string[]) => UnformattedTreeViewItemData;
  getUnknownItemChildren: (
    item: TUnknownTreeViewItemData,
    itemData: UnformattedTreeViewItemData,
  ) => TUnknownTreeViewItemData[];
  getNewParentPathFromPreviousTreeItemPath: (
    movedItemPath: string[],
    previousItemPath: string[],
    previousItemIsOpen?: boolean,
  ) => string[];
  shouldExpandChildrenOnItemExpansion?: (itemPath: string[]) => boolean;
  treeName: string;
  onDragEnd?: (sourceIdentifier?: string[], options?: TreeViewRepositionOptions) => void;
  itemStyle?: Omit<TreeViewItemStyle, 'levelStyles'>;
  renderChild?: (props: TreeViewItemRenderChildProps) => ReactElement | null;
  hideDefaultDragHandle?: boolean;
  canDropInsideOtherElement?: boolean;
  onDragUpdate?: (sourceIdentifier: string[], isValid: boolean, options?: TreeViewRepositionOptions) => void;
  onDragStart?: (draggedItemPath: string[]) => void;
  forceScrollToItemPathIdentifier?: string;
  onForceScrollToItemEnd?: () => void;
  pathPrefix?: string[];
  isFullItemClickable?: boolean;
};

export function TreeView<TUnknownTreeViewItemData = unknown>({
  data,
  treeName,
  onDragEnd,
  itemStyle,
  renderChild,
  hideDefaultDragHandle,
  getDataItemFromUnknownItem,
  getUnknownItemChildren,
  getNewParentPathFromPreviousTreeItemPath,
  shouldExpandChildrenOnItemExpansion,
  canDropInsideOtherElement,
  onDragUpdate,
  onDragStart,
  forceScrollToItemPathIdentifier,
  onForceScrollToItemEnd,
  pathPrefix,
  isFullItemClickable,
}: TreeViewProps<TUnknownTreeViewItemData>): ReactElement {
  const [expandedItemIdentifiers, setExpandedItemIdentifiers] = useState<string[]>([]);
  const [forceChildrenOpenItemPathIdentifier, setForceChildrenOpenItemPathIdentifier] = useState<string | undefined>();
  const actualItemHeight = itemStyle?.height || DEFAULT_ITEM_HEIGHT;

  const virtuoso = useRef(null);

  const formattedData: FormattedTreeViewItemData[] = useMemo(() => {
    if (!getDataItemFromUnknownItem) {
      return [];
    }
    return data.reduce(
      (
        acc: FormattedTreeViewItemData[],
        rootItem: TUnknownTreeViewItemData,
        rootIndex: number,
        rootList: TUnknownTreeViewItemData[],
      ) => {
        const pushItemAndChildrenToAcc = (
          currentUnformattedItem: TUnknownTreeViewItemData,
          level: number,
          levelStyles: LevelStyleData[],
          previousInListIsExpanded: boolean,
          currentPath: string[],
        ): void => {
          const formattedDataItem = getDataItemFromUnknownItem(currentUnformattedItem, currentPath);
          const newPath = [...currentPath, formattedDataItem.identifier];
          const pathIdentifier = newPath.join(TREE_VIEW_SPLIT_PATH_CHARACTERS);
          const isExpanded = formattedDataItem.forceExpand || expandedItemIdentifiers.includes(pathIdentifier);
          const itemChildren = getUnknownItemChildren(currentUnformattedItem, formattedDataItem);
          const hasChildren = !!(itemChildren && itemChildren.length);
          if (!formattedDataItem.forceHide) {
            acc.push({
              identifier: formattedDataItem.identifier,
              pathIdentifier,
              label: formattedDataItem.label,
              iconName: formattedDataItem.iconName,
              level,
              metadata: formattedDataItem.metadata,
              hasChildren,
              previousInListIsExpanded,
              isExpanded,
              isForceExpanded: formattedDataItem.forceExpand,
              path: newPath,
              style: {
                ...itemStyle,
                height: actualItemHeight,
                iconWidth: formattedDataItem.iconWidth,
                bulletIconColor: formattedDataItem.bulletIconColor,
                levelStyles,
              },
            });
          }
          if (hasChildren && isExpanded) {
            itemChildren.forEach((childItem, index, list) => {
              pushItemAndChildrenToAcc(
                childItem,
                level + 1,
                [...levelStyles, { isLast: index === list.length - 1 }],
                false,
                newPath,
              );
            });
          }
        };

        pushItemAndChildrenToAcc(
          rootItem,
          0,
          [{ isLast: rootIndex === rootList.length - 1 }],
          Boolean(
            rootIndex > 0 &&
              expandedItemIdentifiers.includes(getDataItemFromUnknownItem(rootList[rootIndex - 1], []).identifier),
          ),
          [...(pathPrefix || [])],
        );

        return acc;
      },
      [],
    );
  }, [
    actualItemHeight,
    data,
    expandedItemIdentifiers,
    itemStyle,
    getDataItemFromUnknownItem,
    getUnknownItemChildren,
    pathPrefix,
  ]);

  useEffect(() => {
    if (forceScrollToItemPathIdentifier) {
      const itemIndex = formattedData.findIndex((item) => {
        return item.pathIdentifier === forceScrollToItemPathIdentifier;
      });
      if (itemIndex > -1 && virtuoso.current) {
        (
          virtuoso.current as unknown as { scrollToIndex: (data: { index: number; align: 'center' }) => void }
        ).scrollToIndex({
          index: itemIndex,
          align: 'center',
        });
      }
      onForceScrollToItemEnd?.();
    }
  }, [forceScrollToItemPathIdentifier, formattedData, onForceScrollToItemEnd]);

  useEffect(() => {
    if (forceChildrenOpenItemPathIdentifier) {
      const path = forceChildrenOpenItemPathIdentifier.split(TREE_VIEW_SPLIT_PATH_CHARACTERS);

      const childrenItems = formattedData.filter((item) => {
        return JSON.stringify(item.path.slice(0, path.length)) === JSON.stringify(path);
      });

      const newExpandedElementPathIdentifiers: string[] = [];

      childrenItems.forEach((childItem) => {
        const isItemExpanded = expandedItemIdentifiers.includes(childItem.pathIdentifier);
        if (!isItemExpanded && childItem.hasChildren) {
          newExpandedElementPathIdentifiers.push(childItem.pathIdentifier);
        }
      });

      if (newExpandedElementPathIdentifiers.length > 0) {
        setExpandedItemIdentifiers((prev) => [...prev, ...newExpandedElementPathIdentifiers]);
      }
    }
  }, [forceChildrenOpenItemPathIdentifier, formattedData, expandedItemIdentifiers]);

  function toggleItemExpansion(itemPathIdentifier: string, forcedState?: 'open' | 'closed'): void {
    setExpandedItemIdentifiers((prev) => {
      const isItemExpanded = expandedItemIdentifiers.includes(itemPathIdentifier);
      if (isItemExpanded && forcedState !== 'open') {
        if (shouldExpandChildrenOnItemExpansion) {
          setForceChildrenOpenItemPathIdentifier(undefined);
        }
        return prev.filter((item) => item !== itemPathIdentifier);
      }
      if (!isItemExpanded && forcedState !== 'closed') {
        if (shouldExpandChildrenOnItemExpansion?.(itemPathIdentifier.split(TREE_VIEW_SPLIT_PATH_CHARACTERS))) {
          setForceChildrenOpenItemPathIdentifier(itemPathIdentifier);
        } else {
          setForceChildrenOpenItemPathIdentifier(undefined);
        }

        return [...prev, itemPathIdentifier];
      }
      return prev;
    });
  }

  const count = formattedData.length;

  const getDragRepositionOptions = useCallback(
    (sourcePathIdentifier: string, sourcePosition?: number, newPosition?: number): TreeViewRepositionOptions => {
      const sourcePath = sourcePathIdentifier.split(TREE_VIEW_SPLIT_PATH_CHARACTERS);

      // Handle dragging to the very start of the list
      if (newPosition === 0) {
        return {};
      }

      if (!newPosition || sourcePosition === undefined) {
        throw new Error('Not enough information about this reposition');
      }
      const positionOfItemBeforeNewPosition = sourcePosition < newPosition ? newPosition : newPosition - 1;
      const newSibling = formattedData[positionOfItemBeforeNewPosition];
      const newSiblingPath = newSibling?.path || pathPrefix || [];

      const newSiblingPathIdentifier = newSibling.pathIdentifier;

      try {
        // Rely on parent component to determine new parent from drag sibling

        const newParentPath =
          getNewParentPathFromPreviousTreeItemPath(
            sourcePath,
            newSiblingPath,
            expandedItemIdentifiers.includes(newSiblingPathIdentifier),
          ) ||
          pathPrefix ||
          [];

        // Case 1: Item was dragged right below its new parent
        const itemWasDraggedAtBeginningOfParentChildren = newSiblingPath.join('') === newParentPath.join('');

        if (itemWasDraggedAtBeginningOfParentChildren) {
          return { newParentPath };
        }

        // Case 2: Item was dragged below an item that has the determined new parent as parent
        // and not between it and its children
        const newSiblingParentPath = [...newSiblingPath];
        newSiblingParentPath.length -= 1;

        if (
          newSiblingParentPath.join('') === newParentPath.join('') &&
          !expandedItemIdentifiers.includes(newSiblingPath.join(TREE_VIEW_SPLIT_PATH_CHARACTERS))
        ) {
          return { newParentPath, newSiblingPath };
        }

        // Case 3: Item was dragged at the end of a nested list of children
        const pathOfItemAfterNewSibling = formattedData[positionOfItemBeforeNewPosition + 1]?.path;
        const itemWasDraggedAtTheEndOfAChildrenList =
          !pathOfItemAfterNewSibling || pathOfItemAfterNewSibling.length === newParentPath.length + 1;

        if (itemWasDraggedAtTheEndOfAChildrenList) {
          const computedNewSiblingPath = [...newSiblingPath];
          computedNewSiblingPath.length = newParentPath.length + 1;
          return { newParentPath, newSiblingPath: computedNewSiblingPath };
        }
        throw new Error();
      } catch {
        throw new Error('Invalid reposition request');
      }
    },
    [expandedItemIdentifiers, formattedData, getNewParentPathFromPreviousTreeItemPath, pathPrefix],
  );

  function handleDragEnd(result: DropResult): void {
    const sourcePathIdentifier = result.draggableId;

    if (!onDragEnd) {
      return;
    }
    if (!sourcePathIdentifier) {
      onDragEnd();
    }

    const sourcePath = sourcePathIdentifier.split(TREE_VIEW_SPLIT_PATH_CHARACTERS);

    // Whether we are nesting the dragged item into the item it is dropped on
    const combineWithIdentifier = result.combine?.draggableId;

    // Handle dragging into another item
    if (combineWithIdentifier) {
      const combineWithPath = combineWithIdentifier.split(TREE_VIEW_SPLIT_PATH_CHARACTERS);
      onDragEnd(sourcePath, { newParentPath: combineWithPath });
      toggleItemExpansion(combineWithIdentifier, 'open');
      return;
    }

    const newPosition = result.destination?.index;
    const sourcePosition = result.source?.index;
    try {
      const repositionOptions = getDragRepositionOptions(sourcePathIdentifier, sourcePosition, newPosition);
      if (repositionOptions) {
        onDragEnd(sourcePath, repositionOptions);
      }
    } catch {
      onDragEnd();
    }
  }

  const canDrag = !!onDragEnd;

  return (
    <ExpandingContext.Provider
      value={{
        toggleItemExpansion,
        expandedItemIdentifiers,
        itemHeight: actualItemHeight,
        canDrag,
        hideDefaultDragHandle,
      }}
    >
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={(draggedItem) => {
          if (!onDragStart || !draggedItem.draggableId) {
            return;
          }

          onDragStart(draggedItem.draggableId.split(TREE_VIEW_SPLIT_PATH_CHARACTERS));
        }}
        onDragUpdate={({ source, destination }) => {
          if (!onDragUpdate || !destination) {
            return;
          }
          const draggedItemPath = formattedData[source.index].path;
          const destinationIndex = destination.index;
          try {
            const dragRepositionOptions = getDragRepositionOptions(
              formattedData[source.index].pathIdentifier,
              source.index,
              destinationIndex,
            );
            onDragUpdate(draggedItemPath, true, dragRepositionOptions);
          } catch {
            onDragUpdate(draggedItemPath, false);
          }
        }}
      >
        <Droppable
          isDropDisabled={!canDrag}
          droppableId={treeName}
          mode='virtual'
          isCombineEnabled={canDropInsideOtherElement}
          renderClone={(provided, snapshot, rubric) => {
            return (
              <TreeViewDraggableItem
                provided={provided}
                data={formattedData[rubric.source.index]}
                snapshot={snapshot}
                renderChild={renderChild}
                isDraggedCopy
                isFullItemClickable={isFullItemClickable}
              />
            );
          }}
        >
          {(droppableProvided) => {
            return (
              <Virtuoso
                data={formattedData}
                components={{
                  Item: HeightPreservingItem,
                }}
                fixedItemHeight={actualItemHeight}
                totalCount={count}
                ref={virtuoso}
                scrollerRef={droppableProvided.innerRef as unknown as (ref: HTMLElement | Window | null) => void}
                itemContent={(index, item) => {
                  return (
                    <Draggable
                      draggableId={item.pathIdentifier}
                      index={index}
                      key={item.pathIdentifier}
                      isDragDisabled={!canDrag}
                    >
                      {(provided, snapshot) => (
                        <TreeViewDraggableItem
                          provided={provided}
                          snapshot={snapshot}
                          data={item}
                          renderChild={renderChild}
                          isFullItemClickable={isFullItemClickable}
                        />
                      )}
                    </Draggable>
                  );
                }}
              />
            );
          }}
        </Droppable>
      </DragDropContext>
    </ExpandingContext.Provider>
  );
}
