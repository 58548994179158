import { ReactNode, ReactElement } from 'react';

import {
  Container,
  Content,
  CenteredContent,
  Section,
  MainContent,
  MainContentWrapper,
  LeftSectionContainer,
  Footer,
} from './Styles';

export type PlanFinderBaseLayoutProps = {
  header: ReactNode;
  footer?: ReactNode;
  leftSectionContent: ReactNode;
  rightSectionContent: ReactNode;
  popup?: ReactNode;
};

export function PlanFinderBaseLayout(props: PlanFinderBaseLayoutProps): ReactElement {
  return (
    <Container>
      {props.popup}
      {props.header}

      <Content role='main'>
        <CenteredContent>
          <LeftSectionContainer>
            {props.leftSectionContent && <Section>{props.leftSectionContent}</Section>}
          </LeftSectionContainer>

          {props.rightSectionContent && (
            <MainContent>
              <MainContentWrapper>{props.rightSectionContent}</MainContentWrapper>
            </MainContent>
          )}
        </CenteredContent>
      </Content>
      {props.footer && <Footer role='contentinfo'>{props.footer}</Footer>}
    </Container>
  );
}
