export const accents = {
  hyperlink: '#0E85FB',
  error: '#EB5757', // This color comes from lead-platform's red color theme red[50], see shared/lead-platform/src/Styles/MuiTheme.ts
  success: '#39B54A',
  warning: '#DE7F30',
  sent: '#1d8046',
  info: '#2B4177',
  paragraph: '#E6E6E6',
};

export const coreGreys = {
  100: '#000000',
  90: '#333333',
  80: '#666666',
  70: '#757575',
  60: '#999999',
  50: '#bcbcbc',
  40: '#cccccc',
  30: '#e6e6e6',
  20: '#eeeeee',
  10: '#f6f6f6',
  0: '#ffffff',
};

export const corePrimary = {
  default: '#476CC6',
  90: '#1C2B4F',
  80: '#1C2B4F',
  70: '#2B4177',
  60: '#39569E',
  50: '#476CC6',
  40: '#6C89D1',
  30: '#DAE2F4',
  20: '#EDF0F9',
  10: '#EDF0F9',
};

export const coreSecondary = {
  default: '#FFCE84',
  90: '#665235',
  80: '#665235',
  70: '#997C4F',
  60: '#CCA56A',
  50: '#FFCE84',
  40: '#FFE2B5',
  30: '#FFF5E6',
  20: '#FFFAF3',
  10: '#FFFAF3',
};

export const coreTertiary = {
  default: '#EF86A1',
  90: '#603640',
  80: '#603640',
  70: '#8F5061',
  60: '#BF6B81',
  50: '#EF86A1',
  40: '#F5B6C7',
  30: '#FCE7EC',
  20: '#FDF3F6',
  10: '#FDF3F6',
};
