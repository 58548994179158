import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ChecklistIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  const tertiaryColor = props.color?.tertiary || theme.colors.icons.tertiary;

  return (
    <svg viewBox='0 0 49 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24.1394' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M11.9569 39.1267H20.2911H24.5808L36.9903 25.3384V18.2604L28.1934 8.48305C27.8028 8.0927 27.2732 7.87338 26.721 7.87326H11.9569C11.4043 7.87326 10.8743 8.09278 10.4836 8.48352C10.0928 8.87427 9.87329 9.40423 9.87329 9.95683V37.0432C9.87329 37.5958 10.0928 38.1257 10.4836 38.5165C10.8743 38.9072 11.4043 39.1267 11.9569 39.1267Z'
        fill='#F8F9FD'
      />
      <path
        d='M26.5418 7.87326V16.2075C26.5418 16.7601 26.7613 17.2901 27.1521 17.6808C27.5428 18.0716 28.0728 18.2911 28.6254 18.2911H36.9596'
        fill={tertiaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.5418 7C27.0241 7 27.4151 7.39097 27.4151 7.87326V16.2075C27.4151 16.5285 27.5426 16.8364 27.7696 17.0633C27.9966 17.2903 28.3044 17.4178 28.6254 17.4178H36.9597C37.442 17.4178 37.8329 17.8088 37.8329 18.2911C37.8329 18.7734 37.442 19.1643 36.9597 19.1643H28.6254C27.8412 19.1643 27.0891 18.8528 26.5346 18.2983C25.9801 17.7438 25.6686 16.9917 25.6686 16.2075V7.87326C25.6686 7.39097 26.0596 7 26.5418 7Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9568 8.74652C11.6358 8.74652 11.328 8.87403 11.101 9.10101C10.874 9.32799 10.7465 9.63583 10.7465 9.95682V37.0432C10.7465 37.3642 10.874 37.672 11.101 37.899C11.328 38.126 11.6358 38.2535 11.9568 38.2535H20.2911C20.7734 38.2535 21.1643 38.6445 21.1643 39.1267C21.1643 39.609 20.7734 40 20.2911 40H11.9568C11.1726 40 10.4205 39.6885 9.86603 39.134C9.31152 38.5795 9 37.8274 9 37.0432V9.95682C9 9.17263 9.31152 8.42055 9.86603 7.86603C10.4205 7.31152 11.1726 7 11.9568 7H26.721C27.5046 7.00017 28.2563 7.31142 28.8106 7.86536C28.8216 7.87628 28.8322 7.88749 28.8425 7.89897L37.6394 17.6764C37.962 18.0349 37.9329 18.587 37.5743 18.9096C37.2158 19.2322 36.6636 19.2031 36.3411 18.8445L27.5618 9.08668C27.3364 8.86877 27.035 8.74663 26.721 8.74652C26.7209 8.74652 26.721 8.74652 26.721 8.74652H11.9568Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7026 19.4788C21.0884 19.7683 21.1665 20.3156 20.8771 20.7014L17.9157 24.6491C17.751 24.8684 17.5408 25.0501 17.3 25.1813C17.0591 25.3124 16.7929 25.3903 16.5193 25.4097C16.2458 25.4291 15.9712 25.3895 15.7143 25.2935C15.4574 25.1977 15.2242 25.0477 15.0303 24.8539L13.4232 23.2482C13.082 22.9073 13.0817 22.3544 13.4226 22.0132C13.7635 21.672 14.3164 21.6718 14.6576 22.0127L16.2647 23.6184C16.2818 23.6355 16.3025 23.6489 16.3251 23.6573C16.3477 23.6658 16.3719 23.6693 16.396 23.6676C16.4201 23.6659 16.4435 23.659 16.4647 23.6475C16.4859 23.6359 16.5043 23.62 16.5188 23.6007C16.5188 23.6007 16.5188 23.6007 16.5188 23.6007L19.48 19.6534C19.7694 19.2676 20.3168 19.1894 20.7026 19.4788Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.7026 28.8549C21.0884 29.1443 21.1665 29.6917 20.8771 30.0775L17.9157 34.0251C17.751 34.2444 17.5408 34.4262 17.3 34.5573C17.0591 34.6885 16.7929 34.7664 16.5193 34.7858C16.2458 34.8051 15.9712 34.7655 15.7143 34.6696C15.4573 34.5737 15.224 34.4237 15.0301 34.2298L13.4229 32.6226C13.0819 32.2815 13.0819 31.7286 13.4229 31.3876C13.7639 31.0466 14.3168 31.0466 14.6579 31.3876L16.2649 32.9947C16.2649 32.9947 16.2649 32.9947 16.2649 32.9947C16.282 33.0117 16.3026 33.0249 16.3251 33.0334C16.3477 33.0418 16.3719 33.0453 16.396 33.0436C16.4201 33.0419 16.4435 33.035 16.4647 33.0235C16.4859 33.012 16.5043 32.996 16.5188 32.9767C16.5188 32.9767 16.5188 32.9768 16.5188 32.9767L19.48 29.0294C19.7694 28.6436 20.3168 28.5655 20.7026 28.8549Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.585 24.5418C23.585 24.0595 23.9759 23.6685 24.4582 23.6685H29.6671C30.1494 23.6685 30.5404 24.0595 30.5404 24.5418C30.5404 25.0241 30.1494 25.415 29.6671 25.415H24.4582C23.9759 25.415 23.585 25.0241 23.585 24.5418Z'
        fill={primaryColor}
      />
      <path
        d='M42.5305 27.3964L32.4483 40.214L26.956 42.0114L27.4093 36.2503L37.4915 23.4328C38.0155 22.7666 38.7828 22.3358 39.6244 22.2353C40.466 22.1348 41.3131 22.3727 41.9793 22.8967L41.9944 22.9086C42.6606 23.4326 43.0914 24.1999 43.1919 25.0415C43.2924 25.8831 43.0545 26.7302 42.5305 27.3964Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.7371 23.1786C39.1456 23.2492 38.6064 23.5519 38.2382 24.0201L28.3338 36.6116L28.0149 40.6653L31.8794 39.4006L41.7838 26.8091C42.1521 26.3409 42.3193 25.7456 42.2486 25.1542C42.178 24.5627 41.8753 24.0236 41.4071 23.6553C41.4071 23.6553 41.4071 23.6553 41.4071 23.6553L41.392 23.6434L41.392 23.6434C40.9238 23.2751 40.3285 23.1079 39.7371 23.1786ZM42.5619 27.4211L43.2772 27.9838L33.195 40.8013C33.079 40.9487 32.922 41.0585 32.7438 41.1169L27.2515 42.9143C26.9496 43.0131 26.6184 42.9545 26.3687 42.7581C26.1191 42.5617 25.9841 42.2536 26.009 41.9369L26.4622 36.1758C26.4769 35.9888 26.5466 35.8104 26.6626 35.663L36.7448 22.8454L36.7448 22.8454C37.4246 21.9812 38.4199 21.4224 39.5117 21.292C40.6035 21.1616 41.7024 21.4702 42.5666 22.15C42.5666 22.15 42.5666 22.15 42.5666 22.15L42.5817 22.1619L42.5818 22.1619C43.446 22.8417 44.0048 23.837 44.1352 24.9288C44.2656 26.0206 43.957 27.1195 43.2772 27.9837L42.5619 27.4211Z'
        fill={primaryColor}
      />
    </svg>
  );
}
