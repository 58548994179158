import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { GroupIcon } from '@breathelife/mui';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function Group(props: SVGIconProps & { size?: string }): ReactElement {
  const theme = useTheme() as SharedTheme;

  return (
    <GroupIcon style={{ color: props.color?.primary || theme.colors.icons.primary, fontSize: props.size || '16px' }} />
  );
}
