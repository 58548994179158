import { Language } from '@breathelife/types';

import { OnSubmitScheduleDataType } from '../../ContactForm';
import { CloudinaryProps } from '../../Images/Image';
import { SchedulingTimeFrames } from '../../ScheduleTimeTable/ScheduleTimeTable';
import { DayOfTheWeek } from '../../ScheduleTimeTable/Weekdays';
import { LocalizationStrings } from './Localization';

export enum ViewTabs {
  callUs,
  callYou,
}

export type ContactPersonInfo = { name: string; title: string; imgUrl?: string };

export type OpeningHoursType = Partial<Record<DayOfTheWeek, string>>;

export type FormProps = Partial<OnSubmitScheduleDataType> & {
  schedulingTimeFrames: SchedulingTimeFrames;
  onSubmitSchedule: (onSubmitContactArgs: OnSubmitScheduleDataType) => void;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
};

export type BaseContactAdvisorModalProps = {
  form: FormProps;
  language: Language;
  localizationStrings: LocalizationStrings;
  customerServicePhoneNumber?: string;
  customerServiceEmail?: string;
  carrierContactPhoneNumber?: string;
  carrierContactEmail?: string;
  contactPersonInfo?: ContactPersonInfo;
  securityDisclosure?: string;
  cloudinaryProps?: CloudinaryProps;
  openingHours?: OpeningHoursType;
  referenceNumber?: string;
  useMobileStylingOnDesktop?: boolean;
  hideEmail?: boolean;
  hidePhone?: boolean;
  isPlural?: boolean;
};
