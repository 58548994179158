import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function ConfirmationIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M24.0001 39.142C32.3628 39.142 39.1421 32.3627 39.1421 24C39.1421 15.6373 32.3628 8.85805 24.0001 8.85805C15.6374 8.85805 8.85815 15.6373 8.85815 24C8.85815 32.3627 15.6374 39.142 24.0001 39.142Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 9.71609C16.1112 9.71609 9.71609 16.1112 9.71609 24C9.71609 31.8888 16.1112 38.2839 24 38.2839C31.8888 38.2839 38.2839 31.8888 38.2839 24C38.2839 16.1112 31.8888 9.71609 24 9.71609ZM8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.2394 18.3814C32.7291 18.7506 32.8269 19.4468 32.4577 19.9365L25.0074 29.8203C24.7458 30.166 24.4131 30.4514 24.032 30.6578C23.651 30.8642 23.2302 30.9867 22.798 31.0175C22.3658 31.0483 21.9319 30.9866 21.5254 30.8365C21.119 30.6864 20.7491 30.4512 20.4409 30.1464L20.4386 30.1441L16.5923 26.3178C16.1575 25.8853 16.1557 25.1822 16.5882 24.7474C17.0207 24.3127 17.7238 24.3108 18.1586 24.7433L22.0026 28.5674C22.0029 28.5677 22.0032 28.568 22.0035 28.5683C22.0859 28.6496 22.1852 28.7127 22.2949 28.7532C22.405 28.7939 22.5228 28.8107 22.6402 28.8023C22.7576 28.794 22.8716 28.7607 22.9745 28.7049C23.0771 28.6494 23.1661 28.5729 23.2359 28.481C23.2361 28.4807 23.2357 28.4813 23.2359 28.481L30.6843 18.5997C31.0534 18.11 31.7497 18.0123 32.2394 18.3814Z'
        fill={primaryColor}
      />
    </svg>
  );
}
