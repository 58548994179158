import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function DrugsIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path
        d='M10.7538 18.1289C10.7538 20.3502 11.6362 22.4804 13.2068 24.0511C14.7775 25.6217 16.9077 26.5041 19.1289 26.5041C21.3502 26.5041 23.4804 25.6217 25.0511 24.0511C26.6217 22.4804 27.5041 20.3502 27.5041 18.1289C27.5041 15.9077 26.6217 13.7775 25.0511 12.2068C23.4804 10.6362 21.3502 9.75378 19.1289 9.75378C16.9077 9.75378 14.7775 10.6362 13.2068 12.2068C11.6362 13.7775 10.7538 15.9077 10.7538 18.1289V18.1289Z'
        fill='#F8F9FD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6738 11.6738C14.3858 9.96179 16.7078 9 19.1289 9C21.5501 9 23.872 9.96179 25.5841 11.6738C27.2961 13.3858 28.2579 15.7078 28.2579 18.1289C28.2579 20.5501 27.2961 22.872 25.5841 24.5841C23.872 26.2961 21.5501 27.2579 19.1289 27.2579C16.7078 27.2579 14.3858 26.2961 12.6738 24.5841C10.9618 22.872 10 20.5501 10 18.1289C10 15.7078 10.9618 13.3858 12.6738 11.6738ZM19.1289 10.5075C17.1076 10.5075 15.1691 11.3105 13.7398 12.7398C12.3105 14.1691 11.5075 16.1076 11.5075 18.1289C11.5075 20.1502 12.3105 22.0888 13.7398 23.5181C15.1691 24.9474 17.1076 25.7503 19.1289 25.7503C21.1502 25.7503 23.0888 24.9474 24.5181 23.5181C25.9474 22.0888 26.7503 20.1502 26.7503 18.1289C26.7503 16.1076 25.9474 14.1691 24.5181 12.7398C23.0888 11.3105 21.1502 10.5075 19.1289 10.5075Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3752 16.4539H11.6654C11.6654 16.4539 11.5076 16.8727 11.5076 18.129C11.5076 19.3852 11.6654 19.804 11.6654 19.804H18.3752H19.0452H26.575C26.575 19.804 26.7503 19.3852 26.7503 18.129C26.7503 16.8727 26.575 16.4539 26.575 16.4539H19.0452H18.3752Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2637 16.0351C10.2637 15.6188 10.6011 15.2814 11.0174 15.2814H27.2401C27.6564 15.2814 27.9939 15.6188 27.9939 16.0351C27.9939 16.4514 27.6564 16.7889 27.2401 16.7889H11.0174C10.6011 16.7889 10.2637 16.4514 10.2637 16.0351Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2637 20.2226C10.2637 19.8063 10.6011 19.4689 11.0174 19.4689H27.2401C27.6564 19.4689 27.9939 19.8063 27.9939 20.2226C27.9939 20.6389 27.6564 20.9764 27.2401 20.9764H11.0174C10.6011 20.9764 10.2637 20.6389 10.2637 20.2226Z'
        fill={primaryColor}
      />
      <path
        d='M21.6875 32.1804L26.2938 27.1553L26.7126 26.7365L33.8315 34.2742L32.5752 35.9492L26.7126 40.1368L22.9438 38.8805L20.85 35.9492L21.6875 32.1804Z'
        fill={props.color?.tertiary || theme.colors.icons.tertiary}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.6899 21.7367C36.1319 21.5113 35.5343 21.4006 34.9327 21.4112C34.3311 21.4219 33.7378 21.5535 33.1881 21.7984C32.6385 22.0433 32.1438 22.3964 31.7336 22.8367C31.7276 22.8431 31.7214 22.8495 31.7151 22.8558L22.8417 31.7293C22.0557 32.5775 21.6281 33.697 21.6485 34.8536C21.669 36.0144 22.1392 37.1219 22.9602 37.9429C23.7811 38.7638 24.8886 39.234 26.0495 39.2545C27.206 39.2749 28.3255 38.8473 29.1737 38.0613L38.0472 29.1879C38.0535 29.1816 38.0599 29.1754 38.0664 29.1694C38.5066 28.7592 38.8597 28.2645 39.1046 27.7149C39.3495 27.1653 39.4812 26.5719 39.4918 25.9703C39.5024 25.3687 39.3917 24.7711 39.1664 24.2131C38.941 23.6552 38.6056 23.1484 38.1801 22.7229C37.7546 22.2974 37.2478 21.962 36.6899 21.7367ZM34.9061 19.9039C35.7102 19.8898 36.5088 20.0377 37.2545 20.3388C38.0001 20.64 38.6774 21.0883 39.2461 21.6569C39.8147 22.2256 40.263 22.9029 40.5642 23.6485C40.8653 24.3942 41.0133 25.1929 40.9991 25.9969C40.9849 26.8009 40.8089 27.5939 40.4816 28.3284C40.1562 29.0588 39.6878 29.7166 39.1041 30.2629L30.23 39.1371C30.2237 39.1434 30.2173 39.1495 30.2108 39.1556C29.0756 40.2133 27.5742 40.7892 26.0229 40.7618C24.4715 40.7344 22.9913 40.106 21.8942 39.0088C20.797 37.9117 20.1686 36.4315 20.1412 34.8802C20.1138 33.3288 20.6897 31.8274 21.7474 30.6922C21.7535 30.6857 21.7596 30.6793 21.7659 30.6731L30.6401 21.7989C31.1865 21.2152 31.8442 20.7468 32.5746 20.4214C33.3091 20.0941 34.1021 19.9181 34.9061 19.9039Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.2021 26.2258C26.4965 25.9315 26.9737 25.9315 27.2681 26.2258L34.6717 33.6295C34.9661 33.9238 34.9661 34.4011 34.6717 34.6955C34.3774 34.9898 33.9001 34.9898 33.6057 34.6955L26.2021 27.2918C25.9077 26.9975 25.9077 26.5202 26.2021 26.2258Z'
        fill={primaryColor}
      />
    </svg>
  );
}
