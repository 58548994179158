import styled from 'styled-components';

export const FlexWrapper = styled.div`
  // this is to ensure that the header content is aligned properly
  flex: 1;
`;

export const ChildrenWrapper = styled.div`
  margin: 0 auto;
`;
