import { ReactNode, ReactElement, useEffect } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

type DebugToolbarContainerProps = {
  children: ReactNode;
  styles: React.CSSProperties;
  setParentComponentWidth: (width: number) => void;
};

const Container = styled.div`
  position: absolute;
  z-index: 1500;
`;

export function DraggableToolbar(props: DebugToolbarContainerProps): ReactElement | null {
  const { children, setParentComponentWidth, styles } = props;

  const { attributes, listeners, node, setNodeRef, transform } = useDraggable({
    id: 'debug-toolbar-draggable-id',
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  // Parent width is used to calculate default placement of this component
  useEffect(() => {
    setParentComponentWidth(node.current?.parentElement?.clientWidth || window.innerWidth);
  }, [node, node.current?.parentElement?.clientWidth, setParentComponentWidth]);

  return (
    <Container ref={setNodeRef} style={{ ...style, ...styles }} {...listeners} {...attributes}>
      {children}
    </Container>
  );
}
