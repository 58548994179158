import { Box } from '@breathelife/mui';
import styled from 'styled-components';

import { BaseModal } from '../Modal/BaseModal';

export const BareButton = styled.button`
  border: 0;
  padding: 0;
  outline: none;
  background-color: transparent;
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 0 4px ${(props) => props.theme.colors.selection.border.focus};
  }
`;

export const Pill = styled.button`
  outline: none !important;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 8px 16px;
  min-height: 32px;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.primary[10]};
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.primary.default};
  border: 3px solid transparent;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary[20]};
    color: ${(props) => props.theme.colors.primary[70]};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary[40]};
    color: white;
  }

  &:focus {
    border: 3px solid ${(props) => props.theme.colors.hyperlink};
    background-color: white;
    color: ${(props) => props.theme.colors.primary.default};
  }
`;

export const PillText = styled.span`
  text-align: left;
`;

export const IconButton = styled(BareButton)`
  margin: 0 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary.default} !important;
  font-size: 14px;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.colors.grey[0]};

  :hover,
  :focus,
  :active {
    background-color: ${(props) => props.theme.colors.primary[60]} !important;
  }

  :focus {
    box-shadow: 0 0 0 3px ${(props) => props.theme.colors.selection.border.focus};
  }

  svg {
    height: 12px;
    width: 4px;
  }

  span {
    font-weight: 500;
  }
`;

export const Modal = styled(BaseModal)`
  .modal {
    border-radius: 4px !important;
  }
`;

export const ModalTitle = styled(Box)`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
`;

export const Container = styled(Box)<{ isMobile?: boolean; isPopper?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.grey[40]};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: ${(props) => (props.isMobile || props.isPopper ? '280px' : '540px')};
`;

export const Header = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[20]};
  stroke: 1px inside ${(props) => props.theme.colors.grey[50]};
`;

export const Body = styled(Box)`
  background-color: ${(props) => props.theme.colors.grey[0]};
  p {
    color: ${(props) => props.theme.colors.grey[90]};
    font-size: 14px;
    line-height: 18px;
    font-weight: regular;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;
