import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function FamilyIncomeIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M33.1157 16.2179L29.9704 9.9274C29.8515 9.68935 29.6868 9.47707 29.4858 9.30269C29.2848 9.1283 29.0514 8.99523 28.7989 8.91108C28.5464 8.82693 28.2799 8.79334 28.0144 8.81224C27.749 8.83114 27.4899 8.90216 27.2519 9.02123L12.8585 16.2179'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.9572 8.00715C28.3284 7.98072 28.7011 8.02769 29.0542 8.14537C29.4072 8.26305 29.7336 8.44914 30.0147 8.69301C30.2958 8.93683 30.526 9.23362 30.6924 9.56644C30.6924 9.5665 30.6923 9.56637 30.6924 9.56644L33.8376 15.857C34.037 16.2557 33.8754 16.7405 33.4767 16.9398C33.078 17.1392 32.5932 16.9776 32.3938 16.5789L29.2485 10.2883C29.177 10.1451 29.0778 10.0173 28.9569 9.91235C28.836 9.80745 28.6956 9.7274 28.5437 9.67678C28.3918 9.62615 28.2315 9.60595 28.0718 9.61732C27.9121 9.62869 27.7563 9.67141 27.6131 9.74304L13.2195 16.9398C12.8208 17.1392 12.336 16.9776 12.1367 16.5789C11.9373 16.1802 12.0989 15.6954 12.4976 15.496L26.8908 8.29941C26.8909 8.29938 26.8907 8.29944 26.8908 8.29941C27.2236 8.13295 27.586 8.03358 27.9572 8.00715Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8586 17.025C11.9981 17.025 11.1729 17.3668 10.5645 17.9752C9.95605 18.5837 9.61424 19.4089 9.61424 20.2693V34.4493C9.61424 35.3098 9.95605 36.135 10.5645 36.7434C11.1729 37.3518 11.9981 37.6936 12.8586 37.6936H33.1157C33.9762 37.6936 34.8014 37.3518 35.4098 36.7434C36.0182 36.135 36.36 35.3098 36.36 34.4493V32.4236C36.36 31.9779 36.7214 31.6165 37.1671 31.6165C37.6129 31.6165 37.9743 31.9779 37.9743 32.4236V34.4493C37.9743 35.7379 37.4624 36.9737 36.5512 37.8848C35.6401 38.796 34.4043 39.3079 33.1157 39.3079H12.8586C11.57 39.3079 10.3342 38.796 9.42304 37.8848C8.51188 36.9737 8 35.7379 8 34.4493V20.2693C8 18.9808 8.51188 17.745 9.42304 16.8338C10.3342 15.9226 11.57 15.4108 12.8586 15.4108H33.1157C34.4043 15.4108 35.6401 15.9226 36.5512 16.8338C37.4624 17.745 37.9743 18.9808 37.9743 20.2693V22.295C37.9743 22.7408 37.6129 23.1022 37.1671 23.1022C36.7214 23.1022 36.36 22.7408 36.36 22.295V20.2693C36.36 19.4089 36.0182 18.5837 35.4098 17.9752C34.8014 17.3668 33.9762 17.025 33.1157 17.025H12.8586Z'
        fill={primaryColor}
      />
      <path
        d='M37.1671 32.4237C37.7043 32.4237 38.2196 32.2103 38.5995 31.8304C38.9794 31.4505 39.1928 30.9352 39.1928 30.398V24.3208C39.1928 23.7836 38.9794 23.2683 38.5995 22.8884C38.2196 22.5085 37.7043 22.2951 37.1671 22.2951H32.1028C30.7597 22.2951 29.4715 22.8287 28.5218 23.7784C27.5721 24.7281 27.0385 26.0163 27.0385 27.3594C27.0385 28.7025 27.5721 29.9906 28.5218 30.9404C29.4715 31.8901 30.7597 32.4237 32.1028 32.4237H37.1671Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.9511 23.2077C29.0522 22.1066 30.5457 21.488 32.1029 21.488H37.1671C37.9185 21.488 38.639 21.7864 39.1703 22.3177C39.7015 22.849 40 23.5695 40 24.3208V30.398C40 31.1493 39.7015 31.8698 39.1703 32.4011C38.639 32.9323 37.9185 33.2308 37.1671 33.2308H32.1029C30.5457 33.2308 29.0522 32.6122 27.9511 31.5111C26.85 30.41 26.2314 28.9166 26.2314 27.3594C26.2314 25.8022 26.85 24.3088 27.9511 23.2077ZM32.1029 23.1022C30.9738 23.1022 29.891 23.5507 29.0926 24.3491C28.2942 25.1475 27.8457 26.2303 27.8457 27.3594C27.8457 28.4885 28.2942 29.5713 29.0926 30.3697C29.891 31.168 30.9738 31.6166 32.1029 31.6166H37.1671C37.4903 31.6166 37.8003 31.4882 38.0288 31.2596C38.2573 31.0311 38.3857 30.7211 38.3857 30.398V24.3208C38.3857 23.9976 38.2573 23.6877 38.0288 23.4591C37.8003 23.2306 37.4903 23.1022 37.1671 23.1022H32.1029Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.1028 26.0458C31.7544 26.0458 31.4203 26.1842 31.174 26.4305C30.9276 26.6768 30.7892 27.011 30.7892 27.3593C30.7892 27.6191 30.8663 27.8731 31.0106 28.0891C31.155 28.3051 31.3601 28.4735 31.6001 28.5729C31.8401 28.6723 32.1043 28.6983 32.3591 28.6476C32.6139 28.597 32.8479 28.4719 33.0316 28.2881C33.2153 28.1044 33.3404 27.8704 33.3911 27.6156C33.4418 27.3608 33.4158 27.0967 33.3164 26.8567C33.2169 26.6166 33.0486 26.4115 32.8326 26.2671C32.6166 26.1228 32.3626 26.0458 32.1028 26.0458Z'
        fill={primaryColor}
      />
    </svg>
  );
}
