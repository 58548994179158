import { ReactElement, memo } from 'react';
import { ExternalScriptDomElementPosition } from '@breathelife/react-hooks';
import { ExternalUrlScriptLoader } from '../ScriptsLoader';

type Props = {
  googleMapsApiKey: string;
};

function GoogleMapsHelmetComponent(props: Props): ReactElement | null {
  if (window.google?.maps) return null;

  return (
    <ExternalUrlScriptLoader
      script={{
        id: 'google-map-api-js',
        domElementPosition: ExternalScriptDomElementPosition.head,
        attributes: {
          src: `https://maps.googleapis.com/maps/api/js?key=${props.googleMapsApiKey}&libraries=places&callback=Function.prototype`,
        },
      }}
    />
  );
}

export const GoogleMapsHelmet = memo(GoogleMapsHelmetComponent);
