import { ReactNode, ReactElement } from 'react';

import { Container, LogoContainer, InfoViewContainer, Title } from './Styles';

type Props = {
  logo: ReactNode;
  title: string;
  children: ReactNode;
};

export function TransactionFlowSidebar(props: Props): ReactElement {
  return (
    <Container>
      <LogoContainer>{props.logo}</LogoContainer>
      <Title>{props.title}</Title>
      <InfoViewContainer>{props.children}</InfoViewContainer>
    </Container>
  );
}
