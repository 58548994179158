import styled from 'styled-components';

import { colors, fontSize, fontWeight } from '../../Theme/Theme';

export const ItemCount = styled.span`
  flex: 1;
  text-align: center;
`;

export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  text-align: left;
`;

export const ListItemActionsLabel = styled.span`
  flex: 1;
  align-self: center;
  font-size: ${fontSize.label.medium};
  font-weight: ${fontWeight.regular};
`;

export const ListItemActionsContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100px;
`;

export const Container = styled.div`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${colors.border.light};
  padding: 13px 0;
`;

export const BaseActionButton = styled.button`
  border: 0;
  padding: 0;
  outline: 0;
  height: 30px;
  width: 30px;
  background-color: transparent;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  circle {
    stroke: ${(props) => props.theme.colors.base.primary};
    fill: ${colors.button.white};
  }
  path {
    fill: ${(props) => props.theme.colors.base.primary};
  }

  :not(:disabled) {
    :focus {
      circle {
        fill: ${colors.button.white};
        stroke: ${(props) => props.theme.colors.button.base.border.focus};
      }
    }

    :hover {
      circle {
        fill: ${(props) => props.theme.colors.base.softPrimary};
      }
    }

    :active {
      circle {
        fill: ${(props) => props.theme.colors.base.primary};
      }
      path {
        fill: ${colors.button.white};
      }
    }
  }
`;
