import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';
import { SharedTheme } from 'Theme';

export function WeightIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const fillPrimary = props.color?.primary || theme?.colors?.icons?.primary || '#000000';
  const fillSecondary = props.color?.secondary || theme?.colors?.icons?.secondary || '#0f0f0f';
  const fillTertiary = props.color?.tertiary || theme?.colors?.icons?.tertiary || '#FFF';
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <circle cx='24' cy='24' r='19.5' fill='white' stroke='#F6F6F6' />
      <path d='M8.73279 8.73285H39.2671V39.2672H8.73279V8.73285Z' fill='#F8F9FD' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 8.73282C8 8.3281 8.3281 8 8.73282 8H39.2672C39.6719 8 40 8.3281 40 8.73282V39.2672C40 39.6719 39.6719 40 39.2672 40H8.73282C8.3281 40 8 39.6719 8 39.2672V8.73282ZM9.46565 9.46565V38.5344H38.5344V9.46565H9.46565Z'
        fill={fillPrimary}
      />
      <path
        d='M17.6122 18.3477C17.6964 18.5171 17.8263 18.6596 17.9872 18.7591C18.1481 18.8586 18.3336 18.9112 18.5228 18.9109H29.4771C29.6663 18.9112 29.8518 18.8586 30.0127 18.7591C30.1736 18.6596 30.3035 18.5171 30.3877 18.3477L31.6905 15.7448C31.7512 15.6224 31.7866 15.4889 31.7947 15.3525C31.8027 15.216 31.7833 15.0794 31.7374 14.9506C31.6916 14.8219 31.6204 14.7036 31.5279 14.603C31.4354 14.5023 31.3237 14.4213 31.1993 14.3647C28.939 13.3389 26.4855 12.8082 24.0033 12.8082C21.5212 12.8082 19.0677 13.3389 16.8074 14.3647C16.6826 14.4212 16.5706 14.5024 16.4779 14.6032C16.3852 14.7041 16.3138 14.8226 16.2679 14.9517C16.222 15.0808 16.2027 15.2178 16.211 15.3545C16.2193 15.4913 16.255 15.6249 16.3161 15.7475L17.6122 18.3477Z'
        fill={fillTertiary}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5048 13.6972C18.8602 12.6284 21.4168 12.0754 24.0034 12.0754C26.59 12.0754 29.1467 12.6284 31.5021 13.6973C31.716 13.7946 31.9087 13.9342 32.0676 14.1072C32.2266 14.2803 32.3491 14.4835 32.4279 14.7048C32.5067 14.9262 32.5401 15.1611 32.5263 15.3957C32.5124 15.6302 32.4515 15.8596 32.3472 16.0701L32.3459 16.0728L31.044 18.6739C31.0438 18.6743 31.0436 18.6746 31.0435 18.6749C30.8984 18.9662 30.675 19.2112 30.3982 19.3824C30.1215 19.5535 29.8025 19.644 29.4772 19.6437H18.5228C18.1974 19.644 17.8784 19.5535 17.6017 19.3824C17.3248 19.2111 17.1012 18.9658 16.9562 18.6743C16.9561 18.6742 16.9562 18.6744 16.9562 18.6743L15.6603 16.0744C15.6603 16.0744 15.6603 16.0744 15.6603 16.0744C15.5553 15.8637 15.4938 15.6339 15.4795 15.3988C15.4653 15.1638 15.4986 14.9283 15.5774 14.7064C15.6562 14.4845 15.779 14.2807 15.9383 14.1073C16.0976 13.934 16.2904 13.7945 16.5048 13.6972ZM24.0034 13.541C21.6257 13.541 19.2755 14.0494 17.1103 15.032C17.0752 15.0479 17.0435 15.0708 17.0175 15.0991C16.9914 15.1274 16.9714 15.1607 16.9585 15.197C16.9456 15.2333 16.9402 15.2718 16.9425 15.3102C16.9448 15.3486 16.9549 15.3862 16.972 15.4206L18.2681 18.0208C18.2915 18.0679 18.3279 18.1082 18.3727 18.1358C18.4174 18.1635 18.469 18.1781 18.5216 18.1781L18.5228 18.178L29.4783 18.1781C29.531 18.1781 29.5825 18.1635 29.6273 18.1358C29.672 18.1082 29.7081 18.0685 29.7316 18.0214L29.7324 18.0197L31.0339 15.4195C31.0341 15.4192 31.0342 15.4188 31.0344 15.4185C31.0511 15.3844 31.0609 15.3472 31.0632 15.3092C31.0654 15.2709 31.06 15.2325 31.0471 15.1963C31.0342 15.1602 31.0142 15.1269 30.9882 15.0987C30.9623 15.0705 30.931 15.0477 30.8962 15.0318M24.0034 13.541C26.3809 13.541 28.7312 14.0494 30.8962 15.0318Z'
        fill={fillPrimary}
      />
      <path
        d='M21.9644 25.5267C21.9644 24.1208 20.3698 22.9822 18.4021 22.9822C16.4343 22.9822 14.8397 24.1208 14.8397 25.5267V32.6514C14.8397 33.3262 15.1078 33.9734 15.585 34.4506C16.0622 34.9278 16.7094 35.1959 17.3843 35.1959C18.0591 35.1959 18.7063 34.9278 19.1835 34.4506C19.6607 33.9734 19.9288 33.3262 19.9288 32.6514V32.1954C19.9289 30.172 20.539 28.1956 21.6794 26.5242V26.5242C21.8636 26.2239 21.9622 25.879 21.9644 25.5267Z'
        fill={fillSecondary}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.4571 23.1308C16.2397 22.5719 17.2832 22.2493 18.4021 22.2493C19.521 22.2493 20.5645 22.5719 21.3471 23.1308C22.1283 23.6888 22.6973 24.5257 22.6973 25.5267V25.5314C22.6942 26.0173 22.5582 26.4931 22.3041 26.9073L21.6795 26.5241L22.2848 26.9372C21.2275 28.4869 20.6618 30.3193 20.6616 32.1954C20.6616 32.1954 20.6616 32.1954 20.6616 32.1954V32.6514C20.6616 33.5206 20.3164 34.3542 19.7017 34.9688C19.0871 35.5834 18.2535 35.9287 17.3843 35.9287C16.5151 35.9287 15.6815 35.5834 15.0668 34.9688C14.4522 34.3542 14.1069 33.5206 14.1069 32.6514V25.5267C14.1069 24.5257 14.6759 23.6888 15.4571 23.1308ZM21.0627 26.1278C21.1715 25.9453 21.2298 25.737 21.2316 25.5244C21.2307 25.12 21.0023 24.6857 20.4953 24.3235C19.9886 23.9617 19.251 23.715 18.4021 23.715C17.5532 23.715 16.8156 23.9617 16.3089 24.3235C15.8009 24.6863 15.5726 25.1217 15.5726 25.5267V32.6514C15.5726 33.1319 15.7635 33.5927 16.1032 33.9324C16.443 34.2722 16.9038 34.4631 17.3843 34.4631C17.8648 34.4631 18.3256 34.2722 18.6654 33.9324C19.0051 33.5927 19.196 33.1319 19.196 32.6514V32.1954C19.1962 30.0314 19.8466 27.9174 21.0627 26.1278Z'
        fill={fillPrimary}
      />
      <path
        d='M26.0356 25.5267C26.0356 24.1208 27.6302 22.9822 29.598 22.9822C31.5658 22.9822 33.1603 24.1208 33.1603 25.5267V32.6514C33.1603 33.3262 32.8922 33.9734 32.4151 34.4506C31.9379 34.9278 31.2906 35.1959 30.6158 35.1959C29.9409 35.1959 29.2937 34.9278 28.8165 34.4506C28.3394 33.9734 28.0713 33.3262 28.0713 32.6514V32.1954C28.0711 30.172 27.461 28.1956 26.3206 26.5242V26.5242C26.1364 26.2239 26.0379 25.879 26.0356 25.5267Z'
        fill={fillSecondary}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.7684 25.5244C26.7702 25.737 26.8285 25.9453 26.9373 26.1278C28.1534 27.9174 28.8038 30.0313 28.804 32.1953V32.6514C28.804 33.1319 28.9949 33.5927 29.3346 33.9324C29.6744 34.2722 30.1352 34.4631 30.6157 34.4631C31.0962 34.4631 31.557 34.2722 31.8968 33.9324C32.2365 33.5927 32.4274 33.1319 32.4274 32.6514V25.5267C32.4274 25.1217 32.1991 24.6863 31.6911 24.3235C31.1844 23.9617 30.4468 23.715 29.5979 23.715C28.749 23.715 28.0114 23.9617 27.5047 24.3235C26.9977 24.6857 26.7693 25.12 26.7684 25.5244ZM26.3205 26.5241L25.6959 26.9073C25.4418 26.4931 25.3058 26.0173 25.3027 25.5314L25.3027 25.5267C25.3027 24.5257 25.8717 23.6888 26.6529 23.1308C27.4355 22.5719 28.479 22.2493 29.5979 22.2493C30.7168 22.2493 31.7603 22.5719 32.5429 23.1308C33.3241 23.6888 33.8931 24.5257 33.8931 25.5267V32.6514C33.8931 33.5206 33.5478 34.3542 32.9332 34.9688C32.3185 35.5834 31.4849 35.9287 30.6157 35.9287C29.7465 35.9287 28.9129 35.5834 28.2983 34.9688C27.6836 34.3542 27.3384 33.5206 27.3384 32.6514V32.1954C27.3384 32.1954 27.3384 32.1955 27.3384 32.1954C27.3382 30.3194 26.7725 28.4869 25.7152 26.9372L26.3205 26.5241Z'
        fill={fillPrimary}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.8315 14.8735C25.2224 14.9783 25.4544 15.3801 25.3497 15.771L24.473 19.0429C24.3683 19.4338 23.9664 19.6658 23.5755 19.5611C23.1845 19.4563 22.9525 19.0545 23.0573 18.6636L23.934 15.3917C24.0387 15.0008 24.4406 14.7688 24.8315 14.8735Z'
        fill={fillPrimary}
      />
    </svg>
  );
}
