import { ReactElement } from 'react';

export function CircledSignatureIcon(): ReactElement {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' stroke='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.7446 6.45727C11.6621 6.37884 11.5324 6.37884 11.4499 6.45727L6.69268 11.2187C6.6114 11.3001 6.6114 11.4324 6.69268 11.5137L8.47669 13.2993C8.55797 13.3807 8.69011 13.3807 8.77139 13.2993L13.5286 8.53867C13.6099 8.45732 13.6099 8.32506 13.5286 8.24371L11.7446 6.45727ZM6.24793 11.9639C6.16623 11.8825 6.03452 11.8829 5.95323 11.9647C5.93031 11.9881 5.9128 12.016 5.9028 12.0473L5.01079 14.7257C4.98578 14.8008 5.00496 14.8834 5.06081 14.9393C5.11708 14.9944 5.1992 15.014 5.27423 14.9898L7.95024 14.0974C8.05987 14.0619 8.11989 13.9443 8.08446 13.8346C8.07446 13.8033 8.05695 13.7745 8.03361 13.7516L6.24793 11.9639ZM14.5711 5.41678C13.9892 4.86107 13.0739 4.86107 12.492 5.41678L12.1952 5.71424C12.1139 5.7956 12.1139 5.92785 12.1952 6.0092L13.9788 7.79481C14.0601 7.87616 14.1922 7.87616 14.2735 7.79481L14.5711 7.49568C15.143 6.92119 15.143 5.99126 14.5711 5.41678Z'
        stroke='#757575'
      />
      <circle cx='10' cy='10' r='9.5' stroke='#757575' />
    </svg>
  );
}
