import { ReactElement } from 'react';
import { useTheme } from 'styled-components';

import { SVGIconProps } from 'Components/Icon/IconMap';

import { SharedTheme } from '../../Theme';

export function DebtsSavingsIcon(props: SVGIconProps): ReactElement {
  const theme = useTheme() as SharedTheme;
  const primaryColor = props.color?.primary || theme.colors.icons.primary;
  const secondaryColor = props.color?.secondary || theme.colors.icons.secondary;
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path
        d='M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z'
        fill='#F6F6F6'
      />
      <path
        d='M27.1069 17.4132C30.2154 19.5584 34.2551 24.724 34.2551 29.3776C34.2551 34.098 29.1488 37.9236 23.9999 37.9236C18.8511 37.9236 13.7448 34.098 13.7448 29.3776C13.7448 24.724 17.7845 19.5584 20.893 17.4132L18.5305 11.7812C18.4354 11.6151 18.3762 11.4309 18.3567 11.2405C18.3373 11.0501 18.358 10.8578 18.4176 10.6759C18.4772 10.494 18.5743 10.3267 18.7026 10.1847C18.8309 10.0427 18.9876 9.92927 19.1625 9.85167C22.3025 8.82085 25.6898 8.82085 28.8298 9.85167C29.0053 9.92855 29.1628 10.0415 29.2919 10.1831C29.421 10.3248 29.5189 10.492 29.5792 10.674C29.6396 10.8559 29.6609 11.0485 29.642 11.2393C29.6231 11.43 29.5643 11.6146 29.4694 11.7812L27.1069 17.4132Z'
        fill={secondaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5001 10.6967C19.4537 10.7216 19.4117 10.7543 19.3763 10.7936C19.3332 10.8413 19.3006 10.8975 19.2805 10.9586C19.2605 11.0197 19.2536 11.0843 19.2601 11.1483C19.2666 11.2122 19.2865 11.2741 19.3185 11.3299C19.337 11.3622 19.3535 11.3956 19.3679 11.4299L21.7304 17.0619C21.8966 17.4581 21.7624 17.9165 21.4088 18.1605C19.9641 19.1575 18.2565 20.895 16.9135 22.9513C15.5656 25.015 14.6528 27.2955 14.6528 29.3776C14.6528 31.3946 15.7453 33.2914 17.5111 34.7136C19.2761 36.135 21.6405 37.0156 24 37.0156C26.3594 37.0156 28.7239 36.135 30.4889 34.7136C32.2547 33.2914 33.3472 31.3946 33.3472 29.3776C33.3472 27.2955 32.4344 25.015 31.0865 22.9513C29.7435 20.895 28.0358 19.1575 26.5912 18.1605C26.2376 17.9165 26.1034 17.4581 26.2696 17.0619L28.6321 11.4299C28.6462 11.3962 28.6624 11.3634 28.6805 11.3317C28.7124 11.2757 28.7321 11.2137 28.7385 11.1496C28.7449 11.0855 28.7377 11.0208 28.7174 10.9597C28.6972 10.8986 28.6643 10.8424 28.6209 10.7948C28.5848 10.7553 28.5422 10.7224 28.4949 10.6975C25.5716 9.74989 22.4236 9.7496 19.5001 10.6967ZM18.8794 8.98898C22.2033 7.89776 25.7891 7.89776 29.113 8.98898C29.1405 8.99799 29.1675 9.00832 29.194 9.01991C29.4862 9.14784 29.7482 9.33577 29.9631 9.57151C30.1779 9.80725 30.3408 10.0855 30.4412 10.3883C30.5416 10.6911 30.5771 11.0115 30.5456 11.3289C30.5162 11.6258 30.4286 11.9138 30.2882 12.1766L28.2209 17.1051C29.7225 18.2723 31.325 19.9954 32.607 21.9582C34.0561 24.177 35.1632 26.8061 35.1632 29.3776C35.1632 32.081 33.7025 34.4572 31.628 36.1279C29.5526 37.7994 26.7894 38.8316 24 38.8316C21.2106 38.8316 18.4474 37.7994 16.372 36.1279C14.2975 34.4572 12.8368 32.081 12.8368 29.3776C12.8368 26.8061 13.9439 24.177 15.393 21.9582C16.675 19.9954 18.2775 18.2723 19.7791 17.1051L17.7121 12.1774C17.5717 11.9157 17.4837 11.6288 17.4535 11.3328C17.4211 11.016 17.4556 10.6959 17.5547 10.3933C17.6539 10.0906 17.8154 9.81218 18.029 9.57591C18.2425 9.33964 18.5033 9.15083 18.7944 9.02169C18.8221 9.00937 18.8505 8.99845 18.8794 8.98898Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.9851 17.4132C19.9851 16.9117 20.3916 16.5052 20.8931 16.5052H27.107C27.6085 16.5052 28.015 16.9117 28.015 17.4132C28.015 17.9147 27.6085 18.3212 27.107 18.3212H20.8931C20.3916 18.3212 19.9851 17.9147 19.9851 17.4132Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3626 14.2261L14.8843 14.2261C15.3858 14.2261 15.7923 14.6327 15.7923 15.1341C15.7923 15.6356 15.3858 16.0422 14.8843 16.0422H11.3631C11.3209 16.0422 11.2796 16.0538 11.2435 16.0757C11.2074 16.0975 11.178 16.1289 11.1584 16.1663C11.1388 16.2037 11.1298 16.2458 11.1324 16.2879C11.135 16.33 11.1491 16.3706 11.1731 16.4053C11.1731 16.4053 11.173 16.4052 11.1731 16.4053L13.0933 19.1746C13.2355 19.3798 13.287 19.6345 13.2357 19.8788C13.1843 20.1231 13.0346 20.3355 12.8218 20.4661C11.3441 21.3723 10.1137 22.63 9.24019 24.1272C8.36938 25.6197 7.88025 27.304 7.81603 29.0306C7.81798 30.5522 8.09334 32.3141 8.84155 33.6632C9.56142 34.9612 10.7055 35.876 12.6053 35.876C13.1068 35.876 13.5134 36.2825 13.5134 36.784C13.5134 37.2855 13.1068 37.692 12.6053 37.692C9.94731 37.692 8.24274 36.3279 7.25341 34.544C6.29508 32.816 6 30.7008 6 29.0144C6 29.0036 6.00019 28.9928 6.00058 28.982C6.07302 26.9503 6.6471 24.968 7.67162 23.212C8.53465 21.7328 9.69471 20.4529 11.0749 19.4508L9.68049 17.4397C9.46783 17.1326 9.34293 16.7728 9.31987 16.4C9.29681 16.0272 9.37619 15.6552 9.54939 15.3242C9.7226 14.9933 9.98304 14.716 10.3025 14.5224C10.622 14.3289 10.989 14.2264 11.3626 14.2261Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.1921 21.971C25.1921 21.4695 24.7856 21.063 24.2841 21.063C23.7826 21.063 23.3761 21.4695 23.3761 21.971V22.7111C22.6813 22.8381 22.0437 23.1097 21.5246 23.499C20.7855 24.0532 20.2433 24.8883 20.2433 25.8877C20.2433 26.8867 20.7846 27.7221 21.5236 28.2769C22.2623 28.8315 23.2412 29.1476 24.2841 29.1476C24.9723 29.1476 25.5604 29.3575 25.9558 29.6537C26.3508 29.9495 26.5103 30.289 26.5103 30.5884C26.5103 30.8881 26.3505 31.2286 25.9552 31.5251C25.5596 31.8219 24.9717 32.0323 24.2841 32.0323C24.2722 32.0323 24.2603 32.0325 24.2485 32.033C23.8514 32.0485 23.4565 31.9682 23.0971 31.7988C22.7377 31.6293 22.4244 31.3758 22.1839 31.0596C21.8802 30.6605 21.3105 30.5831 20.9114 30.8868C20.5123 31.1904 20.435 31.7601 20.7386 32.1592C21.1559 32.7077 21.6993 33.1475 22.3226 33.4414C22.6589 33.5999 23.0131 33.7135 23.3761 33.7802V34.5052C23.3761 35.0066 23.7826 35.4132 24.2841 35.4132C24.7856 35.4132 25.1921 35.0066 25.1921 34.5052V33.7666C25.8876 33.6394 26.5257 33.3675 27.0451 32.9778C27.7843 32.4232 28.3264 31.5877 28.3264 30.5884C28.3264 29.5888 27.784 28.7539 27.0444 28.2001C26.3053 27.6466 25.3263 27.3316 24.2841 27.3316C23.5965 27.3316 23.009 27.1213 22.6139 26.8246C22.2191 26.5283 22.0593 26.1878 22.0593 25.8877C22.0593 25.5882 22.219 25.2481 22.6141 24.9519C23.0094 24.6554 23.5971 24.4454 24.2841 24.4454C24.2963 24.4454 24.3086 24.4452 24.3208 24.4447C24.7176 24.4286 25.1123 24.5085 25.4715 24.6777C25.8307 24.8469 26.1438 25.1002 26.3841 25.4163C26.6876 25.8155 27.2572 25.8931 27.6564 25.5896C28.0557 25.2861 28.1332 24.7164 27.8297 24.3172C27.4125 23.7684 26.8689 23.3285 26.2452 23.0347C25.9091 22.8764 25.555 22.7631 25.1921 22.6967V21.971Z'
        fill={primaryColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.2078 15.1341C32.2078 14.6327 32.6143 14.2261 33.1158 14.2261H36.6375C37.011 14.2264 37.3781 14.3289 37.6975 14.5224C38.017 14.716 38.2775 14.9933 38.4507 15.3242C38.6239 15.6551 38.7032 16.0272 38.6802 16.4C38.6571 16.7728 38.5325 17.1322 38.3198 17.4393L36.9251 19.4508C38.3053 20.4529 39.4654 21.7328 40.3284 23.212C41.353 24.968 41.927 26.9503 41.9995 28.982C41.9999 28.9928 42 29.0036 42 29.0144C42 30.7008 41.705 32.816 40.7466 34.544C39.7573 36.3279 38.0527 37.692 35.3947 37.692C34.8932 37.692 34.4867 37.2855 34.4867 36.784C34.4867 36.2825 34.8932 35.876 35.3947 35.876C37.2945 35.876 38.4386 34.9612 39.1585 33.6632C39.9067 32.3141 40.1821 30.5522 40.184 29.0306C40.1198 27.304 39.6307 25.6197 38.7599 24.1272C37.8863 22.63 36.6559 21.3723 35.1783 20.4661C34.9655 20.3355 34.8158 20.1231 34.7644 19.8788C34.713 19.6345 34.7645 19.3798 34.9068 19.1746L36.8269 16.4054C36.8269 16.4053 36.8269 16.4054 36.8269 16.4054C36.8509 16.3707 36.865 16.33 36.8676 16.2879C36.8702 16.2458 36.8613 16.2037 36.8417 16.1663C36.8221 16.1289 36.7926 16.0975 36.7565 16.0757C36.7205 16.0538 36.6791 16.0422 36.637 16.0422H33.1158C32.6143 16.0422 32.2078 15.6356 32.2078 15.1341Z'
        fill={primaryColor}
      />
    </svg>
  );
}
