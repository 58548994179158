import { ReactElement } from 'react';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ProgressBarContainer } from './TransactionFlowStyles';

type Props = {
  progress: number;
};

export function HeaderProgressBar(props: Props): ReactElement {
  return (
    <ProgressBarContainer>
      <ProgressBar progress={props.progress} />
    </ProgressBarContainer>
  );
}
