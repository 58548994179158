import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Content = styled.main`
  flex: 1;
  padding: 0 20px 100px 20px;
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    max-width: 1120px;
    padding: 40px 20px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 4fr 6fr;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop.min}) {
    column-gap: 7.5%;
    grid-template-columns: 5fr 8fr;
  }
`;

export const MainTitle = styled.h1`
  color: ${(props) => props.theme.colors.black};
  text-align: center;
  font-size: 31px;
  margin: 3rem 0 0;
  text-transform: capitalize;
  padding: 0 1rem;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin: 2rem 0 3rem;
  }
`;

export const Section = styled.section`
  margin-bottom: 22px;
`;

export const Footer = styled.footer`
  width: 100%;
`;
